const isSupported = () => {
    return !!window.Notification;
};

const isFocused = () => {
    return document.hasFocus();
};

const shouldRequestPermission = () => {
    if (isSupported()) {
        return Notification.permission === "default";
    } else {
        return false;
    }
};

const getPermission = () => {
    if (isSupported()) {
        Notification.requestPermission((status) => {
            console.log("Notification permission status:", status);
        });
    }
};

const notify = (options) => {
    if (isSupported()) {
        if (Notification.permission === "granted") {
            try {
                new Notification("Morphkey", options);
            } catch (e) {
                navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`).then(() => {
                    navigator.serviceWorker.ready.then((reg) => {
                        reg.showNotification("Morphkey", options);
                    });
                }).catch((err) => {
                    console.log(`Error creating notification: ${err}`);
                });
            }
        }
    }
};

export {isFocused, getPermission, shouldRequestPermission, notify};
