import React from "react";

import {ChevronDown, Plus} from "react-feather";
import {Link} from "react-router-dom";
import {Collapse} from "reactstrap";

import {TAB} from "../Settings";

import "../../styles/ProfileDropdown.css";

class ProfilesDropdown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dropdownOpen: false,
            isOpen: false
        };
    }

    static getDerivedStateFromProps(props) {
        return {
            isOpen: props.isOpen
        };
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        if (this.props.menu) {
            return (
                <React.Fragment>
                    <div className="nav-menu-item profiles"
                        onClick={() => this.props.toggle()}
                    >
                        {this.state.isOpen ?
                            <ChevronDown className="lrg-icon mr-2"/>  :
                            <Plus className="lrg-icon mr-2"/>
                        } PROFILES
                    </div>
                    <Collapse isOpen={this.state.isOpen}>
                        <div className="col sub-group">
                            <Link to={{
                                pathname: "/settings",
                                state: {
                                    settingsTab: TAB.GENERAL
                                }
                            }} className="nav-menu-item profile-type"
                            onClick={() => this.props.closeMenu()}
                            >
                                GENERAL
                            </Link>

                            <Link to={{
                                pathname: "/settings",
                                state: {
                                    settingsTab: TAB.FRIEND
                                }
                            }} className="nav-menu-item profile-type"
                            onClick={() => this.props.closeMenu()}
                            >
                                CONVERSATION
                            </Link>

                            <Link to={{
                                pathname: "/settings",
                                state: {
                                    settingsTab: TAB.DATING
                                }
                            }} className="nav-menu-item profile-type"
                            onClick={() => this.props.closeMenu()}
                            >
                                RELATIONSHIP
                            </Link>

                            <Link to={{
                                pathname: "/settings",
                                state: {
                                    settingsTab: TAB.COLLAB
                                }
                            }} className="nav-menu-item profile-type"
                            onClick={() => this.props.closeMenu()}
                            >
                                COLLABORATE
                            </Link>
                        </div>
                    </Collapse>
                </React.Fragment>
            );
        }

        return (
            <div />
        );
    }
}

ProfilesDropdown.defaultProps = {
    action: () => {},
    closeMenu: () => {}
};

export default ProfilesDropdown;
