import React, {Component} from "react";

import {X} from "react-feather";

import "../styles/Cookies.css";

class Cookies extends Component {

    render() {
        if (this.props.acceptedCookies) {
            return <div />;
        }

        return (
            <div id="cookies-container">
                <span id="accept-message">
                    Like most websites, we use cookies to give you the best experience possible.
                    By continuing to use our website without changing your settings,
                    you are agreeing to <a href="/privacy-policy" id="privacy-link">our use of cookies</a>.
                </span>
                <span id="accept-button" onClick={this.props.acceptCookies}><X /></span>
            </div>
        );
    }
}

Cookies.defaultProps = {
    acceptCookies: () => {},
    acceptedCookies: false,
};

export default Cookies;
