import React, { Component } from "react";

import Main from "./components/Main";
import Cookies from "./components/Cookies";

import {sendEvent} from "./utils/analytics";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/anti-strap.css";
import "./styles/Auth.css";
import "./styles/App.css";
import "./styles/Elements.css";
import "./styles/Main.css";
import "./styles/Chat.css";
import "./styles/Payment.css";
import { CallContext, callSignals } from "./utils/call-context";

class App extends Component {

    static COOKIES = "morphkey-accepted-cookies";

    constructor(props) {
        super(props);

        let acceptedCookies = window.localStorage.getItem(App.COOKIES);
        acceptedCookies = acceptedCookies === null ? false : acceptedCookies;

        this.setCallSignals = (newSignals) => {
            this.setState({ ...newSignals });
        };

        this.state = {
            acceptedCookies,
            ...callSignals,
            setCallSignals: this.setCallSignals,
        };

        this.acceptCookies = this.acceptCookies.bind(this);
    }

    // eslint-disable-next-line class-methods-use-this
    handleUnload() {
        sendEvent("leave");
    }

    componentDidMount() {
        sendEvent("visit");

        window.addEventListener("beforeunload", this.handleUnload);
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.handleUnload);
    }

    acceptCookies() {
        window.localStorage.setItem(App.COOKIES, "true");
        this.setState({acceptedCookies: true});
    }

    render() {
        // We hardcode "true" just in case we ever need to bring it back as a variable prop.
        return (
            <React.Fragment>
                <CallContext.Provider value={this.state}>
                    <Main acceptedCookies={true} />
                    <Cookies acceptCookies={this.acceptCookies} acceptedCookies={true} />
                </CallContext.Provider>
            </React.Fragment>
        );
    }
}

export default App;
