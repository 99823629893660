import React, {Component} from "react";

import "../styles/Home.css";

class HoverWrapper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hovered: false,
            finishedTransitioning: false,
            finishedTransitioningTimeout: undefined,
        };

        this.transitionLength = 1000;

        this.setHover = this.setHover.bind(this);
    }

    setHover(hovered) {
        this.state.finishedTransitioningTimeout && clearTimeout(this.state.finishedTransitioningTimeout);
        const finishedTransitioningTimeout =
            setTimeout(() => this.setState({finishedTransitioning: true}), this.transitionLength);
        this.setState({hovered, finishedTransitioning: false, finishedTransitioningTimeout});
    }

    render() {
        const { hovered } = this.state;

        const transitionStyle = {transition: `opacity ${this.transitionLength}ms`};

        const child1Opacity = hovered ? 0.0 : 1.0;
        const child2Opacity = hovered ? 1.0 : 0.0;

        const child1Style = {
            ...transitionStyle,
            opacity: child1Opacity,
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0
        };
        const child2Style = {
            ...transitionStyle,
            opacity: child2Opacity,
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0
        };

        return (
            <div onMouseEnter={() => this.setHover(true)} onMouseLeave={() => this.setHover(false)}
                style={{position: "relative", ...this.props.style}}>
                <div style={child1Style}>
                    {this.props.children[0]}
                </div>
                <div style={child2Style}>
                    {this.props.children[1]}
                </div>
            </div>
        );
    }
}

export default HoverWrapper;
