import React, {Component} from "react";
import {isMobile} from "../../utils/utils";
import { THEME } from "../NewSettingsComponents";

import Header from "./Header";
import Footer from "../Footer";

import backgroundImage from "../../images/background-tile/Border.png";

class ContentArea extends Component {
    constructor(props) {
        super(props);

        this.state = {
            onMobile: isMobile(),
        };
        this.setMobile = this.setMobile.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.setMobile);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setMobile);
    }

    setMobile() {
        this.setState({onMobile: isMobile()});
    }

    render() {
        const { onMobile } = this.state;

        const mainMargin = onMobile ? THEME.marginXSmall : THEME.marginSmall;
        const fullTopMargin = `calc(90px + ${mainMargin})`; // add in header height, not idea

        const backgroundStyle = {
            backgroundImage: `url(\"${backgroundImage}\")`,
            backgroundRepeat: "repeat",
            backgroundSize: "8%"};

        const wrapperID = "main-wrapper"; // some other file uses getElementById :(

        const html = <>
      <div
          style={{
              display: "flex",
              justifyContent: "start",
              minHeight: "100vh",
              backgroundColor: THEME.colorLightGray,
              flexDirection: "column"}} {...this.props} id={wrapperID}>
          <Header acceptedCookies={true}/>
          <div
              style={{
                  flexGrow: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                  justifyContent: "center",
                  ...backgroundStyle,
                  marginTop: fullTopMargin,
                  padding: `0 ${mainMargin}`}}>
              <div
                  style={{
                      flexGrow: 1,
                      backgroundColor: "white",
                      display: "flex",
                      flexDirection: "column"}}>
                  {this.props.children}
              </div>
          </div>
          <Footer acceptedCookies={true}/>
      </div>
    </>;

        return html;
    }
}

export default ContentArea;
