import React, {Component} from "react";

import {Link} from "react-router-dom";
import validator from "validator";
import axios from "axios";

import Select from "./elements/Select";
import {isMobile} from "../utils/utils";
import {countryList} from "../utils/countryData";

import "../styles/Settings.css";
import rotating from "../images/rotating.png";
import {Modal} from "reactstrap";
import {X} from "react-feather";

import { THEME } from "./NewSettingsComponents";

class AccountSettings extends Component {

    constructor(props) {
        super(props);

        const currentUser = this.props.currentUser;

        this.state = {
            zipCode: currentUser.zipCode,
            country: currentUser.country,
            frozen: currentUser.frozen,
            email: currentUser.email,
            tfa: currentUser.tfa,

            message: "",
            infoColor: false,
            inProgress: false,
            onMobile: isMobile(),

            newPassword: "",
            newPasswordCopy: "",
            currentPassword: "",

            modal: false,
            modalMsg: "",
            modalCb: null,
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
        this.displayAlert = this.displayAlert.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
        this.selectOnChange = this.selectOnChange.bind(this);
        this.checkboxOnChange = this.checkboxOnChange.bind(this);
        this.getCountryDropDown = this.getCountryDropDown.bind(this);

        this.onToggle = this.onToggle.bind(this);
        this.setMobile = this.setMobile.bind(this);
        this.displayModal = this.displayModal.bind(this);
        this.onAutoToggle = this.onAutoToggle.bind(this);
    }

    setMobile() {
        console.log("Screen resize detected");

        this.setState({
            onMobile: isMobile(),
        });
    }

    componentDidMount() {
        window.addEventListener("resize", this.setMobile);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setMobile);
    }

    componentDidUpdate(prevProps) {
        const prevUser = prevProps.currentUser;
        const currentUser = this.props.currentUser;

        for (const field of ["zipCode", "country", "frozen", "email", "tfa"]) {
            if (prevUser[field] !== currentUser[field]) {
                this.setState({
                    zipCode: currentUser.zipCode,
                    country: currentUser.country,
                    frozen: currentUser.frozen,
                    email: currentUser.email,
                    tfa: currentUser.tfa,

                    currentPassword: "",
                    newPasswordCopy: "",
                    newPassword: "",
                });

                return;
            }
        }
    }

    onDismiss() {
        this.setState({message: ""});
    }

    displayAlert() {
        const { message, onMobile, inProgress } = this.state;

        if (message !== "") {
            const splits = this.state.message.split("\n");
            const messages = splits.map((message, i) => (
                <p key={i} style={i === splits.length - 1 ? {marginBottom: "0"} : {}}>{message}</p>
            ));

            return (
                <div style={{
                    marginTop: "10px",
                    marginBottom: "15px",
                    padding: "17px",

                    backgroundColor: THEME.colorSecondary,
                    color: "white",
                    letterSpacing: "1.5px",

                    display: "flex",
                    alignItems: "center",

                    ...(onMobile ? {} : {
                        width: "100%",
                    })
                }} className="dismiss-alert" onClick={this.onDismiss}>
                    {inProgress && <img src={rotating} className="rotating-icon" alt="rotating-icon" />}
                    <div>{messages}</div>
                </div>
            );
        }
    }

    onChange(e) {
        const state = this.state;
        state[e.target.name] = e.target.value;

        this.setState(state);
    }

    checkboxOnChange(e) {
        const name = e.target.name;
        const checked = e.target.checked;

        const state = this.state;
        state[name] = checked;
        this.setState(state);
    }

    selectOnChange(e) {
        const name = e.target.name;
        const options = e.target.options;

        let val = "";

        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                val = options[i].value;
                break;
            }
        }

        const state = this.state;
        state[name] = val;
        this.setState(state);
    }

    getCountryDropDown = () => {
        const tmp = [];

        for (let i = 0; i < countryList.length; i++) {
            tmp.push(<option key={i}>{countryList[i]}</option>);
        }

        return tmp;
    };

    logout = () => {
        axios.post("/api/logout", {}, {withCredentials: true}).then(() => {
            window.location.reload();
            window.location.href = "/";
        });
    };

    onSubmit(e) {
        e.preventDefault();

        if (!validator.isEmail(this.state.email)) {
            return this.setState({
                infoColor: false,
                inProgress: false,
                message: "Please enter a valid email."
            });
        }

        if (this.state.zipCode === "") {
            return this.setState({
                infoColor: false,
                inProgress: false,
                message: "Please enter a valid postal code"
            });
        }

        if (this.state.newPassword !== this.state.newPasswordCopy) {
            return this.setState({
                infoColor: false,
                inProgress: false,
                message: "Passwords must match"
            });
        }

        const settingsBody = {
            currentPassword: this.state.currentPassword,
            newPassword: this.state.newPassword,
            zipCode: this.state.zipCode,
            country: this.state.country,
            frozen: this.state.frozen,
            email: this.state.email,
            tfa: this.state.tfa
        };

        const form_data = new FormData();
        form_data.append("data", JSON.stringify(settingsBody));

        this.setState({
            infoColor: true, inProgress: true,
            message: "Updating Settings...",
        }, () => {
            axios.post("/api/settings", form_data)
                .then((result) => {
                    if (result.data.success) {
                        if (result.data.forceLogout) {
                            this.setState({
                                infoColor: true,
                                inProgress: false,
                                message: "Changes saved! We will need to log you out to re-verify " +
                                    "your account. Please check your new email for the account verification link."
                            });

                            setTimeout(this.logout, 5000);
                        } else {
                            this.setState({
                                infoColor: true,
                                inProgress: false,
                                message: "Changes saved!"
                            }, () => {
                                this.props.reloadCurrentSettings();
                            });
                        }
                    } else {
                        if (result.data.message) {
                            this.setState({
                                infoColor: false,
                                inProgress: false,
                                message: result.data.message
                            });
                        } else {
                            this.setState({
                                infoColor: false,
                                inProgress: false,
                                message: "Error"
                            });
                        }
                    }
                });
        });
    }

    displayModal(msg, cb = null) {
        this.setState({modal: true, modalMsg: msg, modalCb: cb});
    }

    deleteAccount = () => {
        axios.post("/api/account/delete").then((result) => {
            this.displayModal(result.data.success ? "Account deleted" : "Could not delete account");
        });
    };

    onToggle = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const state = this.state;

        state[name] = !state[name];
        this.setState(state);
    };

    onAutoToggle = (name) => {
        const newValue = !this.state[name];

        const settingsBody = {
            frozen: this.state.frozen,
            tfa: this.state.tfa,
            ...{[name]: newValue},
        };

        const formData = new FormData();
        formData.append("data", JSON.stringify(settingsBody));

        const nameTitle = name === "tfa" ? "2FA" : (name === "frozen" ? "Frozen" : "Account");

        this.setState({
            infoColor: true, inProgress: true,
            message: `Updating ${nameTitle} Settings...`,
        }, () => {
            axios.post("/api/settings/partial", formData)
                .then((result) => {
                    if (result.data.success) {
                        let message;

                        if (name === "tfa") {
                            message = `2FA has been ${newValue ? "en" : "dis"}abled!`;
                        } else if (name === "frozen") {
                            message = `Account is now ${newValue ? "frozen" : "active"}!`;
                        } else {
                            message = "Changes saved!";
                        }

                        this.setState({
                            infoColor: true,
                            inProgress: false,
                            message, ...settingsBody
                        }, () => {
                            this.props.reloadCurrentSettings();
                        });
                    } else {
                        if (result.data.message) {
                            this.setState({
                                infoColor: false,
                                inProgress: false,
                                message: result.data.message
                            });
                        } else {
                            this.setState({
                                infoColor: false,
                                inProgress: false,
                                message: "Error"
                            });
                        }
                    }
                });
        });
    };

    render() {
        const { currentPassword, country, email, frozen, newPassword,
            newPasswordCopy, onMobile, tfa, zipCode } = this.state;

        const headerStyle = {
            margin: "0",
            fontSize: "16px",
            letterSpacing: "2px",
            color: "#9FC6CC"
        };

        const marginHoriz = onMobile ? "10px" : "90px";

        const btnStyle = {
            paddingLeft: marginHoriz,
            paddingRight: marginHoriz,

            border: "none",
            letterSpacing: "1px",
            backgroundColor: "#F17432",
            borderRadius: "0px",
            fontWeight: "bold",

            ...(onMobile ? {width: "55px"} : {}),
        };

        const vertPadding = "40px";
        const sectionWidth = onMobile ? "100%" : "30%";

        const settingsBtnStyle = {
            letterSpacing: "2px",
            fontWeight: "bold",
            padding: "10px 25px 10px 25px",

            width: sectionWidth,
            textAlign: "center",
            fontSize: "16px",
        };

        return (
            <div className="primary-wrapper col center-self primary" id="account-settings"
                style={{
                    backgroundColor: "white",

                    ...(onMobile ? {
                        width: "90%",
                        paddingTop: "0px",
                        paddingBottom: "10px",
                        minHeight: "unset",

                        ...(this.props.settings ? {
                            marginTop: "50px"
                        } : {}),
                    } : {}),
                }}
            >
                <Modal id="talkSchedule" isOpen={this.state.modal} style={{
                    height: "auto",
                    ...(onMobile ? {} : {width: "750px"})
                }}>
                    <X stroke="white" id="close-modal" size="25" onClick={() => {
                        this.setState({modal: false}, () => {
                            if (this.state.modalCb) {
                                this.state.modalCb();
                            }
                        });
                    }} />
                    <h1 style={window.innerWidth <= 700 ? {
                        paddingLeft: "50px",
                        paddingRight: "50px",
                    } : {}} className="chat-modal">{this.state.modalMsg}</h1>
                </Modal>

                <div>
                    <h4 style={{
                        ...headerStyle,
                        paddingTop: onMobile ? "20px" : "10px",
                    }}>UPDATE EMAIL</h4>
                </div>
                <input className="flat-input"
                    type="username"
                    name="email"
                    value={email}
                    placeholder="Email"
                    onChange={this.onChange}
                    style={{
                        width: "100%",
                        margin: "15px 0 18px 0"
                    }}
                />

                <div>
                    <h4 style={{
                        ...headerStyle,
                        paddingTop: "20px",
                    }}>POSTAL CODE</h4>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <Select
                        className="native-select"
                        name="country"
                        defaultValue={country}
                        onChange={this.selectOnChange}
                        style={{
                            height: "40px",
                            margin: "12px 0",
                            border: "1px solid black",
                        }}
                    >
                        {this.getCountryDropDown()}
                    </Select>

                    <input name="zipCode"
                        id="zipcode-input"
                        className="flat-input"
                        value={zipCode}
                        placeholder="Postal Code"
                        onChange={this.onChange}
                        style={{
                            width: "100%",
                            ...(onMobile ? {} : {marginLeft: "0px"})
                        }}
                    />
                </div>

                <div>
                    <h4 style={{
                        ...headerStyle,
                        paddingTop: "20px",
                    }}>CHANGE PASSWORD</h4>
                </div>
                <div style={{
                    display:"flex",
                    flexDirection: "column",
                    width: "100%"}}>
                    <input name="currentPassword"
                        id="currentpassword-input"
                        type="password"
                        className="flat-input settings-password-input"
                        value={currentPassword}
                        placeholder="Current Password"
                        onChange={this.onChange}
                    />
                    <input name="newPassword"
                        id="newpassword-input"
                        type="password"
                        className="flat-input settings-password-input"
                        value={newPassword}
                        placeholder="New Password"
                        onChange={this.onChange}
                    />
                    <input name="newPasswordCopy"
                        id="newpasswordcopy-input"
                        type="password"
                        className="flat-input settings-password-input"
                        value={newPasswordCopy}
                        placeholder="Confirm New Password"
                        onChange={this.onChange}
                    />

                    {this.displayAlert()}

                    <div
                        className="account-settings-save">
                        <div className="btn" style={{
                            ...btnStyle,
                            ...(onMobile && this.props.settings ? {
                                minWidth: "160px",
                                marginBottom: "20px",
                            } : {minWidth: "170px",
                                paddingLeft: "0",
                                paddingRight: "0"}),
                            marginLeft: "auto",
                            marginRight: "auto",
                            fontSize: "1rem",
                            minHeight: "45px"
                        }} onClick={this.onSubmit}>
                        SAVE CHANGES
                        </div>
                    </div>
                </div>

                <div>
                    <h4 style={{
                        ...headerStyle,
                        paddingBottom: "20px",
                        ...(onMobile ? {textAlign: "center"} : {}),
                    }}>TWO FACTOR AUTHENTICATION</h4>
                </div>
                <div style={{
                    ...settingsBtnStyle,
                    backgroundColor: "#9FC6CC",
                    ...(onMobile ? {width: "200px", marginLeft: "auto", marginRight: "auto"} : {}),
                }} id="travel-toggle" onClick={() => {this.onAutoToggle("tfa");}}>
                    {tfa ? "DISABLE" : "ENABLE"}
                </div>

                <div>
                    <h4 style={{
                        ...headerStyle,
                        paddingBottom: "20px",
                        paddingTop: vertPadding,
                        ...(onMobile ? {textAlign: "center"} : {}),
                    }}>EDIT SUBSCRIPTION / UPDATE PAYMENT INFORMATION</h4>
                </div>
                <Link to="/subscribe" style={{
                    ...settingsBtnStyle,
                    backgroundColor: "#9FC6CC",
                    borderRadius: "0px",
                    ...(onMobile ? {width: "200px", marginLeft: "auto", marginRight: "auto"} : {}),
                }} className="btn no-hover">
                    VIEW
                </Link>

                <div>
                    <h4 style={{
                        ...headerStyle,
                        paddingBottom: "20px",
                        paddingTop: vertPadding,
                        ...(onMobile ? {textAlign: "center"} : {}),
                    }}>FREEZE ACCOUNT</h4>
                </div>
                <div style={{
                    ...settingsBtnStyle,
                    backgroundColor: "#9FC6CC",
                    ...(onMobile ? {width: "200px", marginLeft: "auto", marginRight: "auto"} : {}),
                }} id="travel-toggle" onClick={() => {this.onAutoToggle("frozen");}}>
                    {frozen ? "UNFREEZE" : "FREEZE"}
                </div>
                <div style={{
                    color: "#1E2738",
                    paddingTop: "20px",
                    ...(onMobile ? {textAlign: "center"} : {}),
                }}>
                    Freeze Account to disable new talk requests and re-schedule current requests.
                    Simply Unfreeze when you have more time. You will still be charged for your
                    subscription while your account is frozen.
                </div>

                <div>
                    <h4 style={{
                        ...headerStyle,
                        paddingBottom: "20px",
                        paddingTop: vertPadding,
                        ...(onMobile ? {textAlign: "center"} : {}),
                    }}>DELETE ACCOUNT</h4>
                </div>
                <Link to="/delete" style={{
                    ...settingsBtnStyle,
                    backgroundColor: "#9FC6CC",
                    borderRadius: "0px",
                    ...(onMobile ? {
                        marginBottom: "20px",
                        width: "200px",

                        marginLeft: "auto",
                        marginRight: "auto"} : {}),
                }} className="btn no-hover">
                    DELETE
                </Link>
            </div>
        );
    }
}

export default AccountSettings;
