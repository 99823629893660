import React from "react";

const Select = ({name, className, onChange, defaultValue, children, style}) => {

    let c = "select-wrapper";

    if (className) {
        c += (" " + className);
    }

    return (
        <div className={c} style={style}>
            <select className="flat-select w-fill" defaultValue={defaultValue} name={name} onChange={onChange}>
                {defaultValue ? <option value={""} key={"default"} hidden>{defaultValue}</option> : null}
                {children}
            </select>

            <svg focusable="false" className="arrow" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M7 10l5 5 5-5z"/>
            </svg>

        </div>
    );
};

export default Select;
