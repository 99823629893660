import React, { Component } from "react";
import axios from "axios";

import "../../styles/RolloutFeedbackButton.css";

import { Button } from "../NewSettingsComponents";

class RolloutFeedbackButton extends Component {
    animationLength = 0.2;  // Seconds

    constructor(props) {
        super(props);

        this.state = {
            transitioning: false,  // Transitioning is true during open/close animation.
            expanded: false,       // Expanded is true immediately after rollout starts.
            pending: false,
            submitted: false,      // User submits form -> pending=true ...fetch... => error=true or submitted=true
            error: false,
            reportText: "",
        };
    }

    submit() {
        if (this.state.reportText !== "" && !this.state.pending) {
            this.setState({pending: true}, () => {
                axios.post("/api/reports/new",
                    {type: this.props.type, reportText: this.state.reportText, _id: this.props._id},
                    {withCredentials: true})
                    .then(() => this.setState({submitted: true, pending: false}))
                    .catch(() => {
                        this.setState({error: true, pending: false});
                    });
            });
        } else {
            this.setState({error: true});
        }
    }

    render() {
        const expandContract = isExpanded => () => this.setState(() => {
            this.setState({
                transitioning: true,
                expanded: isExpanded
            });

            setTimeout(() => this.setState({transitioning: false}), this.animationLength * 1000);
        });

        let onButtonClick;
        let buttonText;

        if (this.state.expanded && (this.state.submitted || this.state.error)) {
            onButtonClick = () => {
                expandContract(false)();
                setTimeout(() => this.setState({submitted: false, reportText: "", error: false}),
                    this.animationLength * 1000);
            };

            buttonText = "CLOSE";
        } else if (this.state.expanded) {
            onButtonClick = () => this.submit();
            buttonText = "SUBMIT";
        } else {
            onButtonClick = expandContract(true);
            buttonText = this.props.buttonText || "REPORT";
        }

        const isUserReport = this.props.type === "userReport";
        let className = "rollout-form-text-input";

        className = `${className} ${this.state.error ? "errored" : ""}`;
        className = `${className} ${isUserReport ? "user-reporting" : ""}`;
        className = `${className} ${this.state.expanded ? "expanded" : ""}`;
        className = `${className} ${this.state.submitted ? "submitted" : ""}`;
        className = `${className} ${this.state.transitioning ? "transitioning" : ""}`;

        const inputProps = {
            className,

            ref: i => {this.input = i;},
            value: this.state.reportText,

            onChange: e => this.setState({reportText: e.target.value}),
            disabled: this.state.submitted || this.state.error ? "disabled" : "",
            placeholder: this.state.submitted || this.state.error ? "" : this.props.placeholder,
        };

        return (
            <div >
                <form onSubmit={e => {e.preventDefault(); this.submit();}}>
                    <Button type="secondary" /*style={{...this.props.buttonStyle,}}*/ onClick={onButtonClick}>
                        {buttonText}
                    </Button>
                    {isUserReport ?
                        <textarea {...inputProps} /> :
                        <input {...inputProps} />
                    }
                </form>
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevState.expanded && this.state.expanded) {
            this.input.focus();
            this.props.expandHandler();
        }
    }
}

RolloutFeedbackButton.defaultProps = {
    buttonStyle: {},
    color: "#262F6D",
    expandHandler: () => {},
};

export default RolloutFeedbackButton;
