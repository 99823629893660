import React, {Component} from "react";

import axios from "axios";

import Login from "./auth/Login";
import {isMobile} from "../utils/utils";

import AccountSettings from "./AccountSettings";
import FriendQuestions from "./questions/FriendQuestions";
import DatingQuestions from "./questions/DatingQuestions";
import CollabQuestions from "./questions/CollabQuestions";

import ConversationPillar from "../images/pillars/friendship/new.svg";
import CollaboratePillar from "../images/pillars/collaborate/new.svg";
import RelationshipPillar from "../images/pillars/relationship/new.svg";

import { THEME, Button } from "./NewSettingsComponents";

import "../styles/Settings.css";

const TAB = {
    ACCOUNT: "Account Settings",
    GENERAL: "Morphkey Profile",
    FRIEND: "Conversation Profile",
    DATING: "Relationship Profile",
    COLLAB: "Collaborate Profile",
};

class Settings extends Component {

    constructor(props) {
        super(props);

        const tab = Settings.getTab(props);

        this.state = {
            tab,

            isFriend: false,
            isDating: false,
            isCollaborate: false,

            gotCurrentUser: false,
            currentUser: undefined,
            onMobile: isMobile(),

            gotCurrentSettings: false,
        };

        this.createProfile = this.createProfile.bind(this);
        this.reloadCurrentSettings = this.reloadCurrentSettings.bind(this);
    }

    static getTab = (props) => {
        const passedState = props.location.state;
        return passedState ? passedState.settingsTab : TAB.ACCOUNT;
    };

    static getDerivedStateFromProps(props) {
        return isMobile() ? {
            tab: Settings.getTab(props)
        } : {};
    }

    componentDidMount() {
        axios.get("/api/settings").then((result) => {
            if (result.data.success) {
                const currentUser = result.data.currentUser;

                this.setState({
                    isFriend: currentUser.friendAccount,
                    isDating: currentUser.datingAccount,
                    isCollaborate: currentUser.collabMusic,

                    gotCurrentSettings: true,
                    gotCurrentUser: true,
                    currentUser
                }, () => {
                    const accountSettings = document.getElementById("account-settings");

                    if (accountSettings) {
                        accountSettings.style.display = "none";

                        setTimeout(() => {
                            accountSettings.style.display = "";
                        }, 1000);
                    }
                });
            }
        }).catch(() => {
            this.setState({
                gotCurrentUser: true,
                currentUser: undefined
            });
        });
    }

    reloadCurrentSettings() {
        axios.get("/api/settings").then((result) => {
            if (result.data.success) {
                const currentUser = result.data.currentUser;
                this.setState({currentUser});
            }
        });
    }

    createProfile = () => {
        const tabMap = {
            [TAB.FRIEND]: "isFriend",
            [TAB.DATING]: "isDating",
            [TAB.COLLAB]: "isCollaborate"
        };

        const newProfile = tabMap[this.state.tab];
        const state = this.state;

        state[newProfile] = true;
        this.setState(state);
    };

    setTab = (page) => () => {
        this.setState({ tab: page }, () => {
            const createButton = document.getElementById("create-button");
            const accountManagement = document.getElementById("accountManagement");

            if (createButton) {
                createButton.style.height = `${accountManagement.offsetHeight}px`;
            }
        });
    };

    createRow = (page, klass = "") => {
        const selected = page === this.state.tab;
        const color = selected ? THEME.colorSecondary : "white";

        const text = <>
          {page.split(" ")[0]}
          <br/>
          {page.split(" ")[1]}
        </>;

        return (
            <li onClick={this.setTab(page)}>
                <div
                    style={{
                        textTransform: "unset",
                        fontSize: "18px",
                        letterSpacing: "2px",
                        fontWeight: 700,
                        color,
                        cursor: "pointer"}}>
                    {text}
                </div>
            </li>
        );
    };

    getCreateScreen = imageSrc => {
        const content = <>
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                justifyContent: "center",
                alignItems: "center",
                margin: THEME.marginXSmall}}>
            <img src={imageSrc} style={{width: "200px", height: "200px"}}/>
            <Button type="secondary" onClick={this.createProfile} style={{marginTop: THEME.marginSmall}}>
              CREATE PROFILE
            </Button>
        </div>
      </>;
        return content;
    }

    renderHeader = (htmlElems, hasViewProfile = false) => {
        const {currentUser, onMobile} = this.state;
        const profileType = currentUser.friendAccount ? "conversation" : "relationship";
        return (
            <div className="account-settings-header">
                <h1 style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: onMobile ? "30px" : "40px",
                    margin: "15px 0",

                    ...(onMobile ? {
                        width: "105%",
                        marginLeft: "-5px",
                        marginRight: "-5px",
                    } : {}),
                }}>
                    {htmlElems}
                </h1>
                {hasViewProfile &&
                <h1 style={{
                    fontSize: "20px",
                    fontWeight: 300,
                    textAlign: "center",
                    color: "#1E2738",
                    margin: 0,


                    ...(onMobile ? {
                        width: "105%",
                        marginLeft: "-5px",
                        marginRight: "-5px",
                    } : {}),
                }}>
                    <a href={`/profile/${profileType}/${currentUser.uniqueId}`}
                        target="_blank" rel="noreferrer noopener"
                    >View Profile</a>
                </h1>}
            </div>
        );
    };

    render() {
        const { currentUser, gotCurrentSettings, gotCurrentUser,
            isFriend, isDating, isCollaborate, tab, onMobile } = this.state;

        if (gotCurrentUser && !currentUser) {
            return <Login />;
        }

        let content;

        if (!gotCurrentUser) {
            content = <div />;
        } else {
            if (!gotCurrentSettings) {
                content = <div />;
            } else {
                const innerContent = (
                    <React.Fragment>
                        {tab === TAB.ACCOUNT && (
                            <React.Fragment>
                                {this.renderHeader("Account Settings", true)}
                                <AccountSettings
                                    reloadCurrentSettings={this.reloadCurrentSettings}
                                    currentUser={currentUser}
                                    settings
                                />
                            </React.Fragment>)}
                        {tab === TAB.GENERAL && (
                            <React.Fragment>
                                {this.renderHeader("Morphkey Questions")}
                                <FriendQuestions paged={false} currentUser={currentUser}
                                    update updatePage={1} settings />
                            </React.Fragment>)}
                        {tab === TAB.FRIEND && isFriend && (
                            <React.Fragment>
                                {this.renderHeader(
                                    [<React.Fragment>
                                        <span>Conversation</span><br/>
                                        <span>Questions</span>
                                    </React.Fragment>])}
                                <FriendQuestions paged={false} currentUser={currentUser}
                                    update updatePage={2} settings />
                            </React.Fragment>)}
                        {tab === TAB.FRIEND && !isFriend && (
                            <React.Fragment>
                                {this.renderHeader([<React.Fragment>
                                    <span>Conversation</span><br/>
                                    <span>Questions</span>
                                </React.Fragment>])}
                                {this.getCreateScreen(ConversationPillar)}
                            </React.Fragment>
                        )}
                        {tab === TAB.DATING && isDating && (
                            <DatingQuestions gender={currentUser.gender}
                                currentUser={currentUser} update settings shouldHide={false}/>)}
                        {tab === TAB.DATING && !isDating && (
                            <React.Fragment>
                                {this.renderHeader([<React.Fragment>
                                    <span>Relationship</span><br/>
                                    <span>Questions</span>
                                </React.Fragment>])}
                                {this.getCreateScreen(RelationshipPillar)}
                            </React.Fragment>
                        )}
                        {tab === TAB.COLLAB && isCollaborate && (
                            <CollabQuestions currentUser={currentUser} update settings />)}
                        {tab === TAB.COLLAB && !isCollaborate && (
                            <React.Fragment>
                                {this.renderHeader([<React.Fragment>
                                    <span>Collaborate</span><br/>
                                    <span>Questions</span>
                                </React.Fragment>])}
                                {this.getCreateScreen(CollaboratePillar)}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                );

                content = <>
                  <div style={{backgroundColor: THEME.colorAccent, display: onMobile ? "none" : "unset"}}>
                      <ul
                          style={{
                              display: "grid",
                              gridAutoFlow: "row",
                              rowGap: THEME.marginSmall,
                              padding: THEME.marginSmall,
                              marginLeft: "-4px"}}>
                          {this.createRow(TAB.ACCOUNT, "create-account")}
                          {this.createRow(TAB.GENERAL)}
                          {this.createRow(TAB.FRIEND)}
                          {this.createRow(TAB.DATING)}
                          {this.createRow(TAB.COLLAB)}
                      </ul>
                  </div>
                  <div className="settingsContent" style={{display: "flex", flexDirection: "column"}}>
                      {innerContent}
                  </div>
                </>;
            }
        }

        return (
            <div style={{display: "flex", flexDirection: "row", flexGrow: 1}}>
                {content}
            </div>
        );
    };
}

export {Settings, TAB};
