const countryList = [
    "Afghanistan",
    "Åland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo, Republic of the (Brazzaville)",
    "Congo, the Democratic Republic of the (Kinshasa)",
    "Cook Islands",
    "Costa Rica",
    "Côte d'Ivoire, Republic of",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Islas Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern and Antarctic Lands",
    "Gabon",
    "Gambia, The",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (Vatican City)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Republic of",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Democratic People's Republic of",
    "Korea, Republic of",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia, Republic of",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Réunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "United States Minor Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela, Bolivarian Republic of",
    "Vietnam",
    "Virgin Islands, British",
    "Virgin Islands, U.S.",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe"
];

const regionMap = {
    Afghanistan: [
        "Badakhshan",
        "Badghis",
        "Baghlan",
        "Balkh",
        "Bamyan",
        "Daykundi",
        "Farah",
        "Faryab",
        "Ghazni",
        "Ghor",
        "Helmand",
        "Herat",
        "Jowzjan",
        "Kabul",
        "Kandahar",
        "Kapisa",
        "Khost",
        "Kunar",
        "Kunduz",
        "Laghman",
        "Logar",
        "Maidan Wardak",
        "Nangarhar",
        "Nimruz",
        "Nuristan",
        "Paktia",
        "Paktika",
        "Panjshir",
        "Parwan",
        "Samangan",
        "Sar-e Pol",
        "Takhar",
        "Urozgan",
        "Zabul"
    ],
    "Åland Islands": [
        "Brändö",
        "Eckerö",
        "Finström",
        "Föglö",
        "Geta",
        "Hammarland",
        "Jomala",
        "Kumlinge",
        "Kökar",
        "Lemland",
        "Lumparland",
        "Mariehamn",
        "Saltvik",
        "Sottunga",
        "Sund",
        "Vårdö"
    ],
    Albania: [
        "Berat",
        "Dibër",
        "Durrës",
        "Elbasan",
        "Fier",
        "Gjirokastër",
        "Korçë",
        "Kukës",
        "Lezhë",
        "Shkodër",
        "Tirana",
        "Vlorë"
    ],
    Algeria: [
        "Adrar",
        "Aïn Defla",
        "Aïn Témouchent",
        "Algiers",
        "Annaba",
        "Batna",
        "Béchar",
        "Béjaïa",
        "Biskra",
        "Blida",
        "Bordj Bou Arréridj",
        "Bouïra",
        "Boumerdès",
        "Chlef",
        "Constantine",
        "Djelfa",
        "El Bayadh",
        "El Oued",
        "El Tarf",
        "Ghardaïa",
        "Guelma",
        "Illizi",
        "Jijel",
        "Khenchela",
        "Laghouat",
        "Mascara",
        "Médéa",
        "Mila",
        "Mostaganem",
        "Msila",
        "Naâma",
        "Oran",
        "Ouargla",
        "Oum el Bouaghi",
        "Relizane",
        "Saïda",
        "Sétif",
        "Sidi Bel Abbès",
        "Skikda",
        "Souk Ahras",
        "Tamanghasset",
        "Tébessa",
        "Tiaret",
        "Tindouf",
        "Tipaza",
        "Tissemsilt",
        "Tizi Ouzou",
        "Tlemcen"
    ],
    "American Samoa": [
        "Tutuila",
        "Aunu'u",
        "Ta'ū",
        "Ofu‑Olosega",
        "Rose Atoll",
        "Swains Island"
    ],
    Andorra: [
        "Andorra la Vella",
        "Canillo",
        "Encamp",
        "Escaldes-Engordany",
        "La Massana",
        "Ordino",
        "Sant Julià de Lòria"
    ],
    Angola: [
        "Bengo",
        "Benguela",
        "Bié",
        "Cabinda",
        "Cuando Cubango",
        "Cuanza Norte",
        "Cuanza Sul",
        "Cunene",
        "Huambo",
        "Huíla",
        "Luanda",
        "Lunda Norte",
        "Lunda Sul",
        "Malanje",
        "Moxico",
        "Namibe",
        "Uíge",
        "Zaire"
    ],
    Anguilla: [
        "Anguilla",
        "Anguillita Island",
        "Blowing Rock",
        "Cove Cay",
        "Crocus Cay",
        "Deadman's Cay",
        "Dog Island",
        "East Cay",
        "Little Island",
        "Little Scrub Island",
        "Mid Cay",
        "North Cay",
        "Prickly Pear Cays",
        "Rabbit Island",
        "Sandy Island/Sand Island",
        "Scilly Cay",
        "Scrub Island",
        "Seal Island",
        "Sombrero/Hat Island",
        "South Cay",
        "South Wager Island",
        "West Cay"
    ],
    Antarctica: [
        "Antarctica"
    ],
    "Antigua and Barbuda": [
        "Antigua Island",
        "Barbuda Island",
        "Bird Island",
        "Bishop Island",
        "Blake Island",
        "Crump Island",
        "Dulcina Island",
        "Exchange Island",
        "Five Islands",
        "Great Bird Island",
        "Green Island",
        "Guiana Island",
        "Hawes Island",
        "Hells Gate Island",
        "Henry Island",
        "Johnson Island",
        "Kid Island",
        "Lobster Island",
        "Maiden Island",
        "Moor Island",
        "Nanny Island",
        "Pelican Island",
        "Prickly Pear Island",
        "Rabbit Island",
        "Red Head Island",
        "Redonda Island",
        "Sandy Island",
        "Smith Island",
        "The Sisters",
        "Vernon Island",
        "Wicked Will Island",
        "York Island"
    ],
    Argentina: [
        "Buenos Aires",
        "Capital Federal",
        "Catamarca",
        "Chaco",
        "Chubut",
        "Córdoba",
        "Corrientes",
        "Entre Ríos",
        "Formosa",
        "Jujuy",
        "La Pampa",
        "La Rioja",
        "Mendoza",
        "Misiones",
        "Neuquén",
        "Río Negro",
        "Salta",
        "San Juan",
        "San Luis",
        "Santa Cruz",
        "Santa Fe",
        "Santiago del Estero",
        "Tierra del Fuego",
        "Tucumán"
    ],
    Armenia: [
        "Aragatsotn",
        "Ararat",
        "Armavir",
        "Gegharkunik",
        "Kotayk",
        "Lori",
        "Shirak",
        "Syunik",
        "Tavush",
        "Vayots Dzor",
        "Yerevan"
    ],
    Aruba: [
        "Aruba"
    ],
    Australia: [
        "Australian Capital Territory",
        "New South Wales",
        "Northern Territory",
        "Queensland",
        "South Australia",
        "Tasmania",
        "Victoria",
        "Western Australia"
    ],
    Austria: [
        "Burgenland",
        "Kärnten",
        "Niederösterreich",
        "Oberösterreich",
        "Salzburg",
        "Steiermark",
        "Tirol",
        "Vorarlberg",
        "Wien"
    ],
    Azerbaijan: [
        "Abşeron",
        "Ağcabədi",
        "Ağdam",
        "Ağdaş",
        "Ağstafa",
        "Ağsu",
        "Astara",
        "Babək",
        "Balakən",
        "Bərdə",
        "Beyləqan",
        "Biləsuvar",
        "Cəbrayıl",
        "Cəlilabad",
        "Culfa",
        "Daşkəsən",
        "Füzuli",
        "Gədəbəy",
        "Goranboy",
        "Göyçay",
        "Göygöl",
        "Hacıqabul",
        "İmişli",
        "İsmayıllı",
        "Kəlbəcər",
        "Kǝngǝrli",
        "Kürdəmir",
        "Laçın",
        "Lənkəran",
        "Lerik",
        "Masallı",
        "Neftçala",
        "Oğuz",
        "Ordubad",
        "Qəbələ",
        "Qax",
        "Qazax",
        "Qobustan",
        "Quba",
        "Qubadli",
        "Qusar",
        "Saatlı",
        "Sabirabad",
        "Şabran",
        "Sədərək",
        "Şahbuz",
        "Şəki",
        "Salyan",
        "Şamaxı",
        "Şəmkir",
        "Samux",
        "Şərur",
        "Siyəzən",
        "Şuşa",
        "Tərtər",
        "Tovuz",
        "Ucar",
        "Xaçmaz",
        "Xızı",
        "Xocalı",
        "Xocavənd",
        "Yardımlı",
        "Yevlax",
        "Zəngilan",
        "Zaqatala",
        "Zərdab"
    ],
    Bahamas: [
        "Acklins Island",
        "Berry Islands",
        "Bimini",
        "Black Point",
        "Cat Island",
        "Central Abaco",
        "Crooked Island and Long Cay",
        "East Grand Bahama",
        "Exuma",
        "Freeport",
        "Fresh Creek",
        "Governor's Harbour",
        "Green Turtle Cay",
        "Harbour Island",
        "High Rock",
        "Inagua",
        "Kemps Bay",
        "Long Island",
        "Marsh Harbour",
        "Mayaguana",
        "Moore’s Island",
        "New Providence",
        "Nichollstown and Berry Islands",
        "North Abaco",
        "North Andros",
        "North Eleuthera",
        "Ragged Island",
        "Rock Sound",
        "San Salvador and Rum Cay",
        "Sandy Point",
        "South Abaco",
        "South Andros",
        "South Eleuthera",
        "West Grand Bahama"
    ],
    Bahrain: [
        "Al Janūbīyah",
        "Al Manāmah",
        "Al Muḩarraq",
        "Al Wusţá",
        "Ash Shamālīyah"
    ],
    Bangladesh: [
        "Barisal",
        "Chittagong",
        "Dhaka",
        "Khulna",
        "Rajshahi",
        "Rangpur",
        "Sylhet"
    ],
    Barbados: [
        "Christ Church",
        "Saint Andrew",
        "Saint George",
        "Saint James",
        "Saint John",
        "Saint Joseph",
        "Saint Lucy",
        "Saint Michael",
        "Saint Peter",
        "Saint Philip",
        "Saint Thomas"
    ],
    Belarus: [
        "Brest voblast",
        "Gorod Minsk",
        "Homiel voblast",
        "Hrodna voblast",
        "Mahilyow voblast",
        "Minsk voblast",
        "Vitsebsk voblast"
    ],
    Belgium: [
        "Bruxelles-Capitale",
        "Région Flamande",
        "Région Wallonië"
    ],
    Belize: [
        "Belize District",
        "Cayo District",
        "Corozal District",
        "Orange Walk District",
        "Stann Creek District",
        "Toledo District"
    ],
    Benin: [
        "Alibori",
        "Atakora",
        "Atlantique",
        "Borgou",
        "Collines Department",
        "Donga",
        "Kouffo",
        "Littoral Department",
        "Mono Department",
        "Ouémé",
        "Plateau",
        "Zou"
    ],
    Bermuda: [
        "City of Hamilton",
        "Devonshire Parish",
        "Hamilton Parish",
        "Paget Parish",
        "Pembroke Parish",
        "Sandys Parish",
        "Smith's Parish",
        "Southampton Parish",
        "St. George's Parish",
        "Town of St. George",
        "Warwick Parish"
    ],
    Bhutan: [
        "Bumthang",
        "Chhukha",
        "Dagana",
        "Gasa",
        "Haa",
        "Lhuntse",
        "Mongar",
        "Paro",
        "Pemagatshel",
        "Punakha",
        "Samdrup Jongkhar",
        "Samtse",
        "Sarpang",
        "Thimphu",
        "Trashigang",
        "Trashiyangtse",
        "Trongsa",
        "Tsirang",
        "Wangdue Phodrang",
        "Zhemgang"
    ],
    Bolivia: [
        "Beni",
        "Chuquisaca",
        "Cochabamba",
        "La Paz",
        "Oruro",
        "Pando",
        "Potosí",
        "Santa Cruz",
        "Tarija"
    ],
    "Bonaire, Sint Eustatius and Saba": [
        "Bonaire",
        "Saba Isand",
        "Sint Eustatius"
    ],
    "Bosnia and Herzegovina": [
        "Brčko Distrikt",
        "Federacija Bosne i Hercegovine",
        "Republika Srpska"
    ],
    Botswana: [
        "Central",
        "Ghanzi",
        "Kgalagadi",
        "Kgatleng",
        "Kweneng",
        "North West",
        "North-East",
        "South East",
        "Southern"
    ],
    "Bouvet Island": [
        "Bouvet Island"
    ],
    Brazil: [
        "Acre",
        "Alagoas",
        "Amapá",
        "Amazonas",
        "Bahia",
        "Ceará",
        "Distrito Federal",
        "Espírito Santo",
        "Goiás",
        "Maranhão",
        "Mato Grosso",
        "Mato Grosso do Sul",
        "Minas Gerais",
        "Para",
        "Paraiba",
        "Paraná",
        "Pernambuco",
        "Piauí",
        "Rio de Janeiro",
        "Rio Grande do Norte",
        "Rio Grande do Sul",
        "Rondônia",
        "Roraima",
        "Santa Catarina",
        "Sao Paulo",
        "Sergipe",
        "Tocantins"
    ],
    "British Indian Ocean Territory": [
        "British Indian Ocean Territory"
    ],
    "Brunei Darussalam": [
        "Belait",
        "Brunei Muara",
        "Temburong",
        "Tutong"
    ],
    Bulgaria: [
        "Blagoevgrad",
        "Burgas",
        "Dobrich",
        "Gabrovo",
        "Jambol",
        "Khaskovo",
        "Kjustendil",
        "Kurdzhali",
        "Lovech",
        "Montana",
        "Pazardzhik",
        "Pernik",
        "Pleven",
        "Plovdiv",
        "Razgrad",
        "Ruse",
        "Shumen",
        "Silistra",
        "Sliven",
        "Smoljan",
        "Sofija",
        "Sofija-Grad",
        "Stara Zagora",
        "Turgovishhe",
        "Varna",
        "Veliko Turnovo",
        "Vidin",
        "Vraca"
    ],
    "Burkina Faso": [
        "Balé",
        "Bam/Lake Bam",
        "Banwa Province",
        "Bazèga",
        "Bougouriba",
        "Boulgou Province",
        "Boulkiemdé",
        "Comoé/Komoe",
        "Ganzourgou Province",
        "Gnagna",
        "Gourma Province",
        "Houet",
        "Ioba",
        "Kadiogo",
        "Kénédougou",
        "Komondjari",
        "Kompienga",
        "Kossi Province",
        "Koulpélogo",
        "Kouritenga",
        "Kourwéogo",
        "Léraba",
        "Loroum",
        "Mouhoun",
        "Namentenga",
        "Naouri/Nahouri",
        "Nayala",
        "Noumbiel",
        "Oubritenga",
        "Oudalan",
        "Passoré",
        "Poni",
        "Sanguié",
        "Sanmatenga",
        "Séno",
        "Sissili",
        "Soum",
        "Sourou",
        "Tapoa",
        "Tui/Tuy",
        "Yagha",
        "Yatenga",
        "Ziro",
        "Zondoma",
        "Zoundwéogo"
    ],
    Burundi: [
        "Bubanza",
        "Bujumbura Mairie",
        "Bujumbura Rural",
        "Bururi",
        "Cankuzo",
        "Cibitoke",
        "Gitega",
        "Karuzi",
        "Kayanza",
        "Kirundo",
        "Makamba",
        "Muramvya",
        "Muyinga",
        "Mwaro",
        "Ngozi",
        "Rutana",
        "Ruyigi"
    ],
    Cambodia: [
        "Baat Dambang",
        "Banteay Mean Chey",
        "Kampong Chaam",
        "Kampong Chhnang",
        "Kampong Spueu",
        "Kampong Thum",
        "Kampot",
        "Kandaal",
        "Kaoh Kong",
        "Kracheh",
        "Krong Kaeb",
        "Krong Pailin",
        "Krong Preah Sihanouk",
        "Mondol Kiri",
        "Otdar Mean Chey",
        "Phnom Penh",
        "Pousaat",
        "Preah Vihear",
        "Prey Veaeng",
        "Rotanah Kiri",
        "Siem Reab",
        "Stueng Treng",
        "Svaay Rieng",
        "Taakaev",
        "Tbong Khmum"
    ],
    Cameroon: [
        "Adamaoua",
        "Centre",
        "Est",
        "Extrême-Nord",
        "Littoral",
        "Nord",
        "Nord-Ouest",
        "Ouest",
        "Sud",
        "Sud-Ouest"
    ],
    Canada: [
        "Alberta",
        "British Columbia",
        "Manitoba",
        "New Brunswick",
        "Newfoundland and Labrador",
        "Northwest Territories",
        "Nova Scotia",
        "Nunavut",
        "Ontario",
        "Prince Edward Island",
        "Quebec",
        "Saskatchewan",
        "Yukon"
    ],
    "Cape Verde": [
        "Boa Vista",
        "Brava",
        "Calheta de São Miguel",
        "Maio",
        "Mosteiros",
        "Paúl",
        "Porto Novo",
        "Praia",
        "Ribeira Brava",
        "Ribeira Grande",
        "Sal",
        "Santa Catarina",
        "Santa Cruz",
        "São Domingos",
        "São Filipe",
        "São Nicolau",
        "São Vicente",
        "Tarrafal",
        "Tarrafal de São Nicolau"
    ],
    "Cayman Islands": [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
    ],
    "Central African Republic": [
        "Bamingui-Bangoran",
        "Bangui",
        "Basse-Kotto",
        "Haute-Kotto",
        "Haut-Mbomou",
        "Kémo",
        "Lobaye",
        "Mambéré-Kadéï",
        "Mbomou",
        "Nana-Grebizi",
        "Nana-Mambéré",
        "Ombella-M'Poko",
        "Ouaka",
        "Ouham",
        "Ouham Péndé",
        "Sangha-Mbaéré",
        "Vakaga"
    ],
    Chad: [
        "Bahr el Ghazal",
        "Batha",
        "Borkou",
        "Chari-Baguirmi",
        "Ennedi-Est",
        "Ennedi-Ouest",
        "Guéra",
        "Hadjer Lamis",
        "Kanem",
        "Lac",
        "Logone Occidental",
        "Logone Oriental",
        "Mondoul",
        "Mayo-Kébbi-Est",
        "Moyen-Chari",
        "Ouaddai",
        "Salamat",
        "Sila",
        "Tandjilé",
        "Tibesti",
        "Ville de Ndjamena",
        "Wadi Fira"
    ],
    Chile: [
        "Aisén del General Carlos Ibáñez del Campo",
        "Antofagasta",
        "Araucanía",
        "Arica y Parinacota",
        "Atacama",
        "Bío-Bío",
        "Coquimbo",
        "Libertador General Bernardo O'Higgins",
        "Los Lagos",
        "Los Ríos",
        "Magallanes y Antartica Chilena",
        "Marga-Marga",
        "Maule",
        "Región Metropolitana de Santiago",
        "Tarapacá",
        "Valparaíso"
    ],
    China: [
        "Anhui",
        "Beijing",
        "Chongqing",
        "Fujian",
        "Gansu",
        "Guangdong",
        "Guangxi",
        "Guizhou",
        "Hainan",
        "Hebei",
        "Heilongjiang",
        "Henan",
        "Hong Kong",
        "Hubei",
        "Hunan",
        "Inner Mongolia",
        "Jiangsu",
        "Jiangxi",
        "Jilin",
        "Liaoning",
        "Macau",
        "Ningxia",
        "Qinghai",
        "Shaanxi",
        "Shandong",
        "Shanghai",
        "Shanxi",
        "Sichuan",
        "Tianjin",
        "Tibet",
        "Xinjiang",
        "Yunnan",
        "Zhejiang"
    ],
    "Christmas Island": [
        "Christmas Island"
    ],
    "Cocos (Keeling) Islands": [
        "Direction Island",
        "Home Island",
        "Horsburgh Island",
        "North Keeling Island",
        "South Island",
        "West Island"
    ],
    Colombia: [
        "Amazonas",
        "Antioquia",
        "Arauca",
        "Archipiélago de San Andrés",
        "Atlántico",
        "Bogotá D.C.",
        "Bolívar",
        "Boyacá",
        "Caldas",
        "Caquetá",
        "Casanare",
        "Cauca",
        "Cesar",
        "Chocó",
        "Córdoba",
        "Cundinamarca",
        "Guainía",
        "Guaviare",
        "Huila",
        "La Guajira",
        "Magdalena",
        "Meta",
        "Nariño",
        "Norte de Santander",
        "Putumayo",
        "Quindío",
        "Risaralda",
        "Santander",
        "Sucre",
        "Tolima",
        "Valle del Cauca",
        "Vaupés",
        "Vichada"
    ],
    Comoros: [
        "Andjazîdja",
        "Andjouân",
        "Moûhîlî"
    ],
    "Congo, Republic of the (Brazzaville)": [
        "Bouenza",
        "Brazzaville",
        "Cuvette",
        "Cuvette-Ouest",
        "Kouilou",
        "Lékoumou",
        "Likouala",
        "Niari",
        "Plateaux",
        "Pointe-Noire",
        "Pool",
        "Sangha"
    ],
    "Congo, the Democratic Republic of the (Kinshasa)": [
        "Bandundu",
        "Bas-Congo",
        "Équateur",
        "Kasaï-Occidental",
        "Kasaï-Oriental",
        "Katanga",
        "Kinshasa",
        "Maniema",
        "Nord-Kivu",
        "Orientale",
        "Sud-Kivu"
    ],
    "Cook Islands": [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
    ],
    "Costa Rica": [
        "Alajuela",
        "Cartago",
        "Guanacaste",
        "Heredia",
        "Limón",
        "Puntarenas",
        "San José"
    ],
    "Côte d'Ivoire, Republic of": [
        "Agnéby",
        "Bafing",
        "Bas-Sassandra",
        "Denguélé",
        "Dix-Huit Montagnes",
        "Fromager",
        "Haut-Sassandra",
        "Lacs",
        "Lagunes",
        "Marahoué",
        "Moyen-Cavally",
        "Moyen-Comoé",
        "N'zi-Comoé",
        "Savanes",
        "Sud-Bandama",
        "Sud-Comoé",
        "Vallée du Bandama",
        "Worodougou",
        "Zanzan"
    ],
    Croatia: [
        "Bjelovarsko-Bilogorska Županija",
        "Brodsko-Posavska Županija",
        "Dubrovačko-Neretvanska Županija",
        "Grad Zagreb",
        "Istarska Županija",
        "Karlovačka Županija",
        "Koprivničko-Krizevačka Županija",
        "Krapinsko-Zagorska Županija",
        "Ličko-Senjska Županija",
        "Međimurska Županija",
        "Osječko-Baranjska Županija",
        "Požeško-Slavonska Županija",
        "Primorsko-Goranska Županija",
        "Sisačko-Moslavačka Županija",
        "Splitsko-Dalmatinska Županija",
        "Sibensko-Kninska Županija",
        "Varaždinska Županija",
        "Virovitičko-Podravska Županija",
        "Vukovarsko-Srijemska Županija",
        "Zadarska Županija",
        "Zagrebacka Zupanija"
    ],
    Cuba: [
        "Artemisa",
        "Camagüey",
        "Ciego de Ávila",
        "Cienfuegos",
        "Granma",
        "Guantánamo",
        "Holguín",
        "Isla de la Juventud",
        "La Habana",
        "Las Tunas",
        "Matanzas",
        "Mayabeque",
        "Pinar del Río",
        "Sancti Spíritus",
        "Santiago de Cuba",
        "Villa Clara"
    ],
    Curaçao: [
        "Curaçao"
    ],
    Cyprus: [
        "Ammochostos",
        "Keryneia",
        "Larnaka",
        "Lefkosia",
        "Lemesos",
        "Pafos"
    ],
    "Czech Republic": [
        "Hlavní město Praha",
        "Jihočeský kraj",
        "Jihomoravský kraj",
        "Karlovarský kraj",
        "Královéhradecký kraj",
        "Liberecký kraj",
        "Moravskoslezský kraj",
        "Olomoucký kraj",
        "Pardubický kraj",
        "Plzeňský kraj",
        "Středočeský kraj",
        "Ústecký kraj",
        "Vysočina",
        "Zlínský kraj"
    ],
    Denmark: [
        "Hovedstaden",
        "Kujalleq",
        "Midtjylland",
        "Norderøerne",
        "Nordjylland",
        "Østerø",
        "Qaasuitsup",
        "Qeqqata",
        "Sandø",
        "Sermersooq",
        "Sjælland",
        "Strømø",
        "Suderø",
        "Syddanmark",
        "Vågø"
    ],
    Djibouti: [
        "Ali Sabieh",
        "Arta",
        "Dikhil",
        "Obock",
        "Tadjourah"
    ],
    Dominica: [
        "Saint Andrew Parish",
        "Saint David Parish",
        "Saint George Parish",
        "Saint John Parish",
        "Saint Joseph Parish",
        "Saint Luke Parish",
        "Saint Mark Parish",
        "Saint Patrick Parish",
        "Saint Paul Parish",
        "Saint Peter Parish"
    ],
    "Dominican Republic": [
        "Cibao Central",
        "Del Valle",
        "Distrito Nacional",
        "Enriquillo",
        "Norcentral",
        "Nordeste",
        "Noroeste",
        "Norte",
        "Valdesia"
    ],
    Ecuador: [
        "Azuay",
        "Bolívar",
        "Cañar",
        "Carchi",
        "Chimborazo",
        "Cotopaxi",
        "El Oro",
        "Esmeraldas",
        "Galápagos",
        "Guayas",
        "Imbabura",
        "Loja",
        "Los Ríos",
        "Manabí",
        "Morona-Santiago",
        "Napo",
        "Orellana",
        "Pastaza",
        "Pichincha",
        "Santa Elena",
        "Santo Domingo de los Tsáchilas",
        "Sucumbíos",
        "Tungurahua",
        "Zamora-Chinchipe"
    ],
    Egypt: [
        "Alexandria",
        "Aswan",
        "Asyout",
        "Bani Sueif",
        "Beheira",
        "Cairo",
        "Daqahlia",
        "Dumiat",
        "El Bahr El Ahmar",
        "El Ismailia",
        "El Suez",
        "El Wadi El Gedeed",
        "Fayoum",
        "Gharbia",
        "Giza",
        "Helwan",
        "Kafr El Sheikh",
        "Luxor",
        "Matrouh",
        "Menia",
        "Menofia",
        "North Sinai",
        "Port Said",
        "Qalubia",
        "Qena",
        "Sharqia",
        "Sixth of October",
        "Sohag",
        "South Sinai"
    ],
    "El Salvador": [
        "Ahuachapán",
        "Cabañas",
        "Cuscatlán",
        "Chalatenango",
        "La Libertad",
        "La Paz",
        "La Unión",
        "Morazán",
        "San Miguel",
        "San Salvador",
        "Santa Ana",
        "San Vicente",
        "Sonsonate",
        "Usulután"
    ],
    "Equatorial Guinea": [
        "Annobón",
        "Bioko Norte",
        "Bioko Sur",
        "Centro Sur",
        "Kié-Ntem",
        "Litoral",
        "Wele-Nzas"
    ],
    Eritrea: [
        "Anseba",
        "Debub",
        "Debub-Keih-Bahri",
        "Gash-Barka",
        "Maekel",
        "Semien-Keih-Bahri"
    ],
    Estonia: [
        "Harjumaa (Tallinn)",
        "Hiiumaa (Kardla)",
        "Ida-Virumaa (Johvi)",
        "Järvamaa (Paide)",
        "Jõgevamaa (Jogeva)",
        "Läänemaa",
        "Lääne-Virumaa (Rakvere)",
        "Pärnumaa (Parnu)",
        "Põlvamaa (Polva)",
        "Raplamaa (Rapla)",
        "Saaremaa (Kuessaare)",
        "Tartumaa (Tartu)",
        "Valgamaa (Valga)",
        "Viljandimaa (Viljandi)",
        "Võrumaa (Voru)"
    ],
    Ethiopia: [
        "Addis Ababa",
        "Afar",
        "Amhara",
        "Benshangul-Gumaz",
        "Dire Dawa",
        "Gambela",
        "Harari",
        "Oromia",
        "Somali",
        "Southern Nations Nationalities and People's Region",
        "Tigray"
    ],
    "Falkland Islands (Islas Malvinas)": [
        ""
    ],
    "Faroe Islands": [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
    ],
    Fiji: [
        "Ba",
        "Bua",
        "Cakaudrove",
        "Kadavu",
        "Lau",
        "Lomaiviti",
        "Macuata",
        "Nadroga and Navosa",
        "Naitasiri",
        "Namosi",
        "Ra",
        "Rewa",
        "Rotuma",
        "Serua",
        "Tailevu"
    ],
    Finland: [
        "Ahvenanmaan lääni",
        "Etelä-Suomen lääni",
        "Itä-Suomen lääni",
        "Länsi-Suomen lääni",
        "Lapin lääni",
        "Oulun lääni"
    ],
    France: [
        "Auvergne-Rhône-Alpes",
        "Bourgogne-Franche-Comté",
        "Bretagne",
        "Centre-Val de Loire",
        "Corse",
        "Grand Est",
        "Hauts-de-France",
        "Île-de-France",
        "Normandie",
        "Nouvelle-Aquitaine",
        "Occitanie",
        "Pays de la Loire",
        "Provence-Alpes-Cote d'Azur",
        "Clipperton",
        "Guadeloupe",
        "Guyane",
        "Martinique",
        "Mayotte",
        "Novelle-Calédonie",
        "Polynésie",
        "Saint-Pierre-et-Miquelon",
        "Saint Barthélemy",
        "Saint Martin",
        "Réunion",
        "Terres Australes Françaises",
        "Wallis-et-Futuna"
    ],
    "French Guiana": [
        ""
    ],
    "French Polynesia": [
        "",
        "",
        "",
        "",
        ""
    ],
    "French Southern and Antarctic Lands": [
        "",
        "",
        "",
        ""
    ],
    Gabon: [
        "Estuaire",
        "Haut-Ogooué",
        "Moyen-Ogooué",
        "Ngounié",
        "Nyanga",
        "Ogooué-Ivindo",
        "Ogooué-Lolo",
        "Ogooué-Maritime",
        "Woleu-Ntem"
    ],
    "Gambia, The": [
        "Banjul",
        "Central River",
        "Lower River",
        "North Bank",
        "Upper River",
        "Western"
    ],
    Georgia: [
        "Abkhazia (Sokhumi)",
        "Ajaria (Bat'umi)",
        "Guria",
        "Imereti",
        "K'akheti",
        "Kvemo Kartli",
        "Mtshkheta-Mtianeti",
        "Rach'a-Lexhkumi-KvemoSvaneti",
        "Samegrelo-Zemo Svaneti",
        "Samtskhe-Javakheti",
        "Shida Kartli",
        "Tbilisi"
    ],
    Germany: [
        "Baden-Württemberg",
        "Bayern",
        "Berlin",
        "Brandenburg",
        "Bremen",
        "Hamburg",
        "Hessen",
        "Mecklenburg-Vorpommern",
        "Niedersachsen",
        "Nordrhein-Westfalen",
        "Rheinland-Pfalz",
        "Saarland",
        "Sachsen",
        "Sachsen-Anhalt",
        "Schleswig-Holstein",
        "Thüringen"
    ],
    Ghana: [
        "Ashanti",
        "Brong-Ahafo",
        "Central",
        "Eastern",
        "Greater Accra",
        "Northern",
        "Upper East",
        "Upper West",
        "Volta",
        "Western"
    ],
    Gibraltar: [
        ""
    ],
    Greece: [
        "Anatolikí Makedonía kai Thráki",
        "Attikḯ",
        "Dytikí Elláda",
        "Dytikí Makedonía",
        "Ionía Nísia",
        "Kentrikí Makedonía",
        "Krítí",
        "Notío Aigaío",
        "Peloponnísos",
        "Stereá Elláda",
        "Thessalía",
        "Voreío Aigaío",
        "Ípeiros",
        "Ágion Óros"
    ],
    Greenland: [
        "Kommune Kujalleq",
        "Kommuneqarfik Sermersooq",
        "Qaasuitsup Kommunia",
        "Qeqqata Kommunia"
    ],
    Grenada: [
        "Saint Andrew",
        "Saint David",
        "Saint George",
        "Saint John",
        "Saint Mark",
        "Saint Patrick",
        "Southern Grenadine Islands"
    ],
    Guadeloupe: [
        ""
    ],
    Guam: [
        ""
    ],
    Guatemala: [
        "Alta Verapaz",
        "Baja Verapaz",
        "Chimaltenango",
        "Chiquimula",
        "El Progreso",
        "Escuintla",
        "Guatemala",
        "Huehuetenango",
        "Izabal",
        "Jalapa",
        "Jutiapa",
        "Petén",
        "Quetzaltenango",
        "Quiché",
        "Retalhuleu",
        "Sacatepéquez",
        "San Marcos",
        "Santa Rosa",
        "Sololá",
        "Suchitepéquez",
        "Totonicapán",
        "Zacapa"
    ],
    Guernsey: [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
    ],
    Guinea: [
        "Boké",
        "Conakry",
        "Faranah",
        "Kankan",
        "Kindia",
        "Labé",
        "Mamou",
        "Nzérékoré"
    ],
    "Guinea-Bissau": [
        "Bafatá",
        "Biombo",
        "Bissau",
        "Bolama-Bijagos",
        "Cacheu",
        "Gabú",
        "Oio",
        "Quinara",
        "Tombali"
    ],
    Guyana: [
        "Barima-Waini",
        "Cuyuni-Mazaruni",
        "Demerara-Mahaica",
        "East Berbice-Corentyne",
        "Essequibo Islands-West Demerara",
        "Mahaica-Berbice",
        "Pomeroon-Supenaam",
        "Potaro-Siparuni",
        "Upper Demerara-Berbice",
        "Upper Takutu-Upper Essequibo"
    ],
    Haiti: [
        "Artibonite",
        "Centre",
        "Grand'Anse",
        "Nippes",
        "Nord",
        "Nord-Est",
        "Nord-Ouest",
        "Ouest",
        "Sud",
        "Sud-Est"
    ],
    "Heard Island and McDonald Islands": [
        ""
    ],
    "Holy See (Vatican City)": [
        "Holy See (Vatican City)"
    ],
    Honduras: [
        "Atlántida",
        "Choluteca",
        "Colón",
        "Comayagua",
        "Copán",
        "Cortés",
        "El Paraíso",
        "Francisco Morazan",
        "Gracias a Dios",
        "Intibucá",
        "Islas de la Bahía",
        "La Paz",
        "Lempira",
        "Ocotepeque",
        "Olancho",
        "Santa Bárbara",
        "Valle",
        "Yoro"
    ],
    "Hong Kong": [
        ""
    ],
    Hungary: [
        "Bács-Kiskun",
        "Baranya",
        "Békés",
        "Békéscsaba",
        "Borsod-Abauj-Zemplen",
        "Budapest",
        "Csongrád",
        "Debrecen",
        "Dunaújváros",
        "Eger",
        "Érd",
        "Fejér",
        "Győr",
        "Győr-Moson-Sopron",
        "Hajdú-Bihar",
        "Heves",
        "Hódmezővásárhely",
        "Jász-Nagykun-Szolnok",
        "Kaposvár",
        "Kecskemét",
        "Komárom-Esztergom",
        "Miskolc",
        "Nagykanizsa",
        "Nógrád",
        "Nyíregyháza",
        "Pécs",
        "Pest",
        "Salgótarján",
        "Somogy",
        "Sopron",
        "Szabolcs-á-Bereg",
        "Szeged",
        "Székesfehérvár",
        "Szekszárd",
        "Szolnok",
        "Szombathely",
        "Tatabánya",
        "Tolna",
        "Vas",
        "Veszprém",
        "Veszprém (City)",
        "Zala",
        "Zalaegerszeg"
    ],
    Iceland: [
        "Austurland",
        "Höfuðborgarsvæði utan Reykjavíkur",
        "Norðurland eystra",
        "Norðurland vestra",
        "Suðurland",
        "Suðurnes",
        "Vestfirðir",
        "Vesturland"
    ],
    India: [
        "Andaman and Nicobar Islands",
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chandigarh",
        "Chhattisgarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Lakshadweep",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Puducherry",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttarakhand",
        "Uttar Pradesh",
        "West Bengal"
    ],
    Indonesia: [
        "Aceh",
        "Bali",
        "Bangka Belitung",
        "Banten",
        "Bengkulu",
        "Gorontalo",
        "Jakarta Raya",
        "Jambi",
        "Jawa Barat",
        "Jawa Tengah",
        "Jawa Timur",
        "Kalimantan Barat",
        "Kalimantan Selatan",
        "Kalimantan Tengah",
        "Kalimantan Timur",
        "Kalimantan Utara",
        "Kepulauan Riau",
        "Lampung",
        "Maluku",
        "Maluku Utara",
        "Nusa Tenggara Barat",
        "Nusa Tenggara Timur",
        "Papua",
        "Papua Barat",
        "Riau",
        "Sulawesi Selatan",
        "Sulawesi Tengah",
        "Sulawesi Tenggara",
        "Sulawesi Utara",
        "Sumatera Barat",
        "Sumatera Selatan",
        "Sumatera Utara",
        "Yogyakarta"
    ],
    "Iran, Islamic Republic of": [
        "Alborz",
        "Ardabīl",
        "Āz̄arbāyjān-e Gharbī",
        "Āz̄arbāyjān-e Sharqī",
        "Būshehr",
        "Chahār Maḩāl va Bakhtīārī",
        "Eşfahān",
        "Fārs",
        "Gīlān",
        "Golestān",
        "Hamadān",
        "Hormozgān",
        "Īlām",
        "Kermān",
        "Kermānshāh",
        "Khorāsān-e Jonūbī",
        "Khorāsān-e Raẕavī",
        "Khorāsān-e Shomālī",
        "Khūzestān",
        "Kohgīlūyeh va Bowyer Aḩmad",
        "Kordestān",
        "Lorestān",
        "Markazi",
        "Māzandarān",
        "Qazvīn",
        "Qom",
        "Semnān",
        "Sīstān va Balūchestān",
        "Tehrān",
        "Yazd",
        "Zanjān"
    ],
    Iraq: [
        "Al Anbār",
        "Al Başrah",
        "Al Muthanná",
        "Al Qādisīyah",
        "An Najaf",
        "Arbīl",
        "As Sulaymānīyah",
        "Bābil",
        "Baghdād",
        "Dohuk",
        "Dhī Qār",
        "Diyālá",
        "Karbalā'",
        "Kirkuk",
        "Maysān",
        "Nīnawá",
        "Şalāḩ ad Dīn",
        "Wāsiţ"
    ],
    Ireland: [
        "Carlow",
        "Cavan",
        "Clare",
        "Cork",
        "Donegal",
        "Dublin",
        "Galway",
        "Kerry",
        "Kildare",
        "Kilkenny",
        "Laois",
        "Leitrim",
        "Limerick",
        "Longford",
        "Louth",
        "Mayo",
        "Meath",
        "Monaghan",
        "Offaly",
        "Roscommon",
        "Sligo",
        "Tipperary",
        "Waterford",
        "Westmeath",
        "Wexford",
        "Wicklow"
    ],
    "Isle of Man": [
        ""
    ],
    Israel: [
        "HaDarom",
        "HaMerkaz",
        "HaTsafon",
        "H̱efa",
        "Tel-Aviv",
        "Yerushalayim"
    ],
    Italy: [
        "Abruzzo",
        "Basilicata",
        "Calabria",
        "Campania",
        "Emilia-Romagna",
        "Friuli-Venezia Giulia",
        "Lazio",
        "Liguria",
        "Lombardia",
        "Marche",
        "Molise",
        "Piemonte",
        "Puglia",
        "Sardegna",
        "Sicilia",
        "Toscana",
        "Trentino-Alto Adige",
        "Umbria",
        "Valle d'Aosta",
        "Veneto"
    ],
    Jamaica: [
        "Clarendon",
        "Hanover",
        "Kingston",
        "Manchester",
        "Portland",
        "Saint Andrew",
        "Saint Ann",
        "Saint Catherine",
        "Saint Elizabeth",
        "Saint James",
        "Saint Mary",
        "Saint Thomas",
        "Trelawny",
        "Westmoreland"
    ],
    Japan: [
        "Aichi",
        "Akita",
        "Aomori",
        "Chiba",
        "Ehime",
        "Fukui",
        "Fukuoka",
        "Fukushima",
        "Gifu",
        "Gunma",
        "Hiroshima",
        "Hokkaido",
        "Hyogo",
        "Ibaraki",
        "Ishikawa",
        "Iwate",
        "Kagawa",
        "Kagoshima",
        "Kanagawa",
        "Kochi",
        "Kumamoto",
        "Kyoto",
        "Mie",
        "Miyagi",
        "Miyazaki",
        "Nagano",
        "Nagasaki",
        "Nara",
        "Niigata",
        "Oita",
        "Okayama",
        "Okinawa",
        "Osaka",
        "Saga",
        "Saitama",
        "Shiga",
        "Shimane",
        "Shizuoka",
        "Tochigi",
        "Tokushima",
        "Tokyo",
        "Tottori",
        "Toyama",
        "Wakayama",
        "Yamagata",
        "Yamaguchi",
        "Yamanashi"
    ],
    Jersey: [
        ""
    ],
    Jordan: [
        "‘Ajlūn",
        "Al 'Aqabah",
        "Al Balqā’",
        "Al Karak",
        "Al Mafraq",
        "Al ‘A̅şimah",
        "Aţ Ţafīlah",
        "Az Zarqā’",
        "Irbid",
        "Jarash",
        "Ma‘ān",
        "Mādabā"
    ],
    Kazakhstan: [
        "Almaty",
        "Aqmola",
        "Aqtobe",
        "Astana",
        "Atyrau",
        "Batys Qazaqstan",
        "",
        "Mangghystau",
        "Ongtustik Qazaqstan",
        "Pavlodar",
        "Qaraghandy",
        "Qostanay",
        "Qyzylorda",
        "Shyghys Qazaqstan",
        "Soltustik Qazaqstan",
        "Zhambyl"
    ],
    Kenya: [
        "Baringo",
        "Bomet",
        "Bungoma",
        "Busia",
        "Eleyo/Marakwet",
        "Embu",
        "Garissa",
        "Homa Bay",
        "Isiolo",
        "Kajiado",
        "Kakamega",
        "Kericho",
        "Kiambu",
        "Kilifi",
        "Kirinyaga",
        "Kisii",
        "Kisumu",
        "Kitui",
        "Kwale",
        "Laikipia",
        "Lamu",
        "Machakos",
        "Makueni",
        "Mandera",
        "Marsabit",
        "Meru",
        "Migori",
        "Mombasa",
        "Murang'a",
        "Nairobi City",
        "Nakuru",
        "Nandi",
        "Narok",
        "Nyamira",
        "Nyandarua",
        "Nyeri",
        "Samburu",
        "Siaya",
        "Taita/Taveta",
        "Tana River",
        "Tharaka-Nithi",
        "Trans Nzoia",
        "Turkana",
        "Uasin Gishu",
        "Vihiga",
        "Wajir",
        "West Pokot"
    ],
    Kiribati: [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Gilbert Islands",
        "",
        "",
        "",
        "Line Islands",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Phoenix Islands",
        "",
        "",
        "",
        "",
        "",
        ""
    ],
    "Korea, Democratic People's Republic of": [
        "Chagang-do (Chagang Province)",
        "Hamgyong-bukto (North Hamgyong Province)",
        "Hamgyong-namdo (South Hamgyong Province)",
        "Hwanghae-bukto (North Hwanghae Province)",
        "Hwanghae-namdo (South Hwanghae Province)",
        "Kangwon-do (Kangwon Province)",
        "Nasŏn (Najin-Sŏnbong)",
        "P'yongan-bukto (North P'yongan Province)",
        "P'yongan-namdo (South P'yongan Province)",
        "P'yongyang-si (P'yongyang City)",
        "Yanggang-do (Yanggang Province)"
    ],
    "Korea, Republic of": [
        "Ch'ungch'ongbuk-do",
        "Ch'ungch'ongnam-do",
        "Cheju-do",
        "Chollabuk-do",
        "Chollanam-do",
        "Inch'on-Kwangyokhi",
        "Kang-won-do",
        "Kwangju-Kwangyokshi",
        "Kyonggi-do",
        "Kyongsangbuk-do",
        "Kyongsangnam-do",
        "Pusan-Kwangyokshi",
        "Seoul-T'ukpyolshi",
        "Sejong",
        "Taegu-Kwangyokshi",
        "Taejon-Kwangyokshi",
        "Ulsan-Kwangyokshi"
    ],
    Kuwait: [
        "Al Aḩmadi",
        "Al Farwānīyah",
        "Al Jahrā’",
        "Al ‘Āşimah",
        "Ḩawallī",
        "Mubārak al Kabir"
    ],
    Kyrgyzstan: [
        "Batken Oblasty",
        "Bishkek Shaary",
        "Chuy Oblasty (Bishkek)",
        "Jalal-Abad Oblasty",
        "Naryn Oblasty",
        "Osh Oblasty",
        "Talas Oblasty",
        "Ysyk-Kol Oblasty (Karakol)"
    ],
    Laos: [
        "Attapu",
        "Bokèo",
        "Bolikhamxai",
        "Champasak",
        "Houaphan",
        "Khammouan",
        "Louang Namtha",
        "Louangphabang",
        "Oudômxai",
        "Phôngsali",
        "Salavan",
        "Savannakhét",
        "Vientiane",
        "Xaignabouli",
        "Xékong",
        "Xaisomboun",
        "Xiangkhouang"
    ],
    Latvia: [
        "Aglona",
        "Aizkraukle",
        "Aizpute",
        "Aknīste",
        "Aloja",
        "Alsunga",
        "Alūksne",
        "Amata",
        "Ape",
        "Auce",
        "Ādaži",
        "Babīte",
        "Baldone",
        "Baltinava",
        "Balvi",
        "Bauska",
        "Beverīna",
        "Brocēni",
        "Burtnieki",
        "Carnikava",
        "Cesvaine",
        "Cēsis",
        "Cibla",
        "Dagda",
        "Daugavpils",
        "Daugavpils (City)",
        "Dobele",
        "Dundaga",
        "Durbe",
        "Engure",
        "Ērgļi",
        "Garkalne",
        "Grobiņa",
        "Gulbene",
        "Iecava",
        "Ikšķile",
        "Ilūkste",
        "Inčukalns",
        "Jaunjelgava",
        "Jaunpiebalga",
        "Jaunpils",
        "Jelgava",
        "Jelgava (City)",
        "Jēkabpils",
        "Jēkabpils (City)",
        "Jūrmala (City)",
        "Kandava",
        "Kārsava",
        "Kocēni",
        "Koknese",
        "Krāslava",
        "Krimulda",
        "Krustpils",
        "Kuldīga",
        "Ķegums",
        "Ķekava",
        "Lielvārde",
        "Liepāja",
        "Limbaži",
        "Līgatne",
        "Līvāni",
        "Lubāna",
        "Ludza",
        "Madona",
        "Mazsalaca",
        "Mālpils",
        "Mārupe",
        "Mērsrags",
        "Naukšēni",
        "Nereta",
        "Nīca",
        "Ogre",
        "Olaine",
        "Ozolnieki",
        "Pārgauja",
        "Pāvilosta",
        "Pļaviņas",
        "Preiļi",
        "Priekule",
        "Priekuļi",
        "Rauna",
        "Rēzekne",
        "Rēzekne (City)",
        "Riebiņi",
        "Rīga",
        "Roja",
        "Ropaži",
        "Rucava",
        "Rugāji",
        "Rundāle",
        "Rūjiena",
        "Sala",
        "Salacgrīva",
        "Salaspils",
        "Saldus",
        "Saulkrasti",
        "Sēja",
        "Sigulda",
        "Skrīveri",
        "Skrunda",
        "Smiltene",
        "Stopiņi",
        "Strenči",
        "Talsi",
        "Tērvete",
        "Tukums",
        "Vaiņode",
        "Valka",
        "Valmiera",
        "Varakļāni",
        "Vārkava",
        "Vecpiebalga",
        "Vecumnieki",
        "Ventspils",
        "Ventspils (City)",
        "Viesīte",
        "Viļaka",
        "Viļāni",
        "Zilupe"
    ],
    Lebanon: [
        "Aakkâr",
        "Baalbelk-Hermel",
        "Béqaa",
        "Beyrouth",
        "Liban-Nord",
        "Liban-Sud",
        "Mont-Liban",
        "Nabatîyé"
    ],
    Lesotho: [
        "Berea",
        "Butha-Buthe",
        "Leribe",
        "Mafeteng",
        "Maseru",
        "Mohales Hoek",
        "Mokhotlong",
        "Qacha's Nek",
        "Quthing",
        "Thaba-Tseka"
    ],
    Liberia: [
        "Bomi",
        "Bong",
        "Gbarpolu",
        "Grand Bassa",
        "Grand Cape Mount",
        "Grand Gedeh",
        "Grand Kru",
        "Lofa",
        "Margibi",
        "Maryland",
        "Montserrado",
        "Nimba",
        "River Cess",
        "River Geee",
        "Sinoe"
    ],
    Libya: [
        "Al Buţnān",
        "Al Jabal al Akhḑar",
        "Al Jabal al Gharbī",
        "Al Jafārah",
        "Al Jufrah",
        "Al Kufrah",
        "Al Marj",
        "Al Marquab",
        "Al Wāḩāt",
        "An Nuqaţ al Khams",
        "Az Zāwiyah",
        "Banghāzī",
        "Darnah",
        "Ghāt",
        "Mişrātah",
        "Murzuq",
        "Nālūt",
        "Sabhā",
        "Surt",
        "Ţarābulus",
        "Yafran",
        "Wādī ash Shāţiʾ"
    ],
    Liechtenstein: [
        "Balzers",
        "Eschen",
        "Gamprin",
        "Mauren",
        "Planken",
        "Ruggell",
        "Schaan",
        "Schellenberg",
        "Triesen",
        "Triesenberg",
        "Vaduz"
    ],
    Lithuania: [
        "Alytaus",
        "Kauno",
        "Klaipėdos",
        "Marijampolės",
        "Panevėžio",
        "Šiaulių",
        "Tauragės",
        "Telšių",
        "Utenos",
        "Vilniaus"
    ],
    Luxembourg: [
        "Capellen",
        "Clevaux",
        "Diekirch",
        "Echternach",
        "Esch-sur-Alzette",
        "Grevenmacher",
        "Luxembourg",
        "Mersch",
        "Redange",
        "Remich",
        "Vianden",
        "Wiltz"
    ],
    Macao: [
        ""
    ],
    "Macedonia, Republic of": [
        "Aračinovo",
        "Berovo",
        "Bitola",
        "Bogdanci",
        "Bogovinje",
        "Bosilovo",
        "Brvenica",
        "Centar Župa",
        "Čaška",
        "Češinovo-Obleševo",
        "Čučer Sandevo",
        "Debar",
        "Debarca",
        "Delčevo",
        "Demir Hisar",
        "Demir Kapija",
        "Doran",
        "Dolneni",
        "Gevgelija",
        "Gostivar",
        "Gradsko",
        "Ilinden",
        "Jegunovce",
        "Karbinci",
        "Kavadarci",
        "Kičevo",
        "Kočani",
        "Konče",
        "Kratovo",
        "Kriva Palanka",
        "Krivogaštani",
        "Kruševo",
        "Kumanovo",
        "Lipkovo",
        "Lozovo",
        "Makedonska Kamenica",
        "Makedonski Brod",
        "Mavrovo i Rostuša",
        "Mogila",
        "Negotino",
        "Novaci",
        "Novo Selo",
        "Ohrid",
        "Pehčevo",
        "Petrovec",
        "Plasnica",
        "Prilep",
        "Probištip",
        "Radoviš",
        "Rankovce",
        "Resen",
        "Rosoman",
        "Skopje",
        "Sopište",
        "Staro Nagoričane",
        "Struga",
        "Strumica",
        "Studeničani",
        "Sveti Nikole",
        "Štip",
        "Tearce",
        "Tetovo",
        "Valandovo",
        "Vasilevo",
        "Veles",
        "Vevčani",
        "Vinica",
        "Vrapčište",
        "Zelenikovo",
        "Zrnovci",
        "Želino"
    ],
    Madagascar: [
        "Antananarivo",
        "Antsiranana",
        "Fianarantsoa",
        "Mahajanga",
        "Toamasina",
        "Toliara"
    ],
    Malawi: [
        "Balaka",
        "Blantyre",
        "Chikwawa",
        "Chiradzulu",
        "Chitipa",
        "Dedza",
        "Dowa",
        "Karonga",
        "Kasungu",
        "Likoma",
        "Lilongwe",
        "Machinga",
        "Mangochi",
        "Mchinji",
        "Mulanje",
        "Mwanza",
        "Mzimba",
        "Nkhata Bay",
        "Nkhotakota",
        "Nsanje",
        "Ntcheu",
        "Ntchisi",
        "Phalombe",
        "Rumphi",
        "Salima",
        "Thyolo",
        "Zomba"
    ],
    Malaysia: [
        "Johor",
        "Kedah",
        "Kelantan",
        "Melaka",
        "Negeri Sembilan",
        "Pahang",
        "Perak",
        "Perlis",
        "Pulau Pinang",
        "Sabah",
        "Sarawak",
        "Selangor",
        "Terengganu",
        "Wilayah Persekutuan (Kuala Lumpur)",
        "Wilayah Persekutuan (Labuan)",
        "Wilayah Persekutuan (Putrajaya)"
    ],
    Maldives: [
        "Alifu Alifu",
        "Alifu Dhaalu",
        "Baa",
        "Dhaalu",
        "Faafu",
        "Gaafu Alifu",
        "Gaafu Dhaalu",
        "Gnaviyani",
        "Haa Alifu",
        "Haa Dhaalu",
        "Kaafu",
        "Laamu",
        "Lhaviyani",
        "Malé",
        "Meemu",
        "Noonu",
        "Raa",
        "Seenu",
        "Shaviyani",
        "Thaa",
        "Vaavu"
    ],
    Mali: [
        "Bamako",
        "Gao",
        "Kayes",
        "Kidal",
        "Koulikoro",
        "Mopti",
        "Segou",
        "Sikasso",
        "Tombouctou"
    ],
    Malta: [
        "Attard",
        "Balzan",
        "Birgu",
        "Birkirkara",
        "Birżebbuġa",
        "Bormla",
        "Dingli",
        "Fgura",
        "Floriana",
        "Fontana",
        "Guda",
        "Gżira",
        "Għajnsielem",
        "Għarb",
        "Għargħur",
        "Għasri",
        "Għaxaq",
        "Ħamrun",
        "Iklin",
        "Isla",
        "Kalkara",
        "Kerċem",
        "Kirkop",
        "Lija",
        "Luqa",
        "Marsa",
        "Marsaskala",
        "Marsaxlokk",
        "Mdina",
        "Mellieħa",
        "Mġarr",
        "Mosta",
        "Mqabba",
        "Msida",
        "Mtarfa",
        "Munxar",
        "Nadur",
        "Naxxar",
        "Paola",
        "Pembroke",
        "Pietà",
        "Qala",
        "Qormi",
        "Qrendi",
        "Rabat Għawdex",
        "Rabat Malta",
        "Safi",
        "San Ġiljan",
        "San Ġwann",
        "San Lawrenz",
        "San Pawl il-Baħar",
        "Sannat",
        "Santa Luċija",
        "Santa Venera",
        "Siġġiewi",
        "Sliema",
        "Swieqi",
        "Tai Xbiex",
        "Tarzien",
        "Valletta",
        "Xagħra",
        "Xewkija",
        "Xgħajra",
        "Żabbar",
        "Żebbuġ Għawde",
        "Żebbuġ Malta",
        "Żejtun",
        "Żurrieq"
    ],
    "Marshall Islands": [
        "Ailinglaplap",
        "Ailuk",
        "Arno",
        "Aur",
        "Bikini and Kili",
        "Ebon",
        "Jabat",
        "Jaluit",
        "Kwajalein",
        "Lae",
        "Lib",
        "Likiep",
        "Majuro",
        "Maloelap",
        "Mejit",
        "Namdrik",
        "Namu",
        "Rongelap",
        "Ujae",
        "Utrik",
        "Wotho",
        "Wotje"
    ],
    Martinique: [
        ""
    ],
    Mauritania: [
        "Adrar",
        "Assaba",
        "Brakna",
        "Dakhlet Nouadhibou",
        "Gorgol",
        "Guidimaka",
        "Hodh Ech Chargui",
        "Hodh El Gharbi",
        "Inchiri",
        "Nouakchott Nord",
        "Nouakchott Ouest",
        "Nouakchott Sud",
        "Tagant",
        "Tiris Zemmour",
        "Trarza"
    ],
    Mauritius: [
        "Agalega Islands",
        "Beau Bassin-Rose Hill",
        "Black River",
        "Cargados Carajos Shoals",
        "Curepipe",
        "Flacq",
        "Grand Port",
        "Moka",
        "Pamplemousses",
        "Plaines Wilhems",
        "Port Louis (City)",
        "Port Louis",
        "Riviere du Rempart",
        "Rodrigues Island",
        "Savanne",
        "Vacoas-Phoenix"
    ],
    Mayotte: [ "Dzaoudzi",
        "Pamandzi",
        "Mamoudzou",
        "Dembeni",
        "Bandrélé",
        "Kani-Kéli",
        "Bouéni",
        "Chirongui",
        "Sada",
        "Ouangani",
        "Chiconi",
        "Tsingoni",
        "M'Tsangamouji",
        "Acoua",
        "Mtsamboro",
        "Bandraboua",
        "Koungou"
    ],
    Mexico: [
        "Aguascalientes",
        "Baja California",
        "Baja California Sur",
        "Campeche",
        "Ciudad de México",
        "Chiapas",
        "Chihuahua",
        "Coahuila de Zaragoza",
        "Colima",
        "Durango",
        "Estado de México",
        "Guanajuato",
        "Guerrero",
        "Hidalgo",
        "Jalisco",
        "Michoacán de Ocampo",
        "Morelos",
        "Nayarit",
        "Nuevo León",
        "Oaxaca",
        "Puebla",
        "Querétaro de Arteaga",
        "Quintana Roo",
        "San Luis Potosí",
        "Sinaloa",
        "Sonora",
        "Tabasco",
        "Tamaulipas",
        "Tlaxcala",
        "Veracruz",
        "Yucatán",
        "Zacatecas"
    ],
    "Micronesia, Federated States of": [
        "Chuuk (Truk)",
        "Kosrae",
        "Pohnpei",
        "Yap"
    ],
    Moldova: [
        "Aenii Noi",
        "Basarabeasca",
        "Bălți",
        "Bender",
        "Briceni",
        "Cahul",
        "Cantemir",
        "Călărași",
        "Căușeni",
        "Chișinău",
        "Cimișlia",
        "Criuleni",
        "Dondușeni",
        "Drochia",
        "Dubăsari",
        "Edineț",
        "Fălești",
        "Florești",
        "Găgăuzia",
        "Glodeni",
        "Hîncești",
        "Ialoveni",
        "Leova",
        "Nisporeni",
        "Ocnița",
        "Orhei",
        "Rezina",
        "Rîșcani",
        "Sîngerei",
        "Soroca",
        "Stînga Nistrului",
        "Strășeni",
        "Șoldănești",
        "Ștefan Vodă",
        "Taraclia",
        "Telenești",
        "Ungheni"
    ],
    Monaco: [
        "Colle",
        "Condamine",
        "Fontvieille",
        "Gare",
        "Jardin Exotique",
        "Larvotto",
        "Malbousquet",
        "Monaco-Ville",
        "Moneghetti",
        "Monte-Carlo",
        "Moulins",
        "Port-Hercule",
        "Saint-Roman",
        "Sainte-Dévote",
        "Source",
        "Spélugues",
        "Vallon de la Rousse"
    ],
    Mongolia: [
        "Arhangay",
        "Bayan-Olgiy",
        "Bayanhongor",
        "Bulgan",
        "Darhan",
        "Dornod",
        "Dornogovi",
        "Dundgovi",
        "Dzavhan",
        "Govi-Altay",
        "Govi-Sumber",
        "Hovd",
        "Hovsgol",
        "Omnogovi",
        "Ovorhangay",
        "Selenge",
        "Suhbaatar",
        "Tov",
        "Ulaanbaatar",
        "Uvs"
    ],
    Montenegro: [
        "Andrijevica",
        "Bar",
        "Berane",
        "Bijelo Polje",
        "Budva",
        "Cetinje",
        "Danilovgrad",
        "Gusinje",
        "Herceg Novi",
        "Kolašin",
        "Kotor",
        "Mojkovac",
        "Nikšić",
        "Petnica",
        "Plav",
        "Plužine",
        "Pljevlja",
        "Podgorica",
        "Rožaje",
        "Šavnik",
        "Tivat",
        "Ulcinj",
        "Žabljak"
    ],
    Montserrat: [
        "",
        "",
        ""
    ],
    Morocco: [
        "Chaouia-Ouardigha",
        "Doukhala-Abda",
        "Fès-Boulemane",
        "Gharb-Chrarda-Beni Hssen",
        "Grand Casablanca",
        "Guelmim-Es Semara",
        "Laâyoune-Boujdour-Sakia el Hamra",
        "Marrakech-Tensift-Al Haouz",
        "Meknès-Tafilalet",
        "Oriental",
        "Oued ed Dahab-Lagouira",
        "Souss-Massa-Drâa",
        "Tadla-Azilal",
        "Tanger-Tétouan",
        "Taza-Al Hoceima-Taounate"
    ],
    Mozambique: [
        "Cabo Delgado",
        "Gaza",
        "Inhambane",
        "Manica",
        "Maputo",
        "Maputo (City)",
        "Nampula",
        "Niassa",
        "Sofala",
        "Tete",
        "Zambezia"
    ],
    Myanmar: [
        "Ayeyarwady",
        "Bago",
        "Chin",
        "Kachin",
        "Kayah",
        "Kayin",
        "Magway",
        "Mandalay",
        "Mon",
        "Nay Pyi Taw",
        "Rakhine",
        "Sagaing",
        "Shan",
        "Tanintharyi",
        "Yangon"
    ],
    Namibia: [
        "Erongo",
        "Hardap",
        "Kavango East",
        "Kavango West",
        "Karas",
        "Khomas",
        "Kunene",
        "Ohangwena",
        "Omaheke",
        "Omusati",
        "Oshana",
        "Oshikoto",
        "Otjozondjupa",
        "Zambezi"
    ],
    Nauru: [
        "Aiwo",
        "Anabar",
        "Anetan",
        "Anibare",
        "Baiti",
        "Boe",
        "Buada",
        "Denigomodu",
        "Ewa",
        "Ijuw",
        "Meneng",
        "Nibok",
        "Uaboe",
        "Yaren"
    ],
    Nepal: [
        "Bagmati",
        "Bheri",
        "Dhawalagiri",
        "Gandaki",
        "Janakpur",
        "Karnali",
        "Kosi",
        "Lumbini",
        "Mahakali",
        "Mechi",
        "Narayani",
        "Rapti",
        "Sagarmatha",
        "Seti"
    ],
    Netherlands: [
        "Drenthe",
        "Flevoland",
        "Friesland",
        "Gelderland",
        "Groningen",
        "Limburg",
        "Noord-Brabant",
        "Noord-Holland",
        "Overijssel",
        "Utrecht",
        "Zeeland",
        "Zuid-Holland"
    ],
    "New Caledonia": [
        "",
        "",
        ""
    ],
    "New Zealand": [
        "Auckland",
        "Bay of Plenty",
        "Canterbury",
        "Gisborne",
        "Hawke's Bay",
        "Marlborough",
        "Manawatu-Wanganui",
        "Northland",
        "Nelson",
        "Otago",
        "Southland",
        "Taranaki",
        "Tasman",
        "Waikato",
        "Wellington",
        "West Coast",
        "Chatham Islands Territory"
    ],
    Nicaragua: [
        "Boaco",
        "Carazo",
        "Chinandega",
        "Chontales",
        "Estelí",
        "Granada",
        "Jinotega",
        "León",
        "Madriz",
        "Managua",
        "Masaya",
        "Matagalpa",
        "Nueva Segovia",
        "Río San Juan",
        "Rivas",
        "Atlántico Norte",
        "Atlántico Sur"
    ],
    Niger: [
        "Agadez",
        "Diffa",
        "Dosso",
        "Maradi",
        "Niamey",
        "Tahoua",
        "Tillabéri",
        "Zinder"
    ],
    Nigeria: [
        "Abia",
        "Abuja Federal Capital Territory",
        "Adamawa",
        "Akwa Ibom",
        "Anambra",
        "Bauchi",
        "Bayelsa",
        "Benue",
        "Borno",
        "Cross River",
        "Delta",
        "Ebonyi",
        "Edo",
        "Ekiti",
        "Enugu",
        "Gombe",
        "Imo",
        "Jigawa",
        "Kaduna",
        "Kano",
        "Katsina",
        "Kebbi",
        "Kogi",
        "Kwara",
        "Lagos",
        "Nassarawa",
        "Niger",
        "Ogun",
        "Ondo",
        "Osun",
        "Oyo",
        "Plateau",
        "Rivers",
        "Sokoto",
        "Taraba",
        "Yobe",
        "Zamfara"
    ],
    Niue: [
        ""
    ],
    "Norfolk Island": [
        ""
    ],
    "Northern Mariana Islands": [
        "",
        "",
        "",
        ""
    ],
    Norway: [
        "Akershus",
        "Aust-Agder",
        "Buskerud",
        "Finnmark",
        "Hedmark",
        "Hordaland",
        "Møre og Romsdal",
        "Nordland",
        "Nord-Trøndelag",
        "Oppland",
        "Oslo",
        "Rogaland",
        "Sogn og Fjordane",
        "Sør-Trøndelag",
        "Telemark",
        "Troms",
        "Vest-Agder",
        "Vestfold",
        "Østfold",
        "Jan Mayen",
        "Svalbard"
    ],
    Oman: [
        "Ad Dakhiliyah",
        "Al Buraymi",
        "Al Wusta",
        "Az Zahirah",
        "Janub al Batinah",
        "Janub ash Sharqiyah",
        "Masqat",
        "Musandam",
        "Shamal al Batinah",
        "Shamal ash Sharqiyah",
        "Zufar"
    ],
    Pakistan: [
        "Āzād Kashmīr",
        "Balōchistān",
        "Gilgit-Baltistān",
        "Islāmābād",
        "Khaībar Pakhtūnkhwās",
        "Punjāb",
        "Sindh",
        "Federally Administered Tribal Areas"
    ],
    Palau: [
        "Aimeliik",
        "Airai",
        "Angaur",
        "Hatobohei",
        "Kayangel",
        "Koror",
        "Melekeok",
        "Ngaraard",
        "Ngarchelong",
        "Ngardmau",
        "Ngatpang",
        "Ngchesar",
        "Ngeremlengui",
        "Ngiwal",
        "Peleliu",
        "Sonsoral"
    ],
    "Palestine, State of": [
        "Ak Khalīl",
        "Al Quds",
        "Arīḩā wal Aghwār",
        "Bayt Laḩm",
        "Dayr al Balaḩ",
        "Ghazzah",
        "Janīn",
        "Khān Yūnis",
        "Nāblus",
        "Qalqīyah",
        "Rafaḩ",
        "Rām Allāh wal Bīrah",
        "Salfīt",
        "Shamāl Ghazzah",
        "Ţūbās",
        "Ţūlkarm"
    ],
    Panama: [
        "Bocas del Toro",
        "Chiriquí",
        "Coclé",
        "Colón",
        "Darién",
        "Emberá",
        "Herrera",
        "Kuna Yala",
        "Los Santos",
        "Ngäbe-Buglé",
        "Panamá",
        "Panamá Oeste",
        "Veraguas"
    ],
    "Papua New Guinea": [
        "Bougainville",
        "Central",
        "Chimbu",
        "East New Britain",
        "East Sepik",
        "Eastern Highlands",
        "Enga",
        "Gulf",
        "Hela",
        "Jiwaka",
        "Madang",
        "Manus",
        "Milne Bay",
        "Morobe",
        "Port Moresby",
        "New Ireland",
        "Northern",
        "Southern Highlands",
        "West New Britain",
        "West Sepik",
        "Western",
        "Western Highlands"
    ],
    Paraguay: [
        "Alto Paraguay",
        "Alto Parana",
        "Amambay",
        "Asuncion",
        "Caaguazu",
        "Caazapa",
        "Canindeyu",
        "Central",
        "Concepcion",
        "Cordillera",
        "Guaira",
        "Itapua",
        "Misiones",
        "Neembucu",
        "Paraguari",
        "Presidente Hayes",
        "San Pedro"
    ],
    Peru: [
        "Amazonas",
        "Ancash",
        "Apurimac",
        "Arequipa",
        "Ayacucho",
        "Cajamarca",
        "Callao",
        "Cusco",
        "Huancavelica",
        "Huanuco",
        "Ica",
        "Junin",
        "La Libertad",
        "Lambayeque",
        "Lima",
        "Loreto",
        "Madre de Dios",
        "Moquegua",
        "Municipalidad Metropolitana de Lima",
        "Pasco",
        "Piura",
        "Puno",
        "San Martin",
        "Tacna",
        "Tumbes",
        "Ucayali"
    ],
    Philippines: [
        "Abra",
        "Agusan del Norte",
        "Agusan del Sur",
        "Aklan",
        "Albay",
        "Antique",
        "Apayao",
        "Aurora",
        "Basilan",
        "Bataan",
        "Batanes",
        "Batangas",
        "Benguet",
        "Biliran",
        "Bohol",
        "Bukidnon",
        "Bulacan",
        "Cagayan",
        "Camarines Norte",
        "Camarines Sur",
        "Camiguin",
        "Capiz",
        "Catanduanes",
        "Cavite",
        "Cebu",
        "Compostela",
        "Cotabato",
        "Davao del Norte",
        "Davao del Sur",
        "Davao Occidental",
        "Davao Oriental",
        "Dinagat Islands",
        "Eastern Samar",
        "Guimaras",
        "Ifugao",
        "Ilocos Norte",
        "Ilocos Sur",
        "Iloilo",
        "Isabela",
        "Kalinga",
        "La Union",
        "Laguna",
        "Lanao del Norte",
        "Lanao del Sur",
        "Leyte",
        "Maguindanao",
        "Masbate",
        "Mindoro Occidental",
        "Mindoro Oriental",
        "Misamis Occidental",
        "Misamis Oriental",
        "Mountain Province",
        "Negros Occidental",
        "Negros Oriental",
        "Northern Samar",
        "Nueva Ecija",
        "Nueva Vizcaya",
        "Palawan",
        "Pampanga",
        "Pangasinan",
        "Quezon",
        "Quirino",
        "Rizal",
        "Romblon",
        "Samar",
        "Sarangani",
        "Siquijor",
        "Sorsogon",
        "Southern Leyte",
        "Sultan Kudarat",
        "Sulu",
        "Surigao del Norte",
        "Surigao del Sur",
        "Tarlac",
        "Tawi-Tawi",
        "Zambales",
        "Zamboanga del Norte",
        "Zamboanga del Sur",
        "Zamboanga Sibugay"
    ],
    Pitcairn: [
        ""
    ],
    Poland: [
        "Dolnośląskie",
        "Kujawsko-pomorskie",
        "Łódzkie",
        "Lubelskie",
        "Lubuskie",
        "Malopolskie",
        "Mazowieckie",
        "Opolskie",
        "Podkarpackie",
        "Podlaskie",
        "Pomorskie",
        "Śląskie",
        "Świętokrzyskie",
        "Warmińsko-mazurskie",
        "Wielkopolskie",
        "Zachodniopomorskie"
    ],
    Portugal: [
        "Acores",
        "Aveiro",
        "Beja",
        "Braga",
        "Braganca",
        "Castelo Branco",
        "Coimbra",
        "Evora",
        "Faro",
        "Guarda",
        "Leiria",
        "Lisboa",
        "Madeira",
        "Portalegre",
        "Porto",
        "Santarem",
        "Setubal",
        "Viana do Castelo",
        "Vila Real",
        "Viseu"
    ],
    "Puerto Rico": [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
    ],
    Qatar: [
        "Ad Dawḩah",
        "Al Khawr wa adh Dhakhīrah",
        "Al Wakrah",
        "Ar Rayyān",
        "Ash Shamāl",
        "Az̧ Za̧`āyin",
        "Umm Şalāl"
    ],
    Réunion: [
        ""
    ],
    Romania: [
        "Alba",
        "Arad",
        "Arges",
        "Bacau",
        "Bihor",
        "Bistrita-Nasaud",
        "Botosani",
        "Braila",
        "Brasov",
        "Bucuresti",
        "Buzau",
        "Calarasi",
        "Caras-Severin",
        "Cluj",
        "Constanta",
        "Covasna",
        "Dambovita",
        "Dolj",
        "Galati",
        "Giurgiu",
        "Gorj",
        "Harghita",
        "Hunedoara",
        "Ialomita",
        "Iasi",
        "Maramures",
        "Mehedinti",
        "Mures",
        "Neamt",
        "Olt",
        "Prahova",
        "Salaj",
        "Satu Mare",
        "Sibiu",
        "Suceava",
        "Teleorman",
        "Timis",
        "Tulcea",
        "Valcea",
        "Vaslui",
        "Vrancea"
    ],
    "Russian Federation": [
        "Republic of Adygea",
        "Republic of Altai (Gorno-Altaysk)",
        "Altai Krai",
        "Amur Oblast",
        "Arkhangelsk Oblast",
        "Astrakhan Oblast",
        "Republic of Bashkortostan",
        "Belgorod Oblast",
        "Bryansk Oblast",
        "Republic of Buryatia",
        "Chechen Republic",
        "Chelyabinsk Oblast",
        "Chukotka Autonomous Okrug",
        "Chuvash Republic",
        "Republic of Dagestan",
        "Republic of Ingushetia",
        "Irkutsk Oblast",
        "Ivanovo Oblast",
        "Jewish Autonomous Oblast",
        "Kabardino-Balkar Republic",
        "Kaliningrad Oblast",
        "Republic of Kalmykia",
        "Kaluga Oblast",
        "Kamchatka Krai",
        "Karachay-Cherkess Republic",
        "Republic of Karelia",
        "Khabarovsk Krai",
        "Republic of Khakassia",
        "Khanty-Mansi Autonomous Okrug - Yugra",
        "Kemerovo Oblast",
        "Kirov Oblast",
        "Komi Republic",
        "Kostroma Oblast",
        "Krasnodar Krai",
        "Krasnoyarsk Krai",
        "Kurgan Oblast",
        "Kursk Oblast",
        "Leningrad Oblast",
        "Lipetsk Oblast",
        "Magadan Oblast",
        "Mari El Republic",
        "Republic of Mordovia",
        "Moscow Oblast",
        "Moscow",
        "Murmansk Oblast",
        "Nenets Autonomous Okrug",
        "Nizhny Novgorod Oblast",
        "Novgorod Oblast",
        "Novosibirsk Oblast",
        "Omsk Oblast",
        "Orenburg Oblast",
        "Oryol Oblast",
        "Penza Oblast",
        "Perm Krai",
        "Primorsky Krai",
        "Pskov Oblast",
        "Rostov Oblast",
        "Ryazan Oblast",
        "Saint Petersburg",
        "Sakha (Yakutia) Republic",
        "Sakhalin Oblast",
        "Samara Oblast",
        "Saratov Oblast",
        "Republic of North Ossetia-Alania",
        "Smolensk Oblast",
        "Stavropol Krai",
        "Sverdlovsk Oblast",
        "Tambov Oblast",
        "Republic of Tatarstan",
        "Tomsk Oblast",
        "Tuva Republic",
        "Tula Oblast",
        "Tver Oblast",
        "Tyumen Oblast",
        "Udmurt Republic",
        "Ulyanovsk Oblast",
        "Vladimir Oblast",
        "Volgograd Oblast",
        "Vologda Oblast",
        "Voronezh Oblast",
        "Yamalo-Nenets Autonomous Okrug",
        "Yaroslavl Oblast",
        "Zabaykalsky Krai"
    ],
    Rwanda: [
        "Kigali",
        "Eastern",
        "Northern",
        "Western",
        "Southern"
    ],
    "Saint Barthélemy": [
        "Au Vent",
        "Sous le Vent"
    ],
    "Saint Helena, Ascension and Tristan da Cunha": [
        "Ascension",
        "Saint Helena",
        "Tristan da Cunha"
    ],
    "Saint Kitts and Nevis": [
        "Saint Kitts",
        "Nevis"
    ],
    "Saint Lucia": [
        "Anse-la-Raye",
        "Canaries",
        "Castries",
        "Choiseul",
        "Dennery",
        "Gros Islet",
        "Laborie",
        "Micoud",
        "Soufriere",
        "Vieux Fort"
    ],
    "Saint Martin": [
        ""
    ],
    "Saint Pierre and Miquelon": [
        "",
        ""
    ],
    "Saint Vincent and the Grenadines": [
        "Charlotte",
        "Grenadines",
        "Saint Andrew",
        "Saint David",
        "Saint George",
        "Saint Patrick"
    ],
    Samoa: [
        "A'ana",
        "Aiga-i-le-Tai",
        "Atua",
        "Fa'asaleleaga",
        "Gaga'emauga",
        "Gagaifomauga",
        "Palauli",
        "Satupa'itea",
        "Tuamasaga",
        "Va'a-o-Fonoti",
        "Vaisigano"
    ],
    "San Marino": [
        "Acquaviva",
        "Borgo Maggiore",
        "Chiesanuova",
        "Domagnano",
        "Faetano",
        "Fiorentino",
        "Montegiardino",
        "San Marino",
        "Serravalle"
    ],
    "Sao Tome and Principe": [
        "Principe",
        "Sao Tome"
    ],
    "Saudi Arabia": [
        "'Asir",
        "Al Bahah",
        "Al Hudud ash Shamaliyah",
        "Al Jawf",
        "Al Madinah al Munawwarah",
        "Al Qasim",
        "Ar Riyad",
        "Ash Sharqiyah",
        "Ha'il",
        "Jazan",
        "Makkah al Mukarramah",
        "Najran",
        "Tabuk"
    ],
    Senegal: [
        "Dakar",
        "Diourbel",
        "Fatick",
        "Kaffrine",
        "Kaolack",
        "Kedougou",
        "Kolda",
        "Louga",
        "Matam",
        "Saint-Louis",
        "Sedhiou",
        "Tambacounda",
        "Thies",
        "Ziguinchor"
    ],
    Serbia: [
        "Beograd (Belgrade)",
        "Borski",
        "Braničevski",
        "Jablanički",
        "Južnobački",
        "Južnobanatski",
        "Kolubarski",
        "Kosovski",
        "Kosovsko-Mitrovački",
        "Kosovsko-Pomoravski",
        "Mačvanski",
        "Moravički",
        "Nišavski",
        "Pčinjski",
        "Pećki",
        "Pirotski",
        "Podunavski",
        "Pomoravski",
        "Prizrenski",
        "Rasinski",
        "Raški",
        "Severnobački",
        "Severnobanatski",
        "Srednjebanatski",
        "Sremski",
        "Šumadijski",
        "Toplički",
        "Zaječarski",
        "Zapadnobački",
        "Zlatiborski"
    ],
    Seychelles: [
        "Anse aux Pins",
        "Anse Boileau",
        "Anse Etoile",
        "Anse Royale",
        "Anu Cap",
        "Baie Lazare",
        "Baie Sainte Anne",
        "Beau Vallon",
        "Bel Air",
        "Bel Ombre",
        "Cascade",
        "Glacis",
        "Grand'Anse Mahe",
        "Grand'Anse Praslin",
        "La Digue",
        "La Riviere Anglaise",
        "Les Mamelles",
        "Mont Buxton",
        "Mont Fleuri",
        "Plaisance",
        "Pointe La Rue",
        "Port Glaud",
        "Roche Caiman",
        "Saint Louis",
        "Takamaka"
    ],
    "Sierra Leone": [
        "Eastern",
        "Northern",
        "Southern",
        "Western"
    ],
    Singapore: [
        "Central Singapore",
        "North East",
        "North West",
        "South East",
        "South West"
    ],
    "Sint Maarten (Dutch part)": [
        ""
    ],
    Slovakia: [
        "Banskobystricky",
        "Bratislavsky",
        "Kosicky",
        "Nitriansky",
        "Presovsky",
        "Trenciansky",
        "Trnavsky",
        "Zilinsky"
    ],
    Slovenia: [
        "Ajdovscina",
        "Apace",
        "Beltinci",
        "Benedikt",
        "Bistrica ob Sotli",
        "Bled",
        "Bloke",
        "Bohinj",
        "Borovnica",
        "Bovec",
        "Braslovce",
        "Brda",
        "Brezice",
        "Brezovica",
        "Cankova",
        "Celje",
        "Cerklje na Gorenjskem",
        "Cerknica",
        "Cerkno",
        "Cerkvenjak",
        "Cirkulane",
        "Crensovci",
        "Crna na Koroskem",
        "Crnomelj",
        "Destrnik",
        "Divaca",
        "Dobje",
        "Dobrepolje",
        "Dobrna",
        "Dobrova-Polhov Gradec",
        "Dobrovnik",
        "Dol pri Ljubljani",
        "Dolenjske Toplice",
        "Domzale",
        "Dornava",
        "Dravograd",
        "Duplek",
        "Gorenja Vas-Poljane",
        "Gorisnica",
        "Gorje",
        "Gornja Radgona",
        "Gornji Grad",
        "Gornji Petrovci",
        "Grad",
        "Grosuplje",
        "Hajdina",
        "Hoce-Slivnica",
        "Hodos",
        "Horjul",
        "Hrastnik",
        "Hrpelje-Kozina",
        "Idrija",
        "Ig",
        "Ilirska Bistrica",
        "Ivancna Gorica",
        "Izola",
        "Jesenice",
        "Jursinci",
        "Kamnik",
        "Kanal",
        "Kidricevo",
        "Kobarid",
        "Kobilje",
        "Kocevje",
        "Komen",
        "Komenda",
        "Koper",
        "Kodanjevica na Krki",
        "Kostel",
        "Kozje",
        "Kranj",
        "Kranjska Gora",
        "Krizevci",
        "Krsko",
        "Kungota",
        "Kuzma",
        "Lasko",
        "Lenart",
        "Lendava",
        "Litija",
        "Ljubljana",
        "Ljubno",
        "Ljutomer",
        "Log-Dragomer",
        "Logatec",
        "Loska Dolina",
        "Loski Potok",
        "Lovrenc na Pohorju",
        "Lukovica",
        "Luce",
        "Majsperk",
        "Makole",
        "Maribor",
        "Markovci",
        "Medvode",
        "Menges",
        "Metlika",
        "Mezica",
        "Miklavz na Dravskem Polju",
        "Miren-Kostanjevica",
        "Mirna",
        "Mirna Pec",
        "Mislinja",
        "Mokronog-Trebelno",
        "Moravce",
        "Moravske Toplice",
        "Mozirje",
        "Murska Sobota",
        "Naklo",
        "Nazarje",
        "Nova Gorica",
        "Novo Mesto",
        "Odranci",
        "Ormoz",
        "Osilnica",
        "Pesnica",
        "Piran",
        "Pivka",
        "Podcetrtek",
        "Podlehnik",
        "Podvelka",
        "Poljcane",
        "Postojna",
        "Prebold",
        "Preddvor",
        "Prevalje",
        "Ptuj",
        "Race-Fram",
        "Radece",
        "Radenci",
        "Radlje ob Dravi",
        "Radovljica",
        "Ravne na Koroskem",
        "Razkrizje",
        "Recica ob Savinji",
        "Rence-Vogrsko",
        "Ribnica",
        "Ribnica na Poboriu",
        "Rogaska Slatina",
        "Rogasovci",
        "Rogatec",
        "Ruse",
        "Salovci",
        "Selnica ob Dravi",
        "Semic",
        "Sempeter-Vrtojba",
        "Sencur",
        "Sentilj",
        "Sentjernej",
        "Sentjur",
        "Sentrupert",
        "Sevnica",
        "Sezana",
        "Skocjan",
        "Skofja Loka",
        "Skofljica",
        "Slovenj Gradec",
        "Slovenska Bistrica",
        "Slovenske Konjice",
        "Smarje pri elsah",
        "Smarjeske Toplice",
        "Smartno ob Paki",
        "Smartno pri Litiji",
        "Sodrazica",
        "Solcava",
        "Sostanj",
        "Sredisce ob Dravi",
        "Starse",
        "Store",
        "Straza",
        "Sveta Ana",
        "Sveta Trojica v Slovenskih Goricah",
        "Sveta Andraz v Slovenskih Goricah",
        "Sveti Jurij",
        "Sveti Jurij v Slovenskih Goricah",
        "Sveti Tomaz",
        "Tabor",
        "Tisina",
        "Tolmin",
        "Trbovlje",
        "Trebnje",
        "Trnovska Vas",
        "Trzin",
        "Trzic",
        "Turnisce",
        "Velenje",
        "Velika Polana",
        "Velike Lasce",
        "Verzej",
        "Videm",
        "Vipava",
        "Vitanje",
        "Vodice",
        "Vojnik",
        "Vransko",
        "Vrhnika",
        "Vuzenica",
        "Zagorje ob Savi",
        "Zavrc",
        "Zrece",
        "Zalec",
        "Zelezniki",
        "Zetale",
        "Ziri",
        "Zirovnica",
        "Zuzemberk"
    ],
    "Solomon Islands": [
        "Central",
        "Choiseul",
        "Guadalcanal",
        "Honiara",
        "Isabel",
        "Makira-Ulawa",
        "Malaita",
        "Rennell and Bellona",
        "Temotu",
        "Western"
    ],
    Somalia: [
        "Awdal",
        "Bakool",
        "Banaadir",
        "Bari",
        "Bay",
        "Galguduud",
        "Gedo",
        "Hiiraan",
        "Jubbada Dhexe",
        "Jubbada Hoose",
        "Mudug",
        "Nugaal",
        "Sanaag",
        "Shabeellaha Dhexe",
        "Shabeellaha Hoose",
        "Sool",
        "Togdheer",
        "Woqooyi Galbeed"
    ],
    "South Africa": [
        "Eastern Cape",
        "Free State",
        "Gauteng",
        "KwaZulu-Natal",
        "Limpopo",
        "Mpumalanga",
        "Northern Cape",
        "North West",
        "Western Cape"
    ],
    "South Georgia and South Sandwich Islands": [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
    ],
    "South Sudan": [
        "Central Equatoria",
        "Eastern Equatoria",
        "Jonglei",
        "Lakes",
        "Northern Bahr el Ghazal",
        "Unity",
        "Upper Nile",
        "Warrap",
        "Western Bahr el Ghazal",
        "Western Equatoria"
    ],
    Spain: [
        "Albacete",
        "Alicante",
        "Almería",
        "Araba/Álava",
        "Asturias",
        "Ávila",
        "Badajoz",
        "Barcelona",
        "Bizkaia",
        "Burgos",
        "Cáceres",
        "Cádiz",
        "Cantabria",
        "Castellón",
        "Cueta",
        "Ciudad Real",
        "Córdoba",
        "A Coruña",
        "Cuenca",
        "Gipuzkoa",
        "Girona",
        "Granada",
        "Guadalajara",
        "Huelva",
        "Huesca",
        "Illes Balears",
        "Jaén",
        "León",
        "Lleida",
        "Lugo",
        "Madrid",
        "Málaga",
        "Melilla",
        "Murcia",
        "Navarre",
        "Ourense",
        "Palencia",
        "Las Palmas",
        "Pontevedra",
        "La Rioja",
        "Salamanca",
        "Santa Cruz de Tenerife",
        "Segovia",
        "Sevilla",
        "Soria",
        "Tarragona",
        "Teruel",
        "Toledo",
        "Valencia",
        "Valladolid",
        "Zamora",
        "Zaragoza"
    ],
    "Sri Lanka": [
        "Basnahira",
        "Dakunu",
        "Madhyama",
        "Naegenahira",
        "Sabaragamuwa",
        "Uturu",
        "Uturumaeda",
        "Vayamba",
        "Uva"
    ],
    Sudan: [
        "Al Bahr al Ahmar",
        "Al Jazirah",
        "Al Khartum",
        "Al Qadarif",
        "An Nil al Abyad",
        "An Nil al Azraq",
        "Ash Shamaliyah",
        "Gharb Darfur",
        "Gharb Kurdufan",
        "Janub Darfur",
        "Janub Kurdufan",
        "Kassala",
        "Nahr an Nil",
        "Shamal Darfur",
        "Sharq Darfur",
        "Shiamal Kurdufan",
        "Sinnar",
        "Wasat Darfur Zalinjay"
    ],
    Suriname: [ "Brokopondo",
        "Commewijne",
        "Coronie",
        "Marowijne",
        "Nickerie",
        "Para",
        "Paramaribo",
        "Saramacca",
        "Sipaliwini",
        "Wanica"
    ],
    Swaziland: [
        "Hhohho",
        "Lubombo",
        "Manzini",
        "Shiselweni"
    ],
    Sweden: [
        "Blekinge",
        "Dalarnas",
        "Gotlands",
        "Gavleborgs",
        "Hallands",
        "Jamtlands",
        "Jonkopings",
        "Kalmar",
        "Kronobergs",
        "Norrbottens",
        "Orebro",
        "Ostergotlands",
        "Skane",
        "Sodermanlands",
        "Stockholm",
        "Varmlands",
        "Vasterbottens",
        "Vasternorrlands",
        "Vastmanlands",
        "Vastra Gotalands"
    ],
    Switzerland: [
        "Aargau",
        "Appenzell Ausserrhoden",
        "Appenzell Innerhoden",
        "Basel-Landschaft",
        "Basel-Stadt",
        "Bern",
        "Fribourg",
        "Genève",
        "Glarus",
        "Graubünden",
        "Jura",
        "Luzern",
        "Neuchâtel",
        "Nidwalden",
        "Obwalden",
        "Sankt Gallen",
        "Schaffhausen",
        "Schwyz",
        "Solothurn",
        "Thurgau",
        "Ticino",
        "Uri",
        "Valais",
        "Vaud",
        "Zug",
        "Zürich"
    ],
    "Syrian Arab Republic": [
        "Al Hasakah",
        "Al Ladhiqiyah",
        "Al Qunaytirah",
        "Ar Raqqah",
        "As Suwayda'",
        "Dar'a",
        "Dayr az Zawr",
        "Dimashq",
        "Halab",
        "Hamah",
        "Hims",
        "Idlib",
        "Rif Dimashq",
        "Tartus"
    ],
    Taiwan: [
        "Chang-hua",
        "Chia-i",
        "Hsin-chu",
        "Hua-lien",
        "Kao-hsiung",
        "Keelung",
        "Kinmen",
        "Lienchiang",
        "Miao-li",
        "Nan-t'ou",
        "P'eng-hu",
        "New Taipei",
        "P'ing-chung",
        "T'ai-chung",
        "T'ai-nan",
        "T'ai-pei",
        "T'ai-tung",
        "T'ao-yuan",
        "Yi-lan",
        "Yun-lin"
    ],
    Tajikistan: [
        "Dushanbe",
        "Kŭhistoni Badakhshon",
        "Khatlon",
        "Sughd"
    ],
    "Tanzania, United Republic of": [
        "Arusha",
        "Coast",
        "Dar es Salaam",
        "Dodoma",
        "Iringa",
        "Kagera",
        "Kigoma",
        "Kilimanjaro",
        "Lindi",
        "Manyara",
        "Mara",
        "Mbeya",
        "Morogoro",
        "Mtwara",
        "Mwanza",
        "Pemba North",
        "Pemba South",
        "Rukwa",
        "Ruvuma",
        "Shinyanga",
        "Singida",
        "Tabora",
        "Tanga",
        "Zanzibar North",
        "Zanzibar Central/South",
        "Zanzibar Urban/West"
    ],
    Thailand: [
        "Amnat Charoen",
        "Ang Thong",
        "Bueng Kan",
        "Buri Ram",
        "Chachoengsao",
        "Chai Nat",
        "Chaiyaphum",
        "Chanthaburi",
        "Chiang Mai",
        "Chiang Rai",
        "Chon Buri",
        "Chumphon",
        "Kalasin",
        "Kamphaeng Phet",
        "Kanchanaburi",
        "Khon Kaen",
        "Krabi",
        "Krung Thep Mahanakhon (Bangkok)",
        "Lampang",
        "Lamphun",
        "Loei",
        "Lop Buri",
        "Mae Hong Son",
        "Maha Sarakham",
        "Mukdahan",
        "Nakhon Nayok",
        "Nakhon Phathom",
        "Nakhon Phanom",
        "Nakhon Ratchasima",
        "Nakhon Sawan",
        "Nakhon Si Thammarat",
        "Nan",
        "Narathiwat",
        "Nong Bua Lam Phu",
        "Nong Khai",
        "Nonthaburi",
        "Pathum Thani",
        "Pattani",
        "Phangnga",
        "Phatthalung",
        "Phayao",
        "Phetchabun",
        "Phetchaburi",
        "Phichit",
        "Phitsanulok",
        "Phra Nakhon Si Ayutthaya",
        "Phrae",
        "Phuket",
        "Prachin Buri",
        "Prachuap Khiri Khan",
        "Ranong",
        "Ratchaburi",
        "Rayong",
        "Roi Et",
        "Sa Kaeo",
        "Sakon Nakhon",
        "Samut Prakan",
        "Samut Sakhon",
        "Samut Songkhram",
        "Saraburi",
        "Satun",
        "Sing Buri",
        "Si Sa ket",
        "Songkhla",
        "Sukhothai",
        "Suphan Buri",
        "Surat Thani",
        "Surin",
        "Tak",
        "Trang",
        "Trat",
        "Ubon Ratchathani",
        "Udon Thani",
        "Uthai Thani",
        "Uttaradit",
        "Yala",
        "Yasothon"
    ],
    "Timor-Leste": [
        "Aileu",
        "Ainaro",
        "Baucau",
        "Bobonaro",
        "Cova Lima",
        "Dili",
        "Ermera",
        "Lautem",
        "Liquica",
        "Manatuto",
        "Manufahi",
        "Oecussi",
        "Viqueque"
    ],
    Togo: [
        "Centre",
        "Kara",
        "Maritime",
        "Plateaux",
        "Savannes"
    ],
    Tokelau: [
        "",
        "",
        ""
    ],
    Tonga: [
        "'Eua",
        "Ha'apai",
        "Niuas",
        "Tongatapu",
        "Vava'u"
    ],
    "Trinidad and Tobago": [
        "Arima",
        "Chaguanas",
        "Couva-Tabaquite-Talparo",
        "Diefo Martin",
        "Mayaro-Rio Claro",
        "Penal-Debe",
        "Point Fortin",
        "Port-of-Spain",
        "Princes Town",
        "San Fernando",
        "San Juan-Laventille",
        "Sangre Grande",
        "Siparia",
        "Tobago",
        "Tunapuna-Piarco"
    ],
    Tunisia: [
        "Ariana",
        "Beja",
        "Ben Arous",
        "Bizerte",
        "Gabes",
        "Gafsa",
        "Jendouba",
        "Kairouan",
        "Kasserine",
        "Kebili",
        "Kef",
        "Mahdia",
        "Medenine",
        "Monastir",
        "Nabeul",
        "Sfax",
        "Sidi Bouzid",
        "Siliana",
        "Sousse",
        "Tataouine",
        "Tozeur",
        "Tunis",
        "Zaghouan"
    ],
    Turkey: [
        "Adana",
        "Adiyaman",
        "Afyonkarahisar",
        "Agri",
        "Aksaray",
        "Amasya",
        "Ankara",
        "Antalya",
        "Ardahan",
        "Artvin",
        "Aydin",
        "Balikesir",
        "Bartin",
        "Batman",
        "Bayburt",
        "Bilecik",
        "Bingol",
        "Bitlis",
        "Bolu",
        "Burdur",
        "Bursa",
        "Canakkale",
        "Cankiri",
        "Corum",
        "Denizli",
        "Diyarbakir",
        "Duzce",
        "Edirne",
        "Elazig",
        "Erzincan",
        "Erzurum",
        "Eskisehir",
        "Gaziantep",
        "Giresun",
        "Gumushane",
        "Hakkari",
        "Hatay",
        "Igdir",
        "Isparta",
        "Istanbul",
        "Izmir",
        "Kahramanmaras",
        "Karabuk",
        "Karaman",
        "Kars",
        "Kastamonu",
        "Kayseri",
        "Kilis",
        "Kirikkale",
        "Kirklareli",
        "Kirsehir",
        "Kocaeli",
        "Konya",
        "Kutahya",
        "Malatya",
        "Manisa",
        "Mardin",
        "Mersin",
        "Mugla",
        "Mus",
        "Nevsehir",
        "Nigde",
        "Ordu",
        "Osmaniye",
        "Rize",
        "Sakarya",
        "Samsun",
        "Sanliurfa",
        "Siirt",
        "Sinop",
        "Sirnak",
        "Sivas",
        "Tekirdag",
        "Tokat",
        "Trabzon",
        "Tunceli",
        "Usak",
        "Van",
        "Yalova",
        "Yozgat",
        "Zonguldak"
    ],
    Turkmenistan: [
        "Ahal",
        "Asgabat",
        "Balkan",
        "Dashoguz",
        "Lebap",
        "Mary"
    ],
    "Turks and Caicos Islands": [
        ""
    ],
    Tuvalu: [
        "Funafuti",
        "Nanumanga",
        "Nanumea",
        "Niutao",
        "Nui",
        "Nukufetau",
        "Nukulaelae",
        "Vaitupu"
    ],
    Uganda: [
        "Abim",
        "Adjumani",
        "Amolatar",
        "Amuria",
        "Amuru",
        "Apac",
        "Arua",
        "Budaka",
        "Bududa",
        "Bugiri",
        "Bukedea",
        "Bukwa",
        "Buliisa",
        "Bundibugyo",
        "Bushenyi",
        "Busia",
        "Butaleja",
        "Dokolo",
        "Gulu",
        "Hoima",
        "Ibanda",
        "Iganga",
        "Isingiro",
        "Jinja",
        "Kaabong",
        "Kabale",
        "Kabarole",
        "Kaberamaido",
        "Kalangala",
        "Kaliro",
        "Kampala",
        "Kamuli",
        "Kamwenge",
        "Kanungu",
        "Kapchorwa",
        "Kasese",
        "Katakwi",
        "Kayunga",
        "Kibaale",
        "Kiboga",
        "Kiruhura",
        "Kisoro",
        "Kitgum",
        "Koboko",
        "Kotido",
        "Kumi",
        "Kyenjojo",
        "Lira",
        "Luwero",
        "Lyantonde",
        "Manafwa",
        "Maracha",
        "Masaka",
        "Masindi",
        "Mayuge",
        "Mbale",
        "Mbarara",
        "Mityana",
        "Moroto",
        "Moyo",
        "Mpigi",
        "Mubende",
        "Mukono",
        "Nakapiripirit",
        "Nakaseke",
        "Nakasongola",
        "Namutumba",
        "Nebbi",
        "Ntungamo",
        "Oyam",
        "Pader",
        "Pallisa",
        "Rakai",
        "Rukungiri",
        "Sembabule",
        "Sironko",
        "Soroti",
        "Tororo",
        "Wakiso",
        "Yumbe"
    ],
    Ukraine: [
        "Cherkasy",
        "Chernihiv",
        "Chernivtsi",
        "Dnipropetrovsk",
        "Donetsk",
        "Ivano-Frankivsk",
        "Kharkiv",
        "Kherson",
        "Khmelnytskyi",
        "Kiev",
        "Kirovohrad",
        "Luhansk",
        "Lviv",
        "Mykolaiv",
        "Odessa",
        "Poltava",
        "Rivne",
        "Sumy",
        "Ternopil",
        "Vinnytsia",
        "Volyn",
        "Zakarpattia",
        "Zaporizhia",
        "Zhytomyr",
        "Avtonomna Respublika Krym",
        "Kyïv",
        "Sevastopol"
    ],
    "United Arab Emirates": [
        "Abu Dhabi",
        "Ajman",
        "Dubai",
        "Fujairah",
        "Ras al Khaimah",
        "Sharjah",
        "Umm Al Quwain"
    ],
    "United Kingdom": [
        "Avon",
        "Bedfordshire",
        "Berkshire",
        "Bristol, City of",
        "Buckinghamshire",
        "Cambridgeshire",
        "Cheshire",
        "Cleveland",
        "Cornwall",
        "Cumbria",
        "Derbyshire",
        "Devon",
        "Dorset",
        "Durham",
        "East Sussex",
        "Essex",
        "Gloucestershire",
        "Greater London",
        "Greater Manchester",
        "Hampshire (County of Southampton)",
        "Hereford and Worcester",
        "Herefordshire",
        "Hertfordshire",
        "Isle of Wight",
        "Kent",
        "Lancashire",
        "Leicestershire",
        "Lincolnshire",
        "London",
        "Merseyside",
        "Middlesex",
        "Norfolk",
        "Northamptonshire",
        "Northumberland",
        "North Humberside",
        "North Yorkshire",
        "Nottinghamshire",
        "Oxfordshire",
        "Rutland",
        "Shropshire",
        "Somerset",
        "South Humberside",
        "South Yorkshire",
        "Staffordshire",
        "Suffolk",
        "Surrey",
        "Tyne and Wear",
        "Warwickshire",
        "West Midlands",
        "West Sussex",
        "West Yorkshire",
        "Wiltshire",
        "Worcestershire",
        "Antrim",
        "Armagh",
        "Belfast, City of",
        "Down",
        "Fermanagh",
        "Londonderry",
        "Derry, City of",
        "Tyrone",
        "Aberdeen, City of",
        "Aberdeenshire",
        "Angus (Forfarshire)",
        "Argyll",
        "Ayrshire",
        "Banffshire",
        "Berwickshire",
        "Bute",
        "Caithness",
        "Clackmannanshire",
        "Cromartyshire",
        "Dumfriesshire",
        "Dunbartonshire (Dumbarton)",
        "Dundee, City of",
        "East Lothian (Haddingtonshire)",
        "Edinburgh, City of",
        "Fife",
        "Glasgow, City of",
        "Inverness-shire",
        "Kincardineshire",
        "Kinross-shire",
        "Kirkcudbrightshire",
        "Lanarkshire",
        "Midlothian (County of Edinburgh)",
        "Moray (Elginshire)",
        "Nairnshire",
        "Orkney",
        "Peeblesshire",
        "Perthshire",
        "Renfrewshire",
        "Ross and Cromarty",
        "Ross-shire",
        "Roxburghshire",
        "Selkirkshire",
        "Shetland (Zetland)",
        "Stirlingshire",
        "Sutherland",
        "West Lothian (Linlithgowshire)",
        "Wigtownshire",
        "Clwyd",
        "Dyfed",
        "Gwent",
        "Gwynedd",
        "Mid Glamorgan",
        "Powys",
        "South Glamorgan",
        "West Glamorgan"
    ],
    "United States": [
        "Alabama",
        "Alaska",
        "American Samoa",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Micronesia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Marshall Islands",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Northern Mariana Islands",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Palau",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Islands",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
        "Armed Forces Americas",
        "Armed Forces Europe, Canada, Africa and Middle East",
        "Armed Forces Pacific"
    ],
    "United States Minor Outlying Islands": [
        "Baker Island",
        "Howland Island",
        "Jarvis Island",
        "Johnston Atoll",
        "Kingman Reef",
        "Midway Islands",
        "Navassa Island",
        "Palmyra Atoll",
        "Wake Island",
        "Bajo Nuevo Bank",
        "Serranilla Bank"
    ],
    Uruguay: [
        "Artigas",
        "Canelones",
        "Cerro Largo",
        "Colonia",
        "Durazno",
        "Flores",
        "Florida",
        "Lavalleja",
        "Maldonado",
        "Montevideo",
        "Paysandú",
        "Río Negro",
        "Rivera",
        "Rocha",
        "Salto",
        "San José",
        "Soriano",
        "Tacuarembó",
        "Treinta y Tres"
    ],
    Uzbekistan: [
        "Toshkent shahri",
        "Andijon",
        "Buxoro",
        "Farg‘ona",
        "Jizzax",
        "Namangan",
        "Navoiy",
        "Qashqadaryo (Qarshi)",
        "Samarqand",
        "Sirdaryo (Guliston)",
        "Surxondaryo (Termiz)",
        "Toshkent wiloyati",
        "Xorazm (Urganch)",
        "Qoraqalpog‘iston Respublikasi (Nukus)"
    ],
    Vanuatu: [
        "Malampa",
        "Pénama",
        "Sanma",
        "Shéfa",
        "Taféa",
        "Torba"
    ],
    "Venezuela, Bolivarian Republic of": [
        "Dependencias Federales",
        "Distrito Federal",
        "Amazonas",
        "Anzoátegui",
        "Apure",
        "Aragua",
        "Barinas",
        "Bolívar",
        "Carabobo",
        "Cojedes",
        "Delta Amacuro",
        "Falcón",
        "Guárico",
        "Lara",
        "Mérida",
        "Miranda",
        "Monagas",
        "Nueva Esparta",
        "Portuguesa",
        "Sucre",
        "Táchira",
        "Trujillo",
        "Vargas",
        "Yaracuy",
        "Zulia"
    ],
    Vietnam: [
        "Đồng Nai",
        "Đồng Tháp",
        "Gia Lai",
        "Hà Giang",
        "Hà Nam",
        "Hà Tây",
        "Hà Tĩnh",
        "Hải Dương",
        "Hậu Giang",
        "Hòa Bình",
        "Hưng Yên",
        "Khánh Hòa",
        "Kiên Giang",
        "Kon Tum",
        "Lai Châu",
        "Lâm Đồng",
        "Lạng Sơn",
        "Lào Cai",
        "Long An",
        "Nam Định",
        "Nghệ An",
        "Ninh Bình",
        "Ninh Thuận",
        "Phú Thọ",
        "Phú Yên",
        "Quảng Bình",
        "Quảng Nam",
        "Quảng Ngãi",
        "Quảng Ninh",
        "Quảng Trị",
        "Sóc Trăng",
        "Sơn La",
        "Tây Ninh",
        "Thái Bình",
        "Thái Nguyên",
        "Thanh Hóa",
        "Thừa Thiên–Huế",
        "Tiền Giang",
        "Trà Vinh",
        "Tuyên Quang",
        "Vĩnh Long",
        "Vĩnh Phúc",
        "Yên Bái",
        "Cần Thơ",
        "Đà Nẵng",
        "Hà Nội",
        "Hải Phòng",
        "Hồ Chí Minh (Sài Gòn)"
    ],
    "Virgin Islands, British": [
        "Anegada",
        "Jost Van Dyke",
        "Tortola",
        "Virgin Gorda"
    ],
    "Virgin Islands, U.S.": [
        "St. Thomas",
        "St. John",
        "St. Croix"
    ],
    "Wallis and Futuna": [
        "Alo",
        "Sigave",
        "Wallis"
    ],
    "Western Sahara": [
        "Es Smara",
        "Boujdour",
        "Laâyoune",
        "Aousserd",
        "Oued ed Dahab"
    ],
    Yemen: [
        "Abyān",
        "'Adan",
        "Aḑ Ḑāli'",
        "Al Bayḑā'",
        "Al Ḩudaydah",
        "Al Jawf",
        "Al Mahrah",
        "Al Maḩwīt",
        "'Amrān",
        "Dhamār",
        "Ḩaḑramawt",
        "Ḩajjah",
        "Ibb",
        "Laḩij",
        "Ma'rib",
        "Raymah",
        "Şā‘dah",
        "Şan‘ā'",
        "Shabwah",
        "Tā‘izz"
    ],
    Zambia: [
        "Central",
        "Copperbelt",
        "Eastern",
        "Luapula",
        "Lusaka",
        "Northern",
        "North-Western",
        "Southern",
        "Western"
    ],
    Zimbabwe: [
        "Bulawayo",
        "Harare",
        "Manicaland",
        "Mashonaland Central",
        "Mashonaland East",
        "Mashonaland West",
        "Masvingo",
        "Matabeleland North",
        "Matabeleland South",
        "Midlands"
    ]
};

exports.countryList = countryList;
exports.regionMap = regionMap;
