import React, {Component} from "react";

import {Link, Redirect} from "react-router-dom";
import axios from "axios";

import {Modal} from "reactstrap";
import {X} from "react-feather";

import {THEME} from "../NewSettingsComponents";
import {isMobile} from "../../utils/utils";
import "../../styles/Login.css";

import SwooshArea from "../SwooshArea";

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",

            visible: false,
            notVerified: false,

            tfa: false,
            tfaCode: "",

            gotLoggedIn: false,
            loggedIn: false,

            message: "",
            disabled: false,

            modal: false,
            modalMsg: "",
            modalCb: null,

            onMobile: isMobile(),
        };

        this.setMobile = this.setMobile.bind(this);
        this.displayModal = this.displayModal.bind(this);
        this.listenForEnter = this.listenForEnter.bind(this);
        this.keyHandlerLogin = this.keyHandlerLogin.bind(this);
    }

    setMobile() {
        console.log("Screen resize detected");

        this.setState({
            onMobile: isMobile(),
        });
    }

    onChange = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState(state);
    };

    displayModal(msg, cb = null) {
        this.setState({modal: true, modalMsg: msg, modalCb: cb});
    }

    keyHandlerLogin = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();

            const func = this.state.tfa ? "tfaOnSubmit" : "onSubmit";
            this[func]({preventDefault: () => {}});
        }
    };

    listenForEnter = (listen) => {
        const func = listen ? "addEventListener" : "removeEventListener";
        document[func]("keydown", this.keyHandlerLogin);
    };

    componentDidMount() {
        window.addEventListener("resize", this.setMobile);

        axios.get("/api/checklogin")
            .then((result) => {
                this.setState({loggedIn: result.data.loggedIn, gotLoggedIn: true}, () => {
                    const userInput = document.getElementById("userInput");
                    const tfaInput = document.getElementById("tfaInput");

                    if (userInput || tfaInput) {
                        this.listenForEnter(true);
                    }
                });
            });

        // temporary pop-up to explain users the login after
        // being redirected from LA events
        if (this.props.fromLAEvent) {
            this.displayModal("Please login to view the LA Book Scavenger Hunt");
        }
    }

    componentWillUnmount() {
        this.listenForEnter(false);

        window.removeEventListener("resize", this.setMobile);
    }

    maybeAlertReinstated = (result, cb = null) => {
        if (result.data.descheduled) {
            const msg = "Welcome back. Your account has been reinstated and will not be deleted.";

            this.displayModal(msg, cb);
        } else if (cb) {
            cb();
        }
    };

    postLogin(result) {
        this.maybeAlertReinstated(result, () => {
            this.setState({gotLoggedIn: false}, () => {
                window.location.reload();
            });
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.listenForEnter(false);

        const { email, password } = this.state;

        this.setState({
            message: "",
            disabled: true,
        }, () => {
            axios.post("/api/login", {
                email, password,
            }, {withCredentials: true})
                .then((result) => {
                    if (!result.data.tfa) {
                        this.postLogin(result);
                    } else {
                        this.setState({
                            tfa: true,
                            message: "",
                            disabled: false,
                        }, () => {
                            this.listenForEnter(true);
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        disabled: false,
                        message: error.response.data.msg,
                        notVerified: error.response.data.notVerified,
                    }, () => {
                        this.listenForEnter(true);
                    });
                });
        });
    };

    tfaOnSubmit = (e) => {
        e.preventDefault();
        this.listenForEnter(false);

        this.setState({
            disabled: true
        }, () => {
            axios.post("/api/tfa", {
                message: "",
                email: this.state.email,
                tfaCode: this.state.tfaCode
            })
                .then((result) => {
                    if (result.data.success) {
                        this.postLogin(result);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.setState({
                            disabled: false,
                            message: error.response.data.msg,
                            notVerified: error.response.data.notVerified,
                        }, () => {
                            this.listenForEnter(true);
                        });
                    }
                });
        });
    };

    resendVerification = () => {
        const email = this.state.email;
        axios.post("/api/resend", {email})
            .then(() => {
                this.setState({message: "Verification email re-sent", notVerified: false});
            });
    };

    onDismiss = () => {
        this.setState({message: ""});
    };

    showVerify = () => {
        if (this.state.notVerified) {
            return (
                <div onClick={this.resendVerification}><span style={{borderBottom: "1px solid white"}}>
                    Click here</span> to resend the verification email.</div>);
        }
    };

    displayAlert = () => {
        const { message, tfa } = this.state;

        if (message !== "") {
            return (
                <div
                    id="login-alert-wrapper"
                    className={`dismiss-alert ${tfa ? "tfa" : ""}`}
                    onClick={this.onDismiss}
                    style={{width: "100%"}}>
                    <div>{message}{this.showVerify()}</div>
                </div>
            );
        }
    };

    render() {
        const { disabled, gotLoggedIn, loggedIn, onMobile, email, password, tfa } = this.state;


        let content;
        let title;

        const inputStyle = {width: "100%", backgroundColor: "white"};

        if (!gotLoggedIn) {
            content = <div />;
        } else {
            if (loggedIn) {
                return <Redirect to="/" />;
            } else {
                if (tfa) {
                    title = "Two Factor Authentication";
                    content = <>
                    <h2 className="primary" id="tfa-stage-lower-header">
                        Please enter the code we have sent to your email. It expires in 5 minutes.
                    </h2>

                    {this.displayAlert()}

                    <input id="tfaInput"
                        className="flat-input w-fill"
                        name="tfaCode"
                        placeholder="Code"
                        onChange={this.onChange}
                        style={inputStyle}
                    />

                    <div className="row-apart w-fill" id="tfa-stage-login-btn">
                        <div className="btn" onClick={disabled ? null : this.tfaOnSubmit}>LOG IN</div>
                    </div>
                  </>;
                } else {
                    title = "Login";
                    content = <>
                    {this.displayAlert()}
                    <input id="userInput"
                        className="login-inputs"
                        name="email"
                        value={email}
                        placeholder="Email"
                        onChange={this.onChange}
                        style={inputStyle}
                    />
                    <input id="passwordInput"
                        className="login-inputs"
                        type="password"
                        name="password"
                        value={password}
                        placeholder="Password"
                        onChange={this.onChange}
                        style={inputStyle}
                    />
                    <div className="login-submit-buttons" style={{margin: "0 0px"}}>
                        <div className="col-center media-core" id="login-stage-action-btns">
                            <div id="loginButton" className="btn" onClick={disabled ? null : this.onSubmit}>
                                LOGIN
                            </div>
                            <Link to="/forgot" className="link mt-10" id="login-stage-forgot-password">
                                Forgot your password?
                            </Link>
                        </div>
                        <div
                            className="col media-drift primary"
                            id="login-stage-signup-wrapper"
                            style={{lineHeight: 1}}>
                            <span>Not a member?</span>
                            <Link to="/register"
                                className="link"
                                id="login-stage-signup-link"
                                style={{marginTop: "10px"}}>
                                Sign up here
                            </Link>
                        </div>
                    </div>
                  </>;
                }
            }
        }

        const modal = <>
          <Modal id="talkSchedule" isOpen={this.state.modal} style={{
              height: "auto",
              backgroundColor: THEME.colorPrimary,
              ...(onMobile ? {} : {width: "750px"})
          }}>
              <X stroke="white" id="close-modal" size="25" onClick={() => {
                  this.setState({modal: false}, () => {
                      if (this.state.modalCb) {
                          this.state.modalCb();
                      }
                  });
              }} />
              <h1 style={window.innerWidth <= 700 ? {
                  paddingLeft: "50px",
                  paddingRight: "50px",
              } : {}} className="chat-modal">{this.state.modalMsg}</h1>
          </Modal>
        </>;

        const html = <>
          <SwooshArea modal={modal} title={title} titleFontSize={"70px"} >
              <div style={{display: "grid", gridAutoFlow: "row", justifyItems: "end", alignContent: "start"}}>
                  {content}
              </div>
          </SwooshArea>
        </>;

        return html;
    }
}

export default Login;
