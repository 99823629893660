import React, { Component } from "react";
import {get} from "lodash";

import AnswerMultipleChoiceQuestions from "./AnswerMultipleChoiceQuestions";
import AnswerRadioQuestions from "./AnswerRadioQuestions";
import AnswerTextQuestions from "./AnswerTextQuestions";
import CategoryQuestions from "./CategoryQuestions";

import unchecked from "../../images/check/unchecked.png";
import checked from "../../images/check/checked.png";


import {isMobile} from "../../utils/utils";

import { ExpandableDropdown, THEME } from "../NewSettingsComponents";

import "../../styles/FriendQuestionsPageOne.css";

class FriendQuestionsPageOne extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dropped: {},
            onMobile: isMobile(),
        };
        this.setMobile = this.setMobile.bind(this);
    }

    setMobile() {
        console.log("Screen resize detected");

        this.setState({
            onMobile: isMobile(),
        });
    }

    componentDidMount() {
        window.addEventListener("resize", this.setMobile);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setMobile);
    }

    getQuestions = () => {
        let response;
        let initState;

        const tmp = [];

        const questions = this.props.friendQuestions;
        const responses = this.props.questionResponses;

        const {multipleChoice: multiQuestions, radio: radioQuestions, text: textQuestions} = questions;

        if (multiQuestions) {
            multiQuestions.forEach((question, i) => {
                response = responses[question._id];
                initState = response ? response.selected : [];

                tmp.push({
                    question: (
                        <AnswerMultipleChoiceQuestions
                            key={"m" + i}
                            question={question}
                            updateMultipleChoiceQuestion={this.props.updateMultipleChoiceQuestion}
                            initState={initState}
                        />
                    ), priority: question.priority
                });
            });
        }

        if (radioQuestions) {
            radioQuestions.forEach((question, i) => {
                response = responses[question._id];
                initState = response ? response.selected : "";

                tmp.push({
                    question: (
                        <AnswerRadioQuestions
                            key={"r" + i}
                            question={question}
                            updateRadioQuestion={this.props.updateRadioQuestion}
                            initState={initState}
                        />
                    ), priority: question.priority
                });
            });
        }

        if (textQuestions) {
            textQuestions.forEach((question, i) => {
                response = responses[question._id];
                initState = response ? response.response : "";

                tmp.push({
                    question: (
                        <AnswerTextQuestions
                            key={"t" + i}
                            question={question}
                            updateTextQuestion={this.props.updateTextQuestion}
                            initState={initState}
                        />
                    ), priority: question.priority
                });
            });
        }

        tmp.sort((a, b) => {
            return b.priority - a.priority;
        });

        return tmp.map((q) => (q.question));
    };

    renderFriendshipQuestions = () => {
        const cat = "defaultFriendship";
        const { onMobile } = this.state;

        const html = <>
          <ExpandableDropdown title={"General Questions"}>
              <div
                  style={{
                      display: "grid",
                      gridAutoFlow: "row",
                      rowGap: THEME.marginSmall,
                      padding: onMobile ? THEME.marginXSmall : THEME.marginSmall}}>
                  {this.getQuestions()}
              </div>
          </ExpandableDropdown>
        </>;

        return html;
    };

    renderSharingQuestion = () => {
        const aboutMePlaceholder = "For example: I love to paint and make pottery..." +
            "I enjoy doing volunteer work in my spare time... I love Game of Thrones...";

        const html = <>
          <ExpandableDropdown title={"A few things I'd like to share:"}>
              <div
                  style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: THEME.marginXSmall}}>
                  <textarea
                      style={{
                          resize: "none",
                          outline: "none",
                          width: "100%",
                          padding: 0,
                          margin: 0,
                          border: "none",
                          height: "150px",
                      }}
                      name="aboutMeFriend"
                      placeholder={aboutMePlaceholder}
                      className="flat-textarea"
                      value={this.props.aboutMeFriend}
                      onChange={this.props.onChange}
                      id="about-me-friend-text-area"
                  />
              </div>
          </ExpandableDropdown>
        </>;

        return html;
    };

    toggle = (cat) => () => {
        const dropped = this.state.dropped;

        dropped[cat] = !dropped[cat];
        this.setState({ dropped });
    };

    render() {
        return (
            <div style={{display: "grid", gridAutoFlow: "row", gap: THEME.marginXSmall}}>
                <div>
                    <h4 className="friend-content-page-one-header">REQUIRED</h4>
                </div>

                {this.renderFriendshipQuestions()}

                {get(this.props, ["categoryQuestions", "questionTypes", "length"], 0) > 0 && (
                    <React.Fragment>
                        <div id="friend-content-page-one-header-wrapper">
                            <h4 className="friend-content-page-one-header">OPTIONAL</h4>
                        </div>
                        <CategoryQuestions
                            categoryQuestions={this.props.categoryQuestions}
                            updateRadioQuestion={this.props.updateRadioQuestion}
                            updateTextQuestion={this.props.updateTextQuestion}
                            updateMultipleChoiceQuestion={this.props.updateMultipleChoiceQuestion}
                            questionResponses={this.props.questionResponses}
                        />
                    </React.Fragment>
                )}

                {this.renderSharingQuestion()}

                <div style={{display: "flex"}} onClick={this.props.updateAccountType}>
                    <img src={this.props.userType === "student" ? checked : unchecked}
                        id="friend-content-student-img" alt="friend-content-student-checkbox"
                    />
                    <div className="no-select">
                        I am a student and want to meet other students.
                    </div>
                </div>
            </div>
        );
    }
}

export default FriendQuestionsPageOne;
