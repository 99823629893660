import React, { Component } from "react";

import axios from "axios";

import { THEME, Button, Subheader, SettingsHeader, Select } from "../NewSettingsComponents";

import AnswerTextQuestions from "./AnswerTextQuestions";
import AnswerRadioQuestions from "./AnswerRadioQuestions";
import AnswerMultipleChoiceQuestions from "./AnswerMultipleChoiceQuestions";

import { get } from "lodash";
import { AgeExtrema } from "../../utils/profileConstants";

import rotating from "../../images/rotating.png";
import checked from "../../images/check/checked.png";
import unchecked from "../../images/check/unchecked.png";

import "../../styles/DatingQuestions.css";

class DatingQuestions extends Component {
    static CACHE_FIELDS = ["datingLowRange", "datingHighRange", "datingGenderPreference", "height",
        "noSmoke", "noDrug", "noDrink", "questionResponses"];
    static CACHE_KEY = "datingQuestions";

    constructor(props) {
        super(props);

        const cachedState = this.loadCache();

        let questionResponses = get(props, ["currentUser", "questionResponses"], []);
        questionResponses = this.formatResponses(questionResponses);

        if (get(cachedState, "questionResponses")) {
            questionResponses = {
                ...questionResponses,
                ...cachedState.questionResponses
            };

            delete cachedState.questionResponses;
        }

        this.state = {
            datingAccount: true,
            userGender: this.props.gender,
            datingGenderPreference: get(props, ["currentUser", "datingGenderPreference"], []),
            datingLowRange: get(props, ["currentUser", "datingLowRange"], "--"),
            datingHighRange: get(props, ["currentUser", "datingHighRange"], "--"),
            height: get(props, ["currentUser", "height"], ""),
            datingQuestions: {},
            datingQuestionsLoaded: false,
            questionResponses,
            gotAllQuestions: false,
            noSmoke: get(props, ["currentUser", "noSmoke"], false),
            noDrug: get(props, ["currentUser", "noDrug"], false),
            noDrink: get(props, ["currentUser", "noDrink"], false),
            validGenders: [],
            datingPhotos: [null, null],

            msg: "",
            infoColor: false,
            inProgress: false,

            currentUser: get(props, ["currentUser"], undefined),
            ...cachedState
        };

        this.updateMultipleChoiceQuestion = this.updateMultipleChoiceQuestion.bind(this);
        this.updateRadioQuestion = this.updateRadioQuestion.bind(this);
        this.updateTextQuestion = this.updateTextQuestion.bind(this);
    }

    formatResponses = (responses) => {
        const formattedResponses = {};

        responses.forEach((response) => {
            const formattedResponse = {
                question: response.question
            };

            let question;

            if (response.radioQuestionResponse) {
                question = response.radioQuestionResponse;

                formattedResponse["selected"] = question.selected;
                formattedResponse["type"] = "radio";
            } else if (response.textQuestionResponse) {
                question = response.textQuestionResponse;

                formattedResponse["response"] = question.text;
                formattedResponse["type"] = "text";
            } else if (response.multipleChoiceQuestionResponse) {
                question = response.multipleChoiceQuestionResponse;

                formattedResponse["selected"] = question.answers;
                formattedResponse["type"] = "multipleChoice";
            }

            if (question) {
                formattedResponse["id"] = question.questionId;
                formattedResponses[question.questionId] = formattedResponse;
            }
        });

        return formattedResponses;
    };

    componentDidMount() {
        axios.get("/api/questions?type=dating").then((result) => {
            this.setState({
                datingQuestions: result.data.questions
            }, () => {
                axios.get("/signup/genders")
                    .then((result) => {
                        let validGenders = result.data.genders;
                        const { userGender } = this.state;

                        if (userGender === "Man") {
                            validGenders = ["Woman", "Man", "Non-Binary", "Man or Woman"];
                        } else if (userGender === "Woman") {
                            validGenders = ["Man", "Woman", "Non-Binary", "Man or Woman"];
                        } else if (userGender === "Non-Binary") {
                            validGenders = ["Non-Binary", "Man", "Woman", "Man or Woman"];
                        }

                        this.setState({
                            validGenders
                        }, () => {
                            this.initializeQuestionResponses();
                        });
                    }).catch(() => { });
            });
        }).catch(() => { });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.datingQuestions[0] !== this.state.datingQuestions[0] && this.state.datingQuestionsLoaded) {
            this.initializeQuestionResponses();
        }
    }

    componentWillUnmount() {
        // When we're creating the profile (i.e. not updating),
        // we already cache after all API calls have succeeded.
        if (this.props.update) {
            this.cacheState();
        }
    }

    initializeQuestionResponses = () => {
        const questionResponses = this.state.questionResponses;
        const questions = this.state.datingQuestions;

        questions.multipleChoice
            .forEach(questionObject => {
                const { _id: id, question: questionText } = questionObject;
                questionResponses[id] = {
                    id,
                    selected: get(questionResponses, [id, "selected"], []),
                    friend: false,
                    type: "multipleChoice",
                    question: questionText,
                };
            });

        questions.text
            .forEach(questionObject => {
                const { _id: id, question: questionText, minimumChars } = questionObject;
                questionResponses[id] = {
                    id,
                    response: get(questionResponses, [id, "response"], ""),
                    minChars: minimumChars,
                    friend: false,
                    type: "text",
                    question: questionText
                };
            });

        questions.radio
            .forEach(questionObject => {
                const { _id: id, question: questionText } = questionObject;
                questionResponses[id] = {
                    id,
                    selected: get(questionResponses, [id, "selected"], ""),
                    friend: true,
                    type: "radio",
                    question: questionText
                };
            });

        this.setState({
            questionResponses,
            gotAllQuestions: true
        });
    };

    updateMultipleChoiceQuestion = (id, selections) => {
        const newQuestions = Object.assign({}, this.state.questionResponses);
        newQuestions[id]["selected"] = selections;

        this.setState({ questionResponses: newQuestions });
    };

    updateTextQuestion = (id, answer) => {
        const newQuestions = Object.assign({}, this.state.questionResponses);
        newQuestions[id]["response"] = answer;

        this.setState({ questionResponses: newQuestions });
    };

    updateRadioQuestion = (id, selection) => {
        const newQuestions = Object.assign({}, this.state.questionResponses);
        newQuestions[id]["selected"] = selection;

        this.setState({ questionResponses: newQuestions });
    };

    getLabelForGender = (gender) => {
        if (gender === "Man") {
            return "male";
        } else if (gender === "Woman") {
            return "female";
        } else if (gender === "Non-Binary") {
            return "non-binary";
        } else if (gender === "Man or Woman") {
            return "a male or a female";
        } else {
            return (gender || "").toLowerCase();
        }
    };

    getGenderDropDown = () => {
        const tmp = [];

        this.state.validGenders.forEach((gender, i) => {
            tmp.push(<option key={i} value={gender}>{this.getLabelForGender(gender)}</option>);
        });

        return tmp;
    };

    getAgeDropDown = () => {
        const tmp = [<option key="-1">--</option>];

        for (let i = AgeExtrema.YOUNGEST_AGE; i <= AgeExtrema.OLDEST_AGE; i++) {
            tmp.push(<option key={i}>{i}</option>);
        }

        return tmp;
    };

    selectOnChange = (e) => {
        const name = e.target.name;
        const options = e.target.options;

        let val = "";

        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                val = options[i].value;
                break;
            }
        }

        const state = this.state;
        state[name] = val;

        this.setState(state, this.cacheState);
    };

    onChange = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;

        this.setState(state, this.cacheState);
    };

    checkOnClick = (field, isGender = false) => {
        if (!isGender) {
            this.setState({ [field]: !this.state[field] }, this.cacheState);
        } else {
            const key = "datingGenderPreference";
            let newPreferences = this.state[key];

            // handler for possible string values that was cached
            // temp, will remove once all stale values are converted in database
            if (typeof newPreferences == "string") {
                newPreferences = [newPreferences];
            }

            if (newPreferences.includes(field)) {
                newPreferences.splice(newPreferences.indexOf(field), 1);
            } else {
                newPreferences.push(field);
            }
            this.setState({
                [key]: newPreferences},
            this.cacheState
            );
        }
    };

    goBack = () => {
        this.cacheState();
        this.props.prevState();
    };

    loadCache = () => {
        let cachedState = JSON.parse(window.localStorage.getItem(this.props.currentUser.email)) || {};
        cachedState = cachedState[DatingQuestions.CACHE_KEY];

        if (cachedState) {
            this.props.updateState({
                datingQuestions: cachedState
            });
        }

        return cachedState;
    };

    cacheState = () => {
        const cache = {};

        for (const key of DatingQuestions.CACHE_FIELDS) {
            cache[key] = this.state[key];
        }

        const cachedState = JSON.parse(window.localStorage.getItem(this.props.currentUser.email)) || {};

        cachedState[DatingQuestions.CACHE_KEY] = cache;
        window.localStorage.setItem(this.props.currentUser.email, JSON.stringify(cachedState));
    };

    onSubmit = () => {
        const state = this.state;
        const shouldIgnore = this.props.shouldHide;
        if (!shouldIgnore && state.datingGenderPreference.length === 0) {
            return this.setState({
                infoColor: false,
                inProgress: false,
                msg: "Please select a gender preference"
            });
        }
        // If parseInt returns undefined for one or both of these, check will still fail as expected.
        if (!(parseInt(state.datingHighRange) >= parseInt(state.datingLowRange))) {
            return this.setState({
                infoColor: false,
                inProgress: false,
                msg: "Must select a valid age range"
            });
        }
        if (!shouldIgnore) {
            const height = state.height;

            if (height === "") {
                return this.setState({
                    infoColor: false,
                    inProgress: false,
                    msg: "Please enter a height."
                });
            }

            let components;

            for (const delimiter of ["'", "‘", "’"]) {
                const potential = height.split(delimiter);

                if (potential.length === 2) {
                    components = potential;
                    break;
                }
            }

            if (!components) {
                return this.setState({
                    infoColor: false,
                    inProgress: false,
                    msg: "Please enter a height in feet and inches, e.g. 5'10."
                });
            }

            const [feet, inches] = components;
            const isNaN = (val) => (Number.isNaN(Number.parseInt(val)));

            if (isNaN(feet) || isNaN(inches)) {
                return this.setState({
                    infoColor: false,
                    inProgress: false,
                    msg: "Please enter numeric values for height in feet and inches."
                });
            }
        }

        const finalPhotos = [];
        const photos = state.datingPhotos;

        for (let i = 0; i < photos.length; i++) {
            const photo = photos[i];

            if (photo) {
                finalPhotos.push(photo);

                if (photo.size > 3000000) {
                    return this.setState({
                        infoColor: false,
                        inProgress: false,
                        msg: "Maximum photo size is 2 MB"
                    });
                }
            }
        }

        if (!this.props.update || this.props.reloadPage) {
            if (finalPhotos.length === 0) {
                return this.setState({
                    infoColor: false,
                    inProgress: false,
                    msg: "Please select a photo of yourself to upload."
                });
            } else {
                state.datingPhotos = finalPhotos;
            }
        }

        const sentState = { ...state };
        delete sentState.currentUser;

        if (!this.props.update) {
            this.setState({
                inProgress: true
            }, () => {
                this.props.updateState({
                    datingQuestions: sentState
                }, () => {
                    this.cacheState();
                    this.props.nextState();
                });
            });
        } else {
            const formData = new FormData();

            // This parameter can't be updated, only set.
            if (!this.props.reloadPage) {
                delete sentState.height;
                // delete sentState.datingGenderPreference;
            }

            formData.append("data", JSON.stringify(sentState));

            finalPhotos.forEach((photo, index) => {
                formData.append("photo" + index, photo);
            });

            this.setState({
                infoColor: true, inProgress: true,
                msg: "Updating Relationship profile..."
            }, () => {
                axios.post("/api/users/update-answers", formData).then(() => {
                    this.cacheState();

                    if (this.props.reloadPage) {
                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                        window.location.reload();
                    } else {
                        this.setState({
                            infoColor: true, inProgress: false,
                            msg: "Relationship profile successfully updated!"
                        }, () => {
                            axios.get("/api/settings").then((result) => {
                                if (result.data.success) {
                                    const currentUser = result.data.currentUser;

                                    this.setState({
                                        currentUser
                                    });
                                }
                            });
                        });
                    }
                }).catch(() => {
                    this.setState({
                        infoColor: false, inProgress: false,
                        msg: "Something went wrong updating. Please try again."
                    });
                });
            });
        }
    };

    onDismiss = () => {
        this.setState({ msg: "", infoColor: false, inProgress: false });
    };

    displayAlert = () => {
        const { msg, infoColor, inProgress } = this.state;

        if (msg !== "") {
            return (
                <div id="dating-content-alert-wrapper" onClick={this.onDismiss}
                    className={`dismiss-alert ${infoColor ? "info" : ""}`}
                >
                    {inProgress ?
                        <img src={rotating} className="rotating-icon" alt="rotating-icon" /> :
                        <div id="dating-content-alert-icon">!</div>
                    }
                    <div id="dating-content-alert-message">{msg}</div>
                </div>
            );
        }
    };

    getQuestions = () => {
        let response;
        let initState;

        const tmp = [];

        const questions = this.state.datingQuestions;
        const responses = this.state.questionResponses;

        const { multipleChoice: multiQuestions, radio: radioQuestions, text: textQuestions } = questions;

        if (multiQuestions) {
            multiQuestions.forEach((question, i) => {
                response = responses[question._id];
                initState = response ? response.selected : [];

                tmp.push({
                    question: <AnswerMultipleChoiceQuestions
                        key={"m" + i}
                        question={question}
                        updateMultipleChoiceQuestion={this.updateMultipleChoiceQuestion}
                        initState={initState} />,
                    priority: question.priority
                });
            });
        }

        if (radioQuestions) {
            radioQuestions.forEach((question, i) => {
                response = responses[question._id];
                initState = response ? response.selected : "";

                tmp.push({
                    question: <AnswerRadioQuestions
                        key={"r" + i}
                        question={question}
                        updateRadioQuestion={this.updateRadioQuestion}
                        initState={initState} />,
                    priority: question.priority
                });
            });
        }

        if (textQuestions) {
            textQuestions.forEach((question, i) => {
                response = responses[question._id];
                initState = response ? response.response : "";

                tmp.push({
                    question: <AnswerTextQuestions
                        key={"t" + i}
                        question={question}
                        updateTextQuestion={this.updateTextQuestion}
                        initState={initState} />,
                    priority: question.priority
                });
            });
        }

        tmp.sort((a, b) => {
            return b.priority - a.priority;
        });

        return tmp.map((q) => (q.question));
    };

    onUploadPhoto = (e) => {
        // Every photo input has a name of the format "photo{index}"
        const photos = this.state.datingPhotos;
        const index = e.target.name[e.target.name.length - 1];

        // We only allow one upload per file input.
        photos[index] = e.target.files[0];
        const filename = (e.target.files[0] || {}).name;

        this.setState({
            datingPhotos: photos,
            [e.target.name]: filename,
        });
    };

    getPhotoURLs = () => {
        const datingPhotos = get(this.state.currentUser, ["datingPhotos"], []);
        const urls = datingPhotos.map(photo => {
            const prefix = `${process.env.REACT_APP_GCLOUD_PREFIX}/`;
            const suffix = `${process.env.REACT_APP_GCLOUD_BUCKET}/${photo.imgUrl}`;
            return prefix + suffix;
        });
        return urls;
    };

    getDatingOption(field, label, isGender = false) {
        let isChecked;
        if (!isGender) {
            isChecked = this.state[field];
        } else {
            isChecked = this.state["datingGenderPreference"].includes(field);
        }
        return (
            <div className="filter-check dating-option" onClick={
                () => {
                    this.checkOnClick(field, isGender);
                }
            }>
                <img src={isChecked ? checked : unchecked} alt="dating-option-check-box" />
                <div className="no-select">
                    {label}
                </div>
            </div>
        );
    }

    render() {
        const { inProgress } = this.state;

        const hideQuestion = this.props.shouldHide;

        const noFileChosen = "Please add photo";
        const innerContent = <>
            <Subheader>AGE</Subheader>
            <div>
                {"I'm looking to meet a person who is "}
                {!hideQuestion &&
                    <div style={{marginTop: "10px"}}>
                        {this.getDatingOption("Woman",
                            "Female", true)}
                        {this.getDatingOption("Man",
                            "Male", true)}
                        {this.getDatingOption("Non-Binary",
                            "Non-Binary", true)}
                    </div>
                }
                <span className={hideQuestion ? "hide-question" : ""}>
                    {" between "}
                    <Select
                        name="datingLowRange"
                        defaultValue={this.state.datingLowRange}
                        onChange={this.selectOnChange}
                    >
                        {this.getAgeDropDown()}
                    </Select>
                    {" and "}
                    <Select name="datingHighRange"
                        defaultValue={this.state.datingHighRange}
                        onChange={this.selectOnChange}
                    >
                        {this.getAgeDropDown()}
                    </Select>
                </span>
            </div>
            {!hideQuestion &&
                <>
                  <Subheader>HEIGHT</Subheader>
                  <label id="dating-content-height-wrapper">
                      What is your height?
                      <input name="height"
                          className="flat-input inline"
                          value={this.state.height}
                          placeholder="e.g. 5'9"
                          onChange={this.onChange}
                          id="dating-content-height"
                      />
                  </label>
                </>
            }

            {this.getQuestions()}

            <Subheader>PREFERENCES</Subheader>
            <div>
                {this.getDatingOption("noDrug",
                    "I am looking for someone who does not use drugs.")}
                {this.getDatingOption("noSmoke",
                    "I am looking for someone who does not smoke.")}
                {this.getDatingOption("noDrink",
                    "I am looking for someone who does not drink, or " +
                    "only drinks on special occasions.")}
            </div>

            <Subheader>PHOTOS</Subheader>
            <div style={{display: "grid", gridAutoFlow: "column", columnGap: THEME.marginXSmall}}>
                {this.getPhotoURLs().map((url, idx) => <img key={idx} style={{maxWidth: "100%"}} src={url}/>)}
            </div>

            <div>
                {this.props.update ? "You can" : "Please"} upload up to <b>
                    2 photos
                </b> for your relationship profile.<br/> Each photo can be no larger than <b>2 MB</b>.
            </div>

            <div>
                <Button type="primary">
                    <input
                        style={{display: "none"}}
                        type="file"
                        name="photo0"
                        id="photo0"
                        accept="image/png, image/jpeg"
                        onChange={this.onUploadPhoto}/>
                Choose File
                </Button>
                <span style={{marginLeft: THEME.marginXSmall}}>
                    {"Photo 1: " + (this.state["photo0"] || noFileChosen)}
                </span>
            </div>

            {/* {this.state["photo0"] && */}
              <div>
                  <Button type="primary">
                      <input
                          style={{display: "none"}}
                          type="file"
                          name="photo1"
                          id="photo1"
                          accept="image/png, image/jpeg"
                          onChange={this.onUploadPhoto}/>
                  Choose File
                  </Button>
                  <span style={{marginLeft: THEME.marginXSmall}}>
                      {"Photo 2: " + (this.state["photo1"] || noFileChosen)}
                  </span>
              </div>
            {/* } */}

            {this.displayAlert()}

            <div className="row-apart w-fill" id="dating-action-btns">
                {this.props.update ?
                    <div /> :
                    <div className="btn" id="dating-action-go-back"
                        onClick={inProgress ? null : this.goBack}>
                        GO BACK
                    </div>
                }

                <Button type="secondary" onClick={inProgress ? null : this.onSubmit}>
                    {this.props.update ? "SAVE CHANGES" : "CONTINUE"}
                </Button>
            </div>
        </>;

        const content = <>
          <div style={{backgroundColor: "white", color: "black"}}>
              <SettingsHeader title="Relationship Questions"/>
              <div
                  style={{
                      margin: `${THEME.marginSmall} ${THEME.marginMedium}`,
                      display: "grid",
                      gridAutoFlow: "row",
                      rowGap: THEME.marginXSmall}}>
                  {innerContent}
              </div>
          </div>
        </>;

        const html = this.state.gotAllQuestions && content;
        return html;
    }
}

DatingQuestions.defaultProps = {
    updateState: () => { },
    reloadPage: false,
    settings: false,
};

export default DatingQuestions;
