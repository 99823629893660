import React from "react";

import {Collapse, DropdownItem, Dropdown, DropdownToggle, DropdownMenu} from "reactstrap";
import {ChevronDown, Plus} from "react-feather";
import {Link} from "react-router-dom";

import messagingRead from "../../images/navigation/messaging/read/mobile.png";

import "../../styles/MessengerDropdown.css";

class MessengerDropdown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            dropdownOpen: false
        };
    }

    static getDerivedStateFromProps(props) {
        return {
            isOpen: props.isOpen
        };
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        if (this.props.menu) {
            return (
                <React.Fragment>
                    <div className="nav-menu-item header messaging"
                        onClick={() => this.props.toggle()}
                    >
                        {this.state.isOpen ?
                            <ChevronDown className="lrg-icon mr-2"/>  :
                            <Plus className="lrg-icon mr-2"/>
                        } CALL / CHAT
                    </div>
                    <Collapse isOpen={this.state.isOpen}>
                        <div className="col sub-group">
                            <Link to="/chat/conversation"
                                className="nav-menu-item account-type"
                                onClick={() => this.props.closeMenu()}
                            >
                                CONVERSATION
                            </Link>
                            {this.props.isDatingAccount &&
                            <Link to="/chat/relationship"
                                className="nav-menu-item account-type"
                                onClick={() => this.props.closeMenu()}
                            >
                                RELATIONSHIP
                            </Link>
                            }
                            <Link to="/chat/collaborate"
                                className="nav-menu-item account-type"
                                onClick={() => this.props.closeMenu()}
                            >
                                COLLABORATE
                            </Link>
                        </div>
                    </Collapse>
                </React.Fragment>
            );
        }

        const readImage = messagingRead;
        const { closeMenu } = this.props;

        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggle()} className="nav-dropdown">
                <DropdownToggle >
                    <img src={readImage} style={{width: "25px", height: "25px"}} alt="messenger-dropdown-icon"/>
                </DropdownToggle>
                <DropdownMenu className="header-menus primary">
                    <Link to="/chat/conversation" onClick={() => closeMenu()}>
                        <DropdownItem >Conversation</DropdownItem>
                    </Link>
                    <DropdownItem divider />
                    {this.props.isDatingAccount &&
                    <React.Fragment>
                        <Link to="/chat/relationship" onClick={() => closeMenu()}>
                            <DropdownItem >Relationship</DropdownItem>
                        </Link>
                        <DropdownItem divider />
                    </React.Fragment>
                    }
                    <Link to="/chat/collaborate" onClick={() => closeMenu()}>
                        <DropdownItem >Collaborate</DropdownItem>
                    </Link>
                </DropdownMenu>
            </Dropdown>
        );

    }
}

MessengerDropdown.defaultProps = {
    closeMenu: () => {},
    isOpen: false,
};

export default MessengerDropdown;
