import React, {Component} from "react";

import checked from "../../images/check/checked.png";
import unchecked from "../../images/check/unchecked.png";

import "../../styles/AnswerRadioQuestions.css";

import { THEME } from "../NewSettingsComponents";

class AnswerRadioQuestions extends Component {

    constructor(props) {
        super(props);

        const special = !(window.innerHeight >= 700 && window.innerWidth >= 700) && this.props.special;

        this.state = {
            special,
            dropped: !special,

            id: this.props.question._id,
            question: this.props.question.question,

            selected: this.props.initState,
            optionOne: this.props.question.optionOne,
            optionTwo: this.props.question.optionTwo,
        };
    }

    onselect = (option) => {
        this.setState({selected: option}, () => {
            this.props.updateRadioQuestion(this.state.id, this.state.selected);
        });
    };

    render() {
        const { dropped, optionOne, optionTwo, selected, special, question } = this.state;

        return (
            <div className="radio-question-wrapper">
                {special ?
                    <div className="text-center">
                        <div className="event-card radio-question-middle-wrapper"
                            onClick={() => {this.setState({dropped: !dropped});}}
                        >
                            <div>{question}</div>
                        </div>
                    </div> :
                    <p style={{marginBottom: THEME.marginXSmall, width: "100%"}}>
                        {question}
                    </p>
                }

                {dropped &&
                    <React.Fragment>
                        <div className="mc-answer filter-check"
                            onClick={() => {this.onselect(optionOne);}}
                        >
                            <img src={selected === optionOne ? checked : unchecked}
                                alt="radio-check-box"
                            />
                            <div className="no-select">{optionOne}</div>
                        </div>
                        <div className="mc-answer filter-check"
                            onClick={() => {this.onselect(optionTwo);}}
                        >
                            <img src={selected === optionTwo ? checked : unchecked}
                                alt="radio-check-box"
                            />
                            <div className="no-select">{optionTwo}</div>
                        </div>
                    </React.Fragment>
                }
            </div>
        );
    }
}

AnswerRadioQuestions.defaultProps = {
    initState: "",
    updateRadioQuestion: () => {},
};

export default AnswerRadioQuestions;
