import React, {Component} from "react";

import "../../styles/AnswerTextQuestions.css";

import {isMobile} from "../../utils/utils";

import { THEME, TextArea } from "../NewSettingsComponents";

class AnswerTextQuestions extends Component {

    constructor(props) {
        super(props);

        const special = !(window.innerHeight >= 700 && window.innerWidth >= 700) && this.props.special;

        this.state = {
            special,
            dropped: !special,

            onMobile: isMobile(),

            id: this.props.question._id,
            question: this.props.question.question,

            response: this.props.initState,
            minChars: this.props.question.minimumChars,
        };
        this.setMobile = this.setMobile.bind(this);
    }

    setMobile() {
        console.log("Screen resize detected");

        this.setState({
            onMobile: isMobile(),
        });
    }

    componentDidMount() {
        window.addEventListener("resize", this.setMobile);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setMobile);
    }

    onChange = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;

        this.setState(state, () => {
            this.props.updateTextQuestion(this.state.id, this.state.response);
        });
    };

    render() {
        const { dropped, question, response, special, onMobile } = this.state;

        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                {special ?
                    <div className="text-center">
                        <div className="event-card text-question-middle-wrapper"
                            onClick={() => {this.setState({dropped: !dropped});}}
                        >
                            <div>{question}</div>
                        </div>
                    </div> :
                    <p style={{marginBottom: THEME.marginXSmall, width: "100%"}}>
                        {question}
                    </p>
                }

                {dropped &&
                    <TextArea placeholder="Write your response here..."
                        name="response" value={response} onChange={this.onChange}
                        style={{width: onMobile ? "100%" : "100%", resize: "none", color: THEME.colorAccent}} >
                    </TextArea>
                }
            </div>
        );
    }
}

AnswerTextQuestions.defaultProps = {
    initState: "",
    updateTextQuestion: () => {},
};

export default AnswerTextQuestions;
