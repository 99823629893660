import React, { Component } from "react";

import AnswerTextQuestions from "./AnswerTextQuestions";
import AnswerRadioQuestions from "./AnswerRadioQuestions";
import AnswerMultipleChoiceQuestions from "./AnswerMultipleChoiceQuestions";

import {isMobile} from "../../utils/utils";

import "../../styles/CategoryQuestions.css";

import { THEME, ExpandableDropdown } from "../NewSettingsComponents";

class CategoryQuestions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropped: {},
            onMobile: isMobile(),
        };
        this.setMobile = this.setMobile.bind(this);
    }

    setMobile() {
        console.log("Screen resize detected");

        this.setState({
            onMobile: isMobile(),
        });
    }

    componentDidMount() {
        window.addEventListener("resize", this.setMobile);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setMobile);
    }

    toggle = (cat) => () => {
        const dropped = this.state.dropped;
        dropped[cat] = !dropped[cat];
        this.setState({ dropped });
    };

    renderAllCategories = () => {
        const allCats = this.props.categoryQuestions.questionTypes;
        const { onMobile } = this.state;
        const cats = [];

        allCats.forEach((cat, index) => {

            cats.push(
                <ExpandableDropdown title={cat} key={cat}>
                    <div
                        style={{
                            display: "grid",
                            gridAutoFlow: "row",
                            rowGap: THEME.marginSmall,
                            padding: onMobile ? THEME.marginXSmall : THEME.marginSmall}}>
                        {this.renderCategoryQuestions(cat)}
                    </div>
                </ExpandableDropdown>
            );
        });

        return cats;
    };

    renderCategoryQuestions = (cat) => {
        let response;
        let initState;

        const tmp = [];
        const catQuestions = this.props.categoryQuestions[cat];

        if (catQuestions) {
            if (catQuestions.radio) {
                for (let i = 0; i < catQuestions.radio.length; i++) {
                    response = this.props.questionResponses[catQuestions.radio[i]._id];
                    initState = response ? response.selected : "";

                    tmp.push({question: <AnswerRadioQuestions
                        question={catQuestions.radio[i]}
                        updateRadioQuestion={this.props.updateRadioQuestion}
                        initState={initState}
                        key={catQuestions.radio[i]._id}
                    />, priority: catQuestions.radio[i].priority
                    });
                }
            }

            if (catQuestions.text) {
                for (let i = 0; i < catQuestions.text.length; i++) {
                    response = this.props.questionResponses[catQuestions.text[i]._id];
                    initState = response ? response.response : "";

                    tmp.push({question: <AnswerTextQuestions
                        question={catQuestions.text[i]}
                        updateTextQuestion={this.props.updateTextQuestion}
                        initState={initState}
                        key={catQuestions.text[i]._id}
                    />, priority: catQuestions.text[i].priority
                    });
                }
            }

            if (catQuestions.multipleChoice) {
                for (let i = 0; i < catQuestions.multipleChoice.length; i++) {
                    response = this.props.questionResponses[catQuestions.multipleChoice[i]._id];
                    initState = response ? response.selected : [];

                    tmp.push({question: <AnswerMultipleChoiceQuestions
                        question={catQuestions.multipleChoice[i]}
                        updateMultipleChoiceQuestion={this.props.updateMultipleChoiceQuestion}
                        initState={initState}
                        key={catQuestions.multipleChoice[i]._id}
                    />, priority: catQuestions.multipleChoice[i].priority
                    });
                }
            }
        }

        tmp.sort((a, b) => {
            return b.priority - a.priority;
        });

        const questions = [];
        tmp.forEach(q => {
            questions.push(q.question);
        });

        return questions;
    };

    render() {
        return this.renderAllCategories();
    }
}

export default CategoryQuestions;
