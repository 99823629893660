import React, {Component} from "react";
import {get} from "lodash";

import { THEME, Button, SettingsHeader } from "../NewSettingsComponents";

import axios from "axios";

import AnswerTextQuestions from "./AnswerTextQuestions";
import AnswerRadioQuestions from "./AnswerRadioQuestions";
import AnswerMultipleChoiceQuestions from "./AnswerMultipleChoiceQuestions";

import rotating from "../../images/rotating.png";

import "../../styles/CollabQuestions.css";

class CollabQuestions extends Component {
    static CACHE_FIELDS = ["questionResponses"];
    static CACHE_KEY = "collabQuestions";

    constructor(props) {
        super(props);

        const cachedState = this.loadCache();

        let questionResponses = get(props, ["currentUser", "questionResponses"], []);
        questionResponses = this.formatResponses(questionResponses);

        if (get(cachedState, "questionResponses")) {
            questionResponses = {
                ...questionResponses,
                ...cachedState.questionResponses
            };

            delete cachedState.questionResponses;
        }

        this.state = {
            collabMusic: true,

            msg: "",
            infoColor: false,
            inProgress: false,

            questionIds: [],
            collabQuestions: {},

            gotAllQuestions: false,
            collabQuestionsLoaded: false,

            questionResponses,
            ...cachedState
        };

        this.updateMultipleChoiceQuestion = this.updateMultipleChoiceQuestion.bind(this);
        this.updateTextQuestion = this.updateTextQuestion.bind(this);
        this.updateRadioQuestion = this.updateRadioQuestion.bind(this);
    }

    formatResponses = (responses) => {
        const formattedResponses = {};

        responses.forEach((response) => {
            const formattedResponse = {
                question: response.question
            };

            let question;

            if (response.radioQuestionResponse) {
                question = response.radioQuestionResponse;

                formattedResponse["selected"] = question.selected;
                formattedResponse["type"] = "radio";
            } else if (response.textQuestionResponse) {
                question = response.textQuestionResponse;

                formattedResponse["response"] = question.text;
                formattedResponse["type"] = "text";
            } else if (response.multipleChoiceQuestionResponse) {
                question = response.multipleChoiceQuestionResponse;

                formattedResponse["selected"] = question.answers;
                formattedResponse["type"] = "multipleChoice";
            }

            if (question) {
                formattedResponse["id"] = question.questionId;
                formattedResponses[question.questionId] = formattedResponse;
            }
        });

        return formattedResponses;
    };

    componentDidMount() {
        axios.get("/api/questions?type=collaboration")
            .then((result) => {
                this.setState({
                    collabQuestions: result.data.questions,
                    collabQuestionsLoaded: true
                });
            })

            .catch((err) => {
                console.error(err);

                this.setState({
                    infoColor: false,
                    inProgress: false,
                    msg: "An error occurred loading the questions."
                });
            })
            .then(() => this.initializeQuestionResponses());
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.collabQuestions[0] !== this.state.collabQuestions[0] && this.state.collabQuestionsLoaded) {
            this.initializeQuestionResponses();
        }
    }

    componentWillUnmount() {
        // When we're creating the profile (i.e. not updating),
        // we already cache after all API calls have succeeded.
        if (this.props.update) {
            this.cacheState();
        }
    }

    initializeQuestionResponses = () => {
        const questionResponses = this.state.questionResponses;
        const questions = this.state.collabQuestions;
        const questionIds = [];

        if (questions) {
            questions.multipleChoice
                .forEach(questionObject => {
                    const {_id: id, question: questionText} = questionObject;
                    questionIds.push(id);

                    questionResponses[id] = {
                        id,
                        selected: get(questionResponses, [id, "selected"], []),
                        friend: false,
                        type: "multipleChoice",
                        question: questionText,
                    };
                });

            questions.text
                .forEach(questionObject => {
                    const {_id: id, question: questionText, minimumChars} = questionObject;
                    questionIds.push(id);

                    questionResponses[id] = {
                        id,
                        response: get(questionResponses, [id, "response"], ""),
                        minChars: minimumChars,
                        friend: false,
                        type: "text",
                        question: questionText
                    };
                });

            questions.radio
                .forEach(questionObject => {
                    const {_id: id, question: questionText} = questionObject;
                    questionIds.push(id);

                    questionResponses[id] = {
                        id,
                        selected: get(questionResponses,[id, "selected"], ""),
                        friend: true,
                        type: "radio",
                        question: questionText
                    };
                });
        }

        this.setState({
            questionIds,
            questionResponses,
            gotAllQuestions: true
        });
    };

    updateMultipleChoiceQuestion = (id, selections) => {
        const newQuestions = Object.assign({}, this.state.questionResponses);
        newQuestions[id]["selected"] = selections;

        this.setState({questionResponses: newQuestions}, this.cacheState);
    };

    updateTextQuestion = (id, answer) => {
        const newQuestions = Object.assign({}, this.state.questionResponses);
        newQuestions[id]["response"] = answer;

        this.setState({questionResponses: newQuestions}, this.cacheState);
    };

    updateRadioQuestion = (id, selection) => {
        const newQuestions = Object.assign({}, this.state.questionResponses);
        newQuestions[id]["selected"] = selection;

        this.setState({questionResponses: newQuestions}, this.cacheState);
    };

    selectOnChange = (e) => {
        const name = e.target.name;
        const options = e.target.options;

        let val = "";

        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                val = options[i].value;
                break;
            }
        }

        const state = this.state;
        state[name] = val;

        this.setState(state, this.cacheState);
    };

    onChange = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;

        this.setState(state, this.cacheState);
    };

    checkOnClick = (e) => {
        const name = e.target.name;
        this.setState({[name]: !this.state[name]}, this.cacheState);
    };

    goBack = () => {
        this.cacheState();
        this.props.prevState();
    };

    loadCache = () => {
        let cachedState = JSON.parse(window.localStorage.getItem(this.props.currentUser.email)) || {};
        cachedState = cachedState[CollabQuestions.CACHE_KEY];

        if (cachedState) {
            this.props.updateState({
                collabQuestions: cachedState
            });
        }

        return cachedState;
    };

    cacheState = () => {
        const cache = {};

        for (const key of CollabQuestions.CACHE_FIELDS) {
            cache[key] = this.state[key];
        }

        const cachedState = JSON.parse(window.localStorage.getItem(this.props.currentUser.email)) || {};

        cachedState[CollabQuestions.CACHE_KEY] = cache;
        window.localStorage.setItem(this.props.currentUser.email, JSON.stringify(cachedState));
    };

    onSubmit = () => {
        const qRes = this.state.questionResponses;

        for (const id in qRes) {
            if (!this.state.questionIds.includes(id)) {
                continue;
            }

            const question = qRes[id];
            const type = question.type;

            if (type === "multipleChoice") {
                if (question.selected.length === 0) {
                    const msg = "Please reply to all of the non-optional questions.";
                    return this.setState({msg});
                }
            } else if (type === "text") {
                if (question.response.length > 0 && question.response.length < question.minChars) {
                    const questionStart = question.question.split("?")[0] + "?";
                    const diff = question.minChars - question.response.length;

                    const msg = `Make sure your response for "${questionStart}" meets the minimum character ` +
                        `requirement. Add ${diff} more character(s).`;
                    return this.setState({msg});
                }
            } else if (type === "radio") {
                if (question.selected === "") {
                    const msg = "Please reply to all of the non-optional questions.";
                    return this.setState({msg});
                }
            }
        }

        if (!this.props.update) {
            this.setState({
                inProgress: true
            }, () => {
                this.props.updateState({
                    collabQuestions: this.state
                }, () => {
                    this.cacheState();
                    this.props.nextState();
                });
            });
        } else {
            const formData = new FormData();
            formData.append("data", JSON.stringify(this.state));

            this.setState({
                infoColor: true, inProgress: true,
                msg: "Updating Collaborate profile..."
            }, () => {
                axios.post("/api/users/update-answers", formData).then(() => {
                    this.cacheState();

                    if (this.props.reloadPage) {
                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                        window.location.reload();
                    } else {
                        this.setState({
                            infoColor: true, inProgress: false,
                            msg: "Collaborate profile successfully updated!"
                        });
                    }
                }).catch(() => {
                    this.setState({
                        infoColor: false, inProgress: false,
                        msg: "Something went wrong updating. Please try again."
                    });
                });
            });
        }
    };

    onDismiss = () => {
        this.setState({msg: "", infoColor: false, inProgress: false});
    };

    displayAlert = () => {
        const { msg, infoColor, inProgress } = this.state;

        if (msg !== "") {
            return (
                <div id="collab-content-alert-wrapper" onClick={this.onDismiss}
                    className={`dismiss-alert ${infoColor ? "info" : ""}`}
                >
                    {inProgress ?
                        <img src={rotating} className="rotating-icon" alt="rotating-icon" /> :
                        <div id="collab-content-alert-icon">!</div>
                    }
                    <div id="collab-content-alert-message">{msg}</div>
                </div>
            );
        }
    };

    getQuestions = () => {
        let response;
        let initState;

        const tmp = [];
        const questions = this.state.collabQuestions;
        const responses = this.state.questionResponses;

        const {multipleChoice: multiQuestions, radio: radioQuestions, text: textQuestions} = questions;

        if (multiQuestions) {
            multiQuestions.forEach((question, i) => {
                response = responses[question._id];
                initState = response ? response.selected : [];

                tmp.push({
                    question: <AnswerMultipleChoiceQuestions
                        // special
                        key={"m" + i}
                        question={question}
                        updateMultipleChoiceQuestion={this.updateMultipleChoiceQuestion}
                        initState={initState}/>,
                    priority: question.priority
                });
            });
        }

        if (radioQuestions) {
            radioQuestions.forEach((question, i) => {
                response = responses[question._id];
                initState = response ? response.selected : "";

                tmp.push({
                    question: <AnswerRadioQuestions
                        // special
                        key={"r" + i}
                        question={question}
                        updateRadioQuestion={this.updateRadioQuestion}
                        initState={initState}/>,
                    priority: question.priority
                });
            });
        }

        if (textQuestions) {
            textQuestions.forEach((question, i) => {
                response = responses[question._id];
                initState = response ? response.response : "";

                tmp.push({
                    question: <AnswerTextQuestions
                        // special
                        key={"t" + i}
                        question={question}
                        updateTextQuestion={this.updateTextQuestion}
                        initState={initState}/>,
                    priority: question.priority
                });
            });
        }

        tmp.sort((a, b) => {
            return b.priority - a.priority;
        });

        return tmp.map((q) => (q.question));
    };

    render() {
        let className = "primary-wrapper mt-40 extended col center-self primary mb-20 questions";
        className = `${className} ${this.props.settings ? "settings" : ""}`;

        const { inProgress } = this.state;

        const innerContent = <>
            {this.getQuestions()}
            {this.displayAlert()}

            <div style={{display: "flex", justifyContent: "space-between"}}>
                {this.props.update ?
                    <div /> :
                    <div className="btn" id="collab-action-go-back" onClick={inProgress ? null : this.goBack}>
                        GO BACK
                    </div>
                }

                <Button type="secondary" onClick={inProgress ? null : this.onSubmit}>
                    {this.props.update ? "SAVE CHANGES" : "CONTINUE"}
                </Button>
            </div>
        </>;

        const content = <>
          <div style={{backgroundColor: "white", color: "black"}}>
              <SettingsHeader title="Collaborate Questions"/>
              <div
                  style={{
                      margin: `${THEME.marginSmall} ${THEME.marginMedium}`,
                      display: "grid",
                      gridAutoFlow: "row",
                      rowGap: THEME.marginXSmall}}>
                  {innerContent}
              </div>
          </div>
        </>;

        const html = this.state.gotAllQuestions && content;
        return html;
    }
}

CollabQuestions.defaultProps = {
    updateState: () => {},
    reloadPage: false,
    settings: false,
};

export default CollabQuestions;
