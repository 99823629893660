import React from "react";

import mobilePinned from "../images/pinned/mobile.png";
import dropdownClosed from "../images/dropdown/closed.png";
import dropdownOpen from "../images/dropdown/open.png";

const THEME = {
    marginMedium: "70px",
    marginSmall: "40px",
    marginXSmall: "18px",
    colorSecondary: "#F17432",
    colorPrimary: "#9FC6CC",
    colorAccent: "#1E2738",
    colorGray: "#424242",
    colorLightGray: "#EEF3F8",
    mobileBreakpoint: 500,
};

const Button = props => {
    const style = {
        border: "none",
        borderRadius: "0px",
        padding: "10px 25px",
        fontWeight: "bold",
        fontSize: "16px",
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: "min-content",
        whiteSpace: "nowrap",
        margin: "0px",
        userSelect: "none"};
    if (props.type === "primary") {
        style.color = "white";
        style.backgroundColor = THEME.colorPrimary;
    } else if (props.type === "secondary") {
        style.color = "white";
        style.backgroundColor = THEME.colorSecondary;
    } else if (props.type === "white") {
        style.color = THEME.colorAccent;
        style.backgroundColor = "white";
    } else if (props.type === "accent") {
        style.color = THEME.colorPrimary;
        style.backgroundColor = THEME.colorAccent;
    }
    if (props.size === "small") {
        style.padding = "4px 16px";
        style.fontSize = "12px";
    }
    if (props.disabled) {
        style.cursor = "not-allowed";
        style.color = "#ccc";
    }
    if (props.fullHeight) {
        style.height = "100%";
    }
    if (props.fullWidth) {
        style.width = "100%";
    }

    const onClick = e => {
        if (!props.disabled && props.onClick) {
            props.onClick(e);
        }
    };

    const button = <>
    <label style={{...style, ...props.style}} onClick={onClick}>
        {props.children}
    </label>
  </>;
    return button;
};

const TextArea = props => {
    const textArea = <>
    <textarea {...props}
        style={{
            height: "180px",
            overflowY: "auto",
            letterSpacing: "1px",
            lineHeight: "20px",
            border: "1px solid",
            borderColor: THEME.colorAccent,
            backgroundColor: "white",
            fontSize: "16px",
            padding: "10px",
            color: THEME.colorGray,
            ...props.style}} />
  </>;
    return textArea;
};

const Input = props => {
    const input = <>
    <input {...props}
        style={{
            height: "40px",
            padding: "10px",
            border: "1px solid",
            borderColor: THEME.colorAccent,
            width: "100%",
            color: THEME.colorAccent,
            ...props.style}} />
  </>;
    return input;
};

const Subheader = props => {
    const header = <>
    <h4 style={{margin: "0px", fontSize: "16px", letterSpacing: "2px", color: THEME.colorPrimary}}>
        {props.children}
    </h4>
  </>;
    return header;
};

const SettingsHeader = props => {
    const header = <>
    <div style={{textAlign: "center", backgroundColor: THEME.colorPrimary, padding: "20px"}}>
        <h1 style={{fontWeight: "bold", textAlign: "center", fontSize: "40px", margin: "15px 0px"}}>
            {props.title}
        </h1>
    </div>
  </>;
    return header;
};

const Select = props => {
    const width = props.fullWidth ? "100%" : "unset";
    const select = <>
    <div
        style={{
            height: "40px",
            border: "1px solid #000",
            display: "grid",
            gridTemplateColumns: "1fr min-content",
            alignItems: "center",
            justifyItems: "start",
            padding: "0px 0px",
            backgroundColor: "white",
            width,
            ...props.outerStyle}}>
        <select {...props}
            style={{
                WebkitAppearance: "none",
                MozAppearance: "none",
                border: "none",
                paddingLeft: "5px",
                width: "100%",
                minWidth: "100px",
                backgroundColor: "white",
                ...props.style}}>
            {props.defaultValue && <option value={""} key={"default"} hidden>{props.defaultValue}</option>}
            {props.children}
        </select>
        <svg focusable="false" viewBox="0 0 24 24" width="24" height="24" aria-hidden="true">
            <path d="M7 10l5 5 5-5z"/>
        </svg>
    </div>
  </>;
    return select;
};

const RadioButton = props => {
    const isChecked = props.isChecked;
    const outerSize = "18px";
    const innerSize = "8px";
    const innerVisibility = isChecked ? "visible" : "hidden";
    const html = <>
    <div
        style={{
            height: outerSize,
            width: outerSize,
            minHeight: outerSize,
            minWidth: outerSize,
            borderRadius: `calc(${outerSize} / 2)`,
            backgroundColor: THEME.colorAccent,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            ...props.style}}
        onClick={props.onClick}>
        <div
            style={{
                height: innerSize,
                width: innerSize,
                borderRadius: `calc(${outerSize} / 2)`,
                backgroundColor: "white",
                visibility: innerVisibility}}>
        </div>
    </div>
  </>;

    return html;
};

class ExpandableDropdown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            collapse: props.collapse || true,
            maxHeight: undefined,
        };

        this.containerRef = React.createRef();
        this.transitionLength = 300;
    }

    updateMaxHeight() {
        const elementHeight = this.containerRef.current.getBoundingClientRect().height;
        if (this.state.maxHeight !== elementHeight) {
            this.setState({maxHeight: elementHeight});
        }
    }

    componentDidMount() {
        this.updateMaxHeight();
    }

    componentDidUpdate() {
        this.updateMaxHeight();
    }

    render() {
        const { collapse, maxHeight } = this.state;
        const { pinned, title, children } = this.props;

        const pinnedIcon = <>
      <img
          style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "15px",
              width: "auto"}}
          src={mobilePinned} alt="pinned" />
    </>;

        const toggle = () => {
            this.setState({collapse: !collapse});
        };

        const transition = `max-height ${this.transitionLength}ms ease-out`;

        const html = <>
      <div
          style={{
              wordBreak: "break-word",
              color: THEME.colorAccent,
              width: "100%"}}>
          <div onClick={toggle}
              style={{
                  backgroundColor: THEME.colorPrimary,
                  padding: "15px",
                  cursor: "pointer",
                  userSelect: "none",
                  display: "flex",
                  alignItems: "center",
                  position: "relative"}}>
              {pinned && pinnedIcon}
              <span
                  style={{
                      color: this.props.textColor || "white",
                      fontSize: "18px",
                      fontWeight: "700",
                      letterSpacing: "1px",
                      flexGrow: 1,
                      textAlign: "center"}}>
                  {title}
              </span>
              <img className="dropdown-arrow"
                  src={collapse ? dropdownClosed : dropdownOpen}
                  alt="dropdown-arrow"
              />
          </div>
          <div
              style={{
                  maxHeight: collapse ? 0 : (100 + maxHeight + "px"),
                  border: "2px solid",
                  borderColor: THEME.colorPrimary,
                  marginTop: "-4px",
                  overflow: "hidden",
                  transition}}>
              <div ref={this.containerRef}>
                  {children}
              </div>
          </div>
      </div>
    </>;

        return html;
    }
}

export {THEME, Button, Subheader, SettingsHeader, Select, TextArea, Input, RadioButton, ExpandableDropdown};
