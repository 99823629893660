import axios from "axios";
import moment from "moment";

const CHAT_KEY = "morphkey-easyrtc-chat-key";
const SCAVENGER_HUNT_AREAS = ["Los Angeles"];

const isMediaSupported = () => {
    return !!(navigator && navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
};

const isPermissionGranted = async () => {
    try {
        const constraints = {audio: true, video: true};

        await navigator.mediaDevices.getUserMedia(constraints);

        return true;
    } catch {
        return false;
    }
};

const isHighResolution = () => (
    window.devicePixelRatio > 1
);

const isMobile = () => (
    window.innerWidth <= 896
);

function isLoggedIn(cb) {
    if (cb) {
        axios.get("/api/checklogin", {withCredentials: true})
            .then((result) => {
                cb(result.data.loggedIn, result.data.user);
            })
            .catch((error) => {
                console.log(error);
                cb(false);
            });
    }
}

function getServices(cb) {
    if (cb) {
        axios.get("/api/users/services")
            .then((res) => {
                cb(res);
            }).catch((error) => {
                cb(null);
            });
    }
}

const getAge = (birthday) => (
    moment().diff(moment(birthday), "years")
);

const getGMapLink = (address) => {
    const cleaned = address.replace(/[ \n]+/g, " ");
    return `https://www.google.com/maps/search/?api=1&query=${encodeURI(cleaned)}`;
};

const loadScript = (src) => {
    const script = window.document.createElement("script");
    script.src = src;
    script.async = false;
    script.defer = false;
    script.type = "text/javascript";
    window.document.head.appendChild(script);
    return new Promise((res, rej) => {
        script.onload = function() {
            res();
        };
        script.onerror = function () {
            rej();
        };
    });
};

export {
    CHAT_KEY,
    SCAVENGER_HUNT_AREAS,
    isMediaSupported,

    getAge,
    getGMapLink,
    getServices,

    isMobile,
    isLoggedIn,
    isHighResolution,
    isPermissionGranted,
    loadScript
};
