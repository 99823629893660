import React, { Component } from "react";
import { FormGroup } from "reactstrap";

import nochecked from "../../images/check/disabled.png";
import checked from "../../images/check/checked.png";
import unchecked from "../../images/check/unchecked.png";

import "../../styles/AnswerMultipleChoiceQuestions.css";

import { THEME } from "../NewSettingsComponents";

class AnswerMultipleChoiceQuestions extends Component {

    constructor(props) {
        super(props);

        const special = !(window.innerHeight >= 700 && window.innerWidth >= 700) && this.props.special;

        this.state = {
            special,
            dropped: !special,

            id: this.props.question._id,
            question: this.props.question.question,

            selected: this.props.initState,
            options: this.props.question.options,
            maxChoose: this.props.question.maxChoose,
        };
    }

    onChange = (e, ind) => {
        const newSelected = [];
        // const optionName = this.state.options[parseInt(ind)];

        // backwards capability code: transforming all previous strings to indices
        for (let i = 0; i < this.state.selected.length; i++) {
            if (typeof this.state.selected[i] === "string") {
                const optionIndx = this.state.options.indexOf(this.state.selected[i]);
                if (optionIndx !== -1) {
                    newSelected.push(optionIndx);
                }
            } else if (typeof this.state.selected[i] === "number") {
                if (this.state.selected[i] < this.state.options.length &&
                    -1 < this.state.selected[i]) {
                    const optionIndx = this.state.selected[i];
                    newSelected.push(optionIndx);
                }
            }
        }

        const optionId = parseInt(ind);
        const optionIdIndx = newSelected.indexOf(optionId);

        if (0 <= optionIdIndx && optionIdIndx < newSelected.length) {
            // Uncheck, remove the element.
            newSelected.splice(optionIdIndx, 1);
        } else {
            // Check.
            if (newSelected.length === this.state.maxChoose) {
                // Don't add it.
                return;
            } else {
                newSelected.push(optionId);
            }
        }

        // send out list of option indices instead of strings to save instead
        this.setState({ selected: newSelected }, () => {
            this.props.updateMultipleChoiceQuestion(this.state.id, newSelected);
        });
    };

    getOptions = () => {
        const tmp = [];
        this.state.options.forEach((option, i) => {
            const selected = this.state.selected.includes(i) || this.state.selected.includes(option);
            const disabled = this.state.selected.length === this.state.maxChoose && !selected;

            tmp.push(
                <div className="mc-answer filter-check"
                    key={i + ":" + this.state.id} id={i + ":" + this.state.id}
                    onClick={disabled ? null : (e) => { this.onChange(e, i); }}
                >
                    <img src={disabled ? nochecked : (selected ? checked : unchecked)} alt="mc-check-box" />
                    <div className="no-select">{option}</div>
                </div>
            );
        });

        return tmp;
    };

    render() {
        const { dropped, special, question } = this.state;

        return (
            <div className="mc-question-wrapper">
                {special ?
                    <div className="text-center">
                        <div className="event-card mc-question-middle-wrapper"
                            onClick={() => { this.setState({ dropped: !dropped }); }}
                        >
                            <div>{question}</div>
                        </div>
                    </div> :
                    <p style={{marginBottom: THEME.marginXSmall}}>
                        {question}
                    </p>
                }

                {dropped &&
                    <FormGroup style={{margin: 0}}>
                        <div className="multiple-answers">
                            {this.getOptions()}
                        </div>
                    </FormGroup>
                }
            </div>
        );
    }
}

AnswerMultipleChoiceQuestions.defaultProps = {
    initState: [],
    updateMultipleChoiceQuestion: () => { },
};

export default AnswerMultipleChoiceQuestions;
