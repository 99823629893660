import React from "react";

import {Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {ChevronDown, Plus, Settings} from "react-feather";
import {Link} from "react-router-dom";

import settings from "../../images/navigation/settings/mobile.png";
import "../../styles/SettingsDropdown.css";

class SettingsDropdown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dropdownOpen: false,
            isOpen: false
        };
    }

    static getDerivedStateFromProps(props) {
        return {
            isOpen: props.isOpen
        };
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        if (this.props.menu) {
            return (
                <React.Fragment>
                    <div className="nav-menu-item" onClick={() => this.props.toggle()}>
                        {this.state.isOpen ?
                            <ChevronDown className="lrg-icon mr-2"/>  :
                            <Plus className="lrg-icon mr-2"/>
                        } SETTINGS
                    </div>
                    <Collapse isOpen={this.state.isOpen}>
                        <div className="col sub-group">

                            <Link to="/settings" className="nav-menu-item">
                                <Settings className="mr-2"/>Settings
                            </Link>


                            <div className="nav-menu-item"
                                onClick={this.props.logout}>Logout
                            </div>
                        </div>

                    </Collapse>
                </React.Fragment>
            );
        }

        const settingsImg = settings;

        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggle()} className="nav-dropdown">
                <DropdownToggle>
                    <img src={settingsImg} style={{width: "25px", height: "25px"}} alt="messenger-dropdown-icon"/>
                </DropdownToggle>
                <DropdownMenu className="header-menus primary" end onClick={this.props.action}>
                    <Link to="/settings" onClick={() => this.props.closeMenu()}>
                        <DropdownItem >Settings</DropdownItem>
                    </Link>
                    <DropdownItem divider />

                    <DropdownItem onClick={this.props.logout}>Logout
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

SettingsDropdown.defaultProps = {
    action: () => {},
    closeMenu: () => {}
};

export default SettingsDropdown;
