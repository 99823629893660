import React, { Component } from "react";
import { Link } from "react-router-dom";

import {isLoggedIn} from "../utils/utils";

import "../styles/Footer.css";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false
        };
    }

    componentDidMount() {
        isLoggedIn(newVal => this.setState({loggedIn: newVal}));
    }

    render() {
        const FooterContent = (props) => (
            <React.Fragment>
                <div id="footer-links-container" className={props.klass}>
                    {
                        this.state.loggedIn &&
                        <Link to={"/what-is-morphkey"} className="not-rightmost-footer-link">What Is Morphkey</Link>
                    }
                    <Link to={"/terms-of-use"} className="not-rightmost-footer-link">Terms of Use</Link>
                    <Link to={"/privacy-policy"} className="not-rightmost-footer-link">Privacy Policy</Link>
                    <Link to={"/faq"}>FAQ and Contact Support</Link>
                </div>
                <div id="footer-copyright-container" className={props.klass}>
                    <span id="footer-copyright">© {new Date().getFullYear()} Morphkey. All rights reserved.</span>
                </div>
            </React.Fragment>
        );

        return (
            <div className={`footer ${this.props.acceptedCookies ? "" : "no-ok"}`}>
                <FooterContent klass="mobile-screen-only" />
                <div id="footer-content-desktop-container">
                    <FooterContent />
                </div>
            </div>
        );
    }
}

export default Footer;
