import React from "react";
import { withRouter } from 'react-router-dom';

import {X} from "react-feather";
import {Modal} from "reactstrap";

import axios from "axios";

import Schedule from "./Schedule";
import {isMobile} from "../../utils/utils";

import "../../styles/Modals.css";

class TalkRequest extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            onMobile: isMobile(),

            modal: false,
            modal2: false,
            modal2Msg: false,

            timeStrings: [],
            userInfo: this.props.userInfo,
            startingDate: new Date(Date.now())
        };

        this.setMobile = this.setMobile.bind(this);

        this.toggle = this.toggle.bind(this);
        this.toggle2 = this.toggle2.bind(this);

        this.sendRequest = this.sendRequest.bind(this);
        this.getFirstTime = this.getFirstTime.bind(this);
        this.shouldInclude = this.shouldInclude.bind(this);

        this.toggleTime = this.toggleTime.bind(this);
        this.isTimeChecked = this.isTimeChecked.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.setMobile);
    }

    setMobile() {
        console.log("Screen resize detected");

        this.setState({
            onMobile: isMobile(),
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setMobile);
    }

    toggle() {
        axios.get("/api/checksubscription").then((result) => {
            if (result.data.subscribed) {
                this.setState({
                    modal: !this.state.modal,
                    startingDate: new Date(Date.now()),
                });
            } else {
                this.props.history.push('/subscribe');
            }
        });
    }

    toggleTime(timeString) {
        const timeStrings = this.state.timeStrings.slice();

        if (timeStrings.includes(timeString)) {
            const index = timeStrings.indexOf(timeString);
            timeStrings.splice(index, 1);
        } else {
            timeStrings.push(timeString);
        }

        this.setState({timeStrings});
    }

    isTimeChecked(timeString) {
        return this.state.timeStrings.includes(timeString);
    }

    getSource() {
        let source = this.props.profileType;

        if (source === "friend") {
            source = "friendship";
        } else if (source === "dating") {
            source = "relationship";
        }

        return source;
    }

    toggle2() {
        this.setState({modal2: !this.state.modal2});
    }

    displayMsg = (msg) => {
        this.setState({modal2: true, modal2Msg: msg});
    };

    sendRequest() {
        const { timeStrings } = this.state;

        if (timeStrings.length === 0) {
            this.displayMsg("You need to select some times before clicking \"Send Request\".");
        } else {
            const data = {
                times: timeStrings,
                source: this.getSource(),
                startingTime: this.state.startingDate.getTime()
            };

            axios.post("/api/talkrequests/send/" + this.state.userInfo.uniqueId, data).then((result) => {
                if (result.data.success) {
                    this.displayMsg("Request sent!");
                } else {
                    if (result.data.message) {
                        this.displayMsg(`An error occurred in sending the talk request: ${result.data.message}`);
                    } else {
                        this.displayMsg("An error occurred in sending the talk request: Please try again");
                    }
                }

                this.toggle();
            });
        }
    }

    shouldInclude = (cutoff, proposed, before, after) => {
        return (before && proposed < cutoff) || (after && proposed >= cutoff);
    };

    getFirstTime = (day0) => {
        if (!this.state.modal) {
            return;
        }

        const firstTime = new Date(day0);
        const firstHours = firstTime.getHours();

        if (firstHours < 22) {
            firstTime.setHours(firstHours + 2);
        }

        return firstTime.getTime().toString();
    };

    formatModal2Msg() {
        const msg = this.state.modal2Msg;

        const requestError = "An error occurred in sending the talk request:";

        if (msg) {
            if (msg.startsWith(requestError)) {
                const rest = msg.substring(requestError.length + 1);

                return (
                    <React.Fragment>
                        <p style={{fontWeight: "bold", fontSize: "20px"}}>{requestError}</p>
                        <p style={{fontSize: "15px"}}>{rest}</p>
                    </React.Fragment>
                );
            }
        }

        return msg;
    }

    render() {
        const { onMobile } = this.state;

        return (
            <>
                <div className="btn" onClick={this.toggle}
                    style={{
                        border: "2px solid #9FC6CC",

                        paddingLeft: "20px",
                        paddingRight: "20px",

                        letterSpacing: "1px",
                        backgroundColor: "#1E2738",
                        ...this.props.style,
                    }}
                ><b>LET'S CHAT</b></div>

                <Modal id="talkSchedule" isOpen={this.state.modal2} style={{
                    height: "auto", minHeight: "120px", paddingBottom: "20px",
                    ...(onMobile ? {} : {width: "750px"}),
                    backgroundColor: "#9FC6CC",
                }}>
                    <X stroke="white" id="close-modal" size="25" onClick={this.toggle2} />
                    <h1 style={{
                        width: "100%",

                        paddingLeft: "15px",
                        paddingRight: "15px",
                    }}>{this.formatModal2Msg()}</h1>
                </Modal>

                <Modal id="talkSchedule" isOpen={this.state.modal}>
                    <div className="talk-schedule-header">
                        <h1>Choose Your <span style={{color: "#F17432"}}>Available Times</span></h1>
                    </div>
                    <Schedule
                        startingTime={this.state.startingDate}
                        shouldInclude={this.shouldInclude}
                        getFirstTime={this.getFirstTime}

                        toggleTime={this.toggleTime}
                        isTimeChecked={this.isTimeChecked}
                    />
                    <div className="request-options-talk-request" id="request-options">
                        <div className="btn" onClick={this.toggle}>
                            CANCEL
                        </div>
                        <div className="btn" onClick={this.sendRequest}>
                            SEND REQUEST
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(TalkRequest);
