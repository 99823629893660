import React from "react";
import ReactDOM from "react-dom";

import {BrowserRouter as Router} from "react-router-dom";
import {initAnalytics} from "./utils/analytics";

import "./index.css";
import App from "./App";

import registerServiceWorker from "./registerServiceWorker";

// initAnalytics();
// initGTM();

ReactDOM.render(
    <Router>
        <App />
    </Router>,
    document.getElementById("root")
);

registerServiceWorker();
