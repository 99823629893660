const AgeExtrema = {
    YOUNGEST_AGE: 18,
    OLDEST_AGE: 100,
};

const CatPriority = {
    Music: 1,
    "Food and Cooking": 2,
    "Architecture, Gardening, and Landscaping": 3,
    "Antiques and Vintage Goods": 4,
    "Art and Photography": 5,
    Film: 6,
    Books: 7,
    Videogames: 8,
    "Curiosity and Reflection": 9,
};

export {
    AgeExtrema,
    CatPriority,
};
