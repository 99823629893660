import React, {Component} from "react";
import axios from "axios";

import {Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {ChevronDown, Plus} from "react-feather";
import {Link} from "react-router-dom";

import notificationRead from "../images/navigation/notification/read/mobile.png";
import notificationUnread from "../images/navigation/notification/unread/mobile.png";

import "../styles/Notifications.css";

class Notifications extends Component {

    constructor(props) {
        super(props);

        this.state = {
            incomingChecker: undefined,
            incomingRequests: [],
            missedRequests: [],

            dropdownOpen: false,
            isOpen: false,
        };

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        const getIncoming = () => {
            axios.get("/api/users/hasCall").then((res) => {
                if (!res.data.success) {
                    // Get pending talks that were missed.
                    axios.get("/api/pendingtalks").then((result) => {
                        if (result.data.success) {
                            const updatedIncoming = result.data.incomingRequests;
                            const updatedOutgoing = result.data.outgoingRequests;

                            const currentDate = Date.now();
                            const pendingRequests = updatedIncoming.concat(updatedOutgoing);

                            // Missed requests are those that are over one hour overdue.
                            const missedRequests = pendingRequests.filter((pendingRequest) => {
                                const scheduledTime = Math.min.apply(null, pendingRequest.matchingTimes);
                                return currentDate - scheduledTime > 60 * 60 * 1000;
                            });

                            this.setState({
                                missedRequests,
                            }, () => {
                                if (missedRequests.length === 0) {
                                    // Get active talk requests with no response.
                                    axios.get("/api/talkrequests").then((result) => {
                                        if (result.data.success) {
                                            this.setState({incomingRequests: result.data.pendingRequests});
                                        }
                                    }).catch(() => {
                                    });
                                }
                            });
                        }
                    }).catch(() => {
                    });
                }
            });
        };

        getIncoming();
        this.setState({
            incomingChecker: setInterval(getIncoming, 15 * 1000)
        });
    }

    static getDerivedStateFromProps(props) {
        return {
            isOpen: props.isOpen
        };
    }

    componentWillUnmount() {
        const incomingChecker = this.state.incomingChecker;

        if (incomingChecker) {
            clearInterval(incomingChecker);
        }
    }

    getPendingCounts() {
        const pendingCount = {};

        const incrementCount = (request) => {
            const { source } = request;
            const count = pendingCount[source] || 0;
            pendingCount[source] = count + 1;
        };

        this.state.incomingRequests.forEach(incrementCount);
        this.state.missedRequests.forEach(incrementCount);

        return pendingCount;
    };

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    renderPending() {
        const pendingCounts = this.getPendingCounts();
        const hasPending = Object.values(pendingCounts).reduce((a, b) => (a + b), 0) > 0;

        const getTitleFromSource = (source) => {
            if (source.toLowerCase() === "friendship") {
                return "Conversation";
            }
            return (source[0].toUpperCase() + source.substring(1));
        };

        const pendingNotifications = [];

        Object.entries(pendingCounts).forEach(([source, count], i) => {
            if (count === 0) {
                return;
            }

            if (i > 0) {
                pendingNotifications.push(
                    <DropdownItem divider />
                );
            }

            const sourcePath = ["friendship", "friend"].includes(source) ? "conversation" : source;
            pendingNotifications.push(
                <Link key={i} to={`/pending/${sourcePath}/`} onClick={() => this.props.closeMenu()}>
                    <DropdownItem>{getTitleFromSource(source)} (New!)</DropdownItem>
                </Link>
            );
        });

        return (
            <React.Fragment>
                {hasPending ?
                    <div className="scrollable-container">
                        {pendingNotifications}
                    </div>
                    :
                    <div>
                        <p id="no-notifications-to-display">No notifications to display...</p>
                    </div>
                }
            </React.Fragment>
        );
    }

    render() {
        if (this.props.menu) {
            return (
                <React.Fragment>
                    <div className="nav-menu-item notifications"
                        onClick={() => this.props.toggle()}
                    >
                        {this.state.isOpen ?
                            <ChevronDown className="lrg-icon mr-2"/>  :
                            <Plus className="lrg-icon mr-2"/>
                        } NOTIFICATIONS
                    </div>
                    <Collapse isOpen={this.state.isOpen}>
                        <div className="col w-fill" id="pending-list">
                            {this.renderPending()}
                        </div>
                    </Collapse>
                </React.Fragment>
            );
        }

        const pendingCounts = this.getPendingCounts();
        const hasPending = Object.values(pendingCounts).reduce((a, b) => (a + b), 0) > 0;

        const readImage = notificationRead;
        const unreadImage = notificationUnread;

        const imgStyle = hasPending ? {width: "25px", height: "30px"} : {width: "20px", height: "25px"};

        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="nav-dropdown">
                <DropdownToggle>
                    <img src={hasPending ? unreadImage : readImage} style={imgStyle} alt="pending-icon"
                        className={`${hasPending ? "pending-dropdown" : "no-pending-dropdown"}`}
                    />
                </DropdownToggle>
                <DropdownMenu className="header-menus" end onClick={this.props.action}>
                    {this.renderPending()}
                </DropdownMenu>
            </Dropdown>
        );
    }
}

Notifications.defaultProps = {
    closeMenu: () => {},
};

export default Notifications;
