import React, {Component} from "react";
import axios from "axios";

import {getAge, isMobile} from "../../utils/utils";
import {CatPriority} from "../../utils/profileConstants";

import ErrorProfile from "./ErrorProfile";
import TalkRequest from "./../talking/TalkRequest";
import RolloutFeedbackButton from "../elements/RolloutFeedbackButton";

import Unverified from "../../images/unverified.png";

import "../../styles/UserProfile.css";

import { THEME } from "../NewSettingsComponents";

class UserProfile extends Component {

    constructor(props) {
        super(props);

        const uniqueId = this.props.match.params.uniqueId;

        this.state = {
            uniqueId,
            onMobile: isMobile(),

            profileInformation: {},
            gotInfo: false,
            error: false,

            isCurrentUser: false,
            allQuestions: {},
            qIdsToOptions: {},
            dropped: {},

            showPictures: false,
            timeOut: Number.parseInt(process.env.REACT_APP_DATING_PHOTO_DELAY) * 1000,
        };

        this.setMobile = this.setMobile.bind(this);
        this.expandHandler = this.expandHandler.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.setMobile);

        axios.get("/api/profile/" + this.props.profileType + "/" + this.state.uniqueId).then((result) => {
            this.setState({
                profileInformation: result.data.profileData,
                isCurrentUser: result.data.isCurrentUser
            }, () => {
                // This is our own profile, no need to delay
                // picture viewing for ourselves.
                if (this.state.isCurrentUser) {
                    this.setState({showPictures: true});
                } else {
                    this.startTimer();
                }
            });
        }).then(() => {
            axios.get("/api/questions").then((result) => {
                const allQuestions = {};
                const qIdsToOptions = {};

                result.data.questions.forEach(q => {
                    const questionId = q.textQuestion
                        ? q.textQuestion._id
                        : q.radioQuestion
                            ? q.radioQuestion._id
                            : q.multipleChoiceQuestion
                                ? q.multipleChoiceQuestion._id
                                : null;

                    if (questionId) {
                        allQuestions[questionId] = q;
                        if (!!q.multipleChoiceQuestion) {
                            qIdsToOptions[questionId] = q.multipleChoiceQuestion.options;
                        }
                    }
                });

                this.setState({ gotInfo: true, allQuestions, qIdsToOptions });
            });
        }).catch((err) => {
            this.setState({gotInfo: true, error: true});
        });
    }

    setMobile() {
        console.log("Screen resize detected");

        this.setState({
            onMobile: isMobile(),
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setMobile);
    }

    startTimer() {
        const photos = this.state.profileInformation.datingPhotos || [];

        // No need for timer if there are no photos to show.
        if (photos.length === 0) {
            return;
        }

        const delta = 50;
        const tid = setInterval(() => {
            if (window.blurred) {
                return;
            }

            this.setState({timeOut: this.state.timeOut - delta});

            if (this.state.timeOut <= 0) {
                clearInterval(tid);
                this.setState({showPictures: true});
            }
        }, delta);

        window.onblur = () => {
            window.blurred = true;
        };

        window.onfocus = () => {
            window.blurred = false;
        };
    }

    toggle = (cat) => () => {
        console.log("toggle called on", cat);
        const dropped = this.state.dropped;

        dropped[cat] = !dropped[cat];
        this.setState({ dropped });
    };

    isQuestionAnswered = question => {
        let isAnswered = false;

        if (question.type === "text") {
            isAnswered = !!question.response;
        } else if (question.type === "radio") {
            isAnswered = true;
        } else if (question.type === "multipleChoice" && question.selected.length > 0) {
            isAnswered = !!question.selected.length;
        }

        return isAnswered;
    };

    shouldDisplayQuestionSet = questions => {
        let shouldDisplay = false;

        questions.forEach(q => {
            if (this.isQuestionAnswered(q)) {
                shouldDisplay = true;
            }
        });

        return shouldDisplay;
    };

    renderQuestionType = (questions) => {
        const tmp = [];

        for (let i = 0; i < questions.length; i++) {
            const question = questions[i];
            const header = <div style={{fontWeight: "bold", paddingBottom: "15px"}}>{question.question}</div>;

            if (question.type === "text") {
                tmp.push(<div key={i}>
                    {header}
                    <span style={{fontStyle: "italic"}}>
                        {question.response}
                    </span>
                </div>);
            } else if (question.type === "radio") {
                tmp.push(<div key={i}>
                    {header}
                    <span style={{fontStyle: "italic"}}>
                        {question.selected}
                    </span>
                </div>);
            } else if (question.type === "multipleChoice" && question.selected.length > 0) {
                const responseTexts = question.selected.map(obj => {
                    const options = this.state.qIdsToOptions[question.questionId];
                    if (!!options && options.includes(obj)){
                        return obj;
                    } else if (typeof obj === "number" && obj < options.length) {
                        return options[obj];
                    } else {
                        return null;
                    }
                }).filter(elem => elem !== null);
                tmp.push(<div key={i}>
                    {header}
                    <span style={{fontStyle: "italic"}}>
                        {responseTexts.join(", ")}
                    </span>
                </div>);
            }
        }

        return tmp;
    };

    getTitleFromType = (questionType) => {
        if (questionType === "Dating") {
            return "Relationship";
        }

        if (questionType === "Collaboration") {
            return "Collaborate";
        }

        return questionType;
    };

    getColor = () => {
        return "#684CB5";
    };

    renderQuestions = () => {
        const tmp = [];
        let questions = this.state.profileInformation.questions;

        // Order the question categories based on what was filtered.
        const passedState = this.props.location.state;
        const filtersUsedIds = passedState ? Object.keys(passedState.filtersUsed) : [];

        const questionsMap = {};

        questions.forEach((qes) => {
            questionsMap[qes.questionId] = qes;
        });

        const relevantQuestions = filtersUsedIds
            .map(((filtersUsedId) => {
                const val = questionsMap[filtersUsedId];
                delete questionsMap[filtersUsedId];
                return val;
            }))
            .filter((qes) => (!!qes));

        const getCategory = (question) => {
            let category = "General";

            if (question) {
                // The "Friend" questions are labeled as "General"
                // because they apply across all of the profiles.
                if (question.category !== "Friend") {
                    category = question.category;
                }
            }

            return category;
        };

        const irrelevantQuestions = Object.values(questionsMap);
        const allQuestions = this.state.allQuestions;

        irrelevantQuestions.sort((a, b) => {
            const categoryA = getCategory(allQuestions[a.questionId]);
            const categoryB = getCategory(allQuestions[b.questionId]);

            const priorityA = CatPriority[categoryA] || -1;
            const priorityB = CatPriority[categoryB] || -1;

            return priorityA - priorityB;
        });

        questions = relevantQuestions.concat(irrelevantQuestions);

        const fullQuestions = questions.map(q => {
            return { q1: q, q2: allQuestions[q.questionId]};
        });

        const questionTypes = {};

        fullQuestions.forEach(q => {
            const category = getCategory(q.q2);

            if (this.props.excludedCategories.includes(category)) {
                return;
            }

            if (questionTypes[category]) {
                questionTypes[category].push(q);
            } else {
                questionTypes[category] = [q];
            }
        });

        const questionTypeKeys = Object.keys(questionTypes);
        const profileType = this.props.profileType;
        let firstKey = null;

        if (profileType === "collaborate") {
            firstKey = "Collaboration";
        } else if (profileType === "dating") {
            firstKey = "Dating";
        }

        const firstIndex = questionTypeKeys.indexOf(firstKey);

        // Insert "firstKey" at the beginning so that the
        // category always gets rendered on the top.
        if (firstIndex > 0) {
            questionTypeKeys.splice(firstIndex, 1);
            questionTypeKeys.unshift(firstKey);
        }

        questionTypeKeys.forEach((qt, index) => {
            let typeQuestions = questionTypes[qt].filter(q => !!q && !!q.q2);
            typeQuestions = typeQuestions.map(q => {
                // We save the original question text from registration,
                // but we override with whatever is from the CMS.
                const question = q.q2.name || q.q1.question;
                return {...q.q1, priority: q.q2.priority, question};
            });

            // Sort questions in descending priority.
            typeQuestions.sort((a, b) => {
                return b.priority - a.priority;
            });

            if (this.shouldDisplayQuestionSet(typeQuestions)) {
                const questionHtml =
                    this.makeDropdown(this.getTitleFromType(qt), this.renderQuestionType(typeQuestions), qt);
                tmp.push(questionHtml);
            }
        });

        return tmp;
    };

    makeDropdown(title, content, qt) {
        const { onMobile } = this.state;

        const titleSize = "20px";

        const onClickFunc = () => {
            const dropped = this.state.dropped;
            const newDroppedState = {...dropped, [qt]: !dropped[qt]};
            this.setState({dropped: newDroppedState});
        };
        const isDropped = this.state.dropped[qt];

        const mainMargin = THEME.marginXSmall;

        const plusIcon = <>
        <svg xmlns="http://www.w3.org/2000/svg"
            className="plus" width="100" height="100" viewBox="0 0 160 160" fill="white">
            <rect className="vertical-line" x="70" width="20" height="160"/>
            <rect className="horizontal-line" y="70" width="160" height="20"/>
        </svg>
      </>;

        const minusIcon = <>
        <svg xmlns="http://www.w3.org/2000/svg"
            class="plus" width="100" height="100" viewBox="0 0 160 160" fill="white">
            <rect class="horizontal-line" y="70" width="160" height="20"/>
        </svg>
      </>;

        const titleButton = <>
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                cursor: "pointer"}}
            onClick={onClickFunc}>
            <div style={{fontWeight: 700, fontSize: titleSize, color: THEME.colorPrimary}}>
                {title}
            </div>
            <div
                style={{
                    width: titleSize,
                    height: titleSize,
                    minWidth: titleSize,
                    minHeight: titleSize,
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: titleSize,
                    color: "white",
                    backgroundColor: THEME.colorPrimary,
                    marginTop: "4px",
                    marginLeft: THEME.marginXSmall,
                    userSelect: "none",
                    padding: "4px"}}>
                {isDropped ? minusIcon : plusIcon}
            </div>
        </div>
      </>;

        const gridStyle = onMobile ? {gridAutoFlow: "row"} : {gridTemplateColumns: "3fr 10fr"};

        const contentArea = <>
        <div style={{display: "grid", gridAutoFlow: "row", rowGap: THEME.marginSmall}}>
            {content}
        </div>
      </>;

        const mainGrid = <>
        <div style={{display: "grid", gap: THEME.marginXSmall, ...gridStyle, color: THEME.colorAccent}}>
            {titleButton}
            {isDropped ? contentArea : onMobile ? undefined : <div/>}
        </div>
      </>;

        const html = (
            <div key={qt}>
                {mainGrid}
                <div
                    style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: THEME.colorPrimary,
                        marginTop: mainMargin}}/>
            </div>
        );

        return html;

    }

    renderPhotos = () => {
        const photos = this.state.profileInformation.datingPhotos || [];

        // Don't render anything if there are no photos to delay.
        if (photos.length === 0) {
            return;
        }

        if (!this.state.showPictures) {
            const html = <>
            <div style={{color: THEME.colorAccent}}>
              Photos load after 45 seconds so you can read the profile first.
            </div>
          </>;
            return html;
        }

        const maxDim = "200px";
        const tmp = [];

        for (let i = 0; i < photos.length; i++) {
            const {imgUrl, approved: imgApproved} = photos[i];
            const src =
                `${process.env.REACT_APP_GCLOUD_PREFIX}/${process.env.REACT_APP_GCLOUD_BUCKET}/${imgUrl}`;
            const image = imgApproved
                ? <img src={src} key={i} className="profile-img" alt="user-icon"
                    style={{maxWidth: maxDim, maxHeight: maxDim, width: maxDim, height: maxDim}}/>
                : <img src={Unverified} key={0} className="profile-img" alt="user-icon"
                    style={{maxWidth: maxDim, maxHeight: maxDim, width: maxDim, height: maxDim}}/>;
            tmp.push(image);
        }

        const gridStyle = {gridTemplateColumns: "repeat(auto-fit, 200px)", justifyContent: "center"};

        const html = <>
          <div style={{display: "grid", gap: THEME.marginXSmall, ...gridStyle}}>
              {tmp}
          </div>
        </>;

        return html;

    };

    getGenderLetter() {
        const gender = this.state.profileInformation.gender.toLowerCase();

        if (gender === "man") {
            return "M";
        } else if (gender === "woman") {
            return "F";
        } else if (gender === "non-binary") {
            return "N";
        } else {
            return gender;
        }
    }

    renderPreferences() {
        if (this.props.profileType !== "dating") {
            return;
        }

        const profileInfo = this.state.profileInformation;
        let preferences = [];

        if (profileInfo.noSmoke) {
            preferences.push("I am looking for someone who does not smoke.");
        }

        if (profileInfo.noDrug) {
            preferences.push("I am looking for someone who does not use drugs.");
        }

        if (profileInfo.noDrink) {
            preferences.push("I am looking for someone who does not drink, or only drinks on special occasions.");
        }

        if (preferences.length === 0) {
            return;
        }

        const qt = "Preferences";
        const title = "Relationship Preferences";

        preferences = preferences.map((preference, i) => (
            <div key={i} style={{}}>{preference}</div>
        ));

        return this.makeDropdown(title, preferences, qt);
    }

    renderShare() {
        const qt = "shareThings";
        const title = "A few things I'd like to share";

        return this.makeDropdown(title, this.state.profileInformation.aboutMeFriend, qt);
    };


    expandHandler = () => {
        if (this.state.onMobile) {
            window.scrollTo(0, document.body.scrollHeight);
        }
    };

    render() {
        const age = getAge(this.state.profileInformation.birthday);
        const { onMobile } = this.state;
        let content;

        if (!this.state.gotInfo) {
            content = <div />;
        } else if (this.state.error) {
            content = <ErrorProfile />;
        } else {
            const bubbleSize = onMobile ? "200px" : "280px";
            const bubbleFontSize = onMobile ? "120px" : "180px";
            const userBubble = <>
            <div
                style={{
                    height: bubbleSize,
                    width: bubbleSize,
                    minWidth: bubbleSize,
                    minHeight: bubbleSize,
                    borderRadius: "50%",
                    border: "7px solid white",
                    backgroundColor: THEME.colorSecondary,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    userSelect: "none"}}>
                <div style={{fontSize: bubbleFontSize,fontWeight: "700", color: THEME.colorAccent}}>
                    {this.state.profileInformation.firstName ?
                        this.state.profileInformation.firstName[0] : ""}
                </div>
            </div>
          </>;

            const userInfo = <>
            <div
                style={{
                    fontSize: "50px",
                    textTransform: "uppercase",
                    display: "grid",
                    rowGap: THEME.marginXSmall,
                    alignItems: "center",
                    justifyItems: onMobile ? "center" : "start",
                    lineHeight: 1}}>
                <div style={{fontWeight: 700, letterSpacing: "3px", wordBreak: "break-all"}}>
                    {this.state.profileInformation.firstName}
                </div>
                <div style={{letterSpacing: "2px"}}>
                    {age}/{this.getGenderLetter()}
                </div>
                <div
                    style={{
                        display: "grid",
                        gridAutoFlow: "row",
                        gridTemplateColumns: onMobile ? "1fr" : "minmax(auto, 200px)",
                        width: "100%",
                        gap: THEME.marginXSmall}}>
                    <div
                        style={{
                            padding: "13px 14px",
                            fontSize: "14px",
                            backgroundColor: THEME.colorPrimary,
                            whiteSpace: "nowrap",
                            fontWeight: 700,
                            userSelect: "none",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"}}>
                    TRAVEL MODE IS{" "}
                        <span style={{color: THEME.colorAccent}}>
                            {this.state.profileInformation.traveling ? "ON" : "OFF"}
                        </span>
                    </div>
                    {!this.state.isCurrentUser &&
                  <TalkRequest
                      userInfo={this.state.profileInformation}
                      profileType={this.props.profileType}
                      style={{width: "100%"}}
                  />
                    }
                    {this.state.profileInformation.frozen &&
                  <div
                      style={{
                          fontWeight: 700,
                          fontSize: "14px",
                          height: "40px",
                          padding: "0px 14px",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          color: THEME.colorAccent,
                          backgroundColor: "white",
                          userSelect: "none"}}>
                        **Frozen Account**
                  </div>
                    }
                </div>
            </div>
          </>;

            const mainMargin = onMobile ? THEME.marginXSmall : THEME.marginSmall;

            const topGridStyle = onMobile ? {gridAutoFlow: "row"} : {gridAutoFlow: "column"};
            content = (
                <div className="profile-container">
                    <div
                        style={{
                            display: "grid",
                            gap: mainMargin,
                            backgroundColor: THEME.colorAccent,
                            justifyContent: "center",
                            justifyItems: "center",
                            padding: mainMargin,
                            ...topGridStyle}}>
                        {userBubble}
                        {userInfo}
                    </div>
                    <div style={{display: "grid", gridAutoFlow: "row", padding: mainMargin, gap: mainMargin}}>
                        {this.renderPhotos()}
                        <div style={{display: "grid", gridAutoFlow: "row", gap: THEME.marginXSmall}}>
                            {this.renderQuestions()}
                            {this.renderPreferences()}
                            {this.renderShare()}
                            {!this.state.isCurrentUser &&
                            <RolloutFeedbackButton
                                _id={this.state.profileInformation.uniqueId}
                                type="userReport" placeholder="..."
                                buttonStyle={{
                                    borderRadius: "5px",
                                    border: "2px solid #684CB5",
                                    backgroundColor: "transparent",

                                    fontSize: "13px",
                                    ...(onMobile ? {width: "90px"} : {}),
                                }}
                                expandHandler={this.expandHandler} />}
                        </div>
                    </div>
                </div>
            );
        }

        const html = content;

        return html;

        //                 <div className="profile-name" style={{borderColor: this.getColor()}}>
        //                     {this.state.profileInformation.frozen &&
        // <div style={{paddingTop: "10px"}}>
        //     **Frozen Account
        // </div>
        //                     }
        //                     {this.state.profileInformation.traveling &&
        //                     <div style={{paddingTop: "10px"}}>
        //                         **Travel Mode: ON
        //                     </div>
        //                     }

        //                     }} title={this.state.profileInformation.firstName}>
        //                         {this.state.profileInformation.firstName}
        //                     </h1>
        //                     <p style={{fontWeight: "bold"}}>
        //                         {age}/{this.getGenderLetter()}
        //                     </p>
        //                 </div>

        // {!this.state.isCurrentUser &&
        // <div style={{marginLeft: "auto", marginRight: "auto", paddingTop: "30px"}}>
        //     <TalkRequest
        //         userInfo={this.state.profileInformation}
        //         profileType={this.props.profileType}
        //     />
        // </div>
        // }

        //                 {this.renderPhotos()}

        // {this.renderQuestions()}
        // {this.renderPreferences()}

        //                 {this.renderShare()}

        // {!this.state.isCurrentUser &&
        // <RolloutFeedbackButton
        //     _id={this.state.profileInformation.uniqueId}
        //     type="userReport" placeholder="..."
        //     buttonStyle={{
        //         borderRadius: "5px",
        //         border: "2px solid #684CB5",
        //         backgroundColor: "transparent",

        //         fontSize: "13px",
        //         ...(onMobile ? {width: "90px"} : {}),
        //     }}
        //     expandHandler={this.expandHandler}

    }
}

UserProfile.defaultProps = {
    profileType: undefined,
    excludedCategories: [],
};

export default UserProfile;
