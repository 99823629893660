import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

const initAnalytics = () => {
    // const inProd = process.env.NODE_ENV === "production";
    // const gaOpts = {debug: !inProd, testMode: !inProd};

    // ReactGA.initialize(process.env.REACT_APP_GA_ID, gaOpts);

    // temporary disabled google tag manager
};

const sendEvent = (action, args = {}) => {
    // args.category = args.category || "user";
    // args.action = action || "Site Event";

    // ReactGA.event(args);

    // temporary disabled google analytics
};

const sendPageView = (page) => {
    // ReactGA.pageview(page);

    // temporary disabled google analytics
};

// TODO: Get this to work
const initGTM = () => {
    // const tagManagerArgs = {
    //     gtmId: process.env.GTM_ID,
    // };

    // TagManager.initialize(tagManagerArgs);

    // temporary disabled google tag manager
};

export {
    sendEvent,
    sendPageView,
    initAnalytics,
    initGTM,
};
