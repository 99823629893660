import React, {Component} from "react";
import {isMobile} from "../../utils/utils";

class ErrorProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            onMobile: isMobile(),
        };
    }

    render() {
        const { onMobile } = this.state;

        return (
            <div className="col"
                style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlign: "center",
                    ...(onMobile ? {
                        width: "100%",
                        paddingLeft: "0px",
                        paddingRight: "0px"
                    } : {maxWidth: "70%"})
                }}
            >
                <div className="profile-name">
                    <h1 style={{fontSize: onMobile ? "30px" : "40px"}}>
                        We could not retrieve the profile that you requested.
                    </h1>
                    <p style={{color: "#f7f7ff"}}>
                        If you believe this is an error, try refreshing the page.
                    </p>
                </div>
            </div>
        );
    }
}

export default ErrorProfile;
