import React, {Component} from "react";

import {isMobile} from "../../utils/utils";
import Select from "../elements/Select";


class Schedule extends Component {
    static TABLE_ID = "modalBody";
    static SCROLL_DIFF = 60;

    constructor(props) {
        super(props);

        const onMobile = isMobile();
        const offset = onMobile ? 80 : 75;

        this.state = {
            onMobile,
            scrollOffset: offset,
            timeOptions: [],
            days: [],
        };

        this.setMobile = this.setMobile.bind(this);
        // this.scrollToFirstTime = this.scrollToFirstTime.bind(this);
    }

    getDay0() {
        const day0 = new Date(this.props.startingTime);
        const minutes = day0.getMinutes();

        if (minutes > 30) {
            day0.setHours(day0.getHours() + 1);
        }

        day0.setMinutes(0);
        day0.setSeconds(0);
        day0.setMilliseconds(0);

        return day0;
    }

    scrollToFirstTime(timeout = 300) {
        const firstTime = this.props.getFirstTime(this.getDay0());

        if (firstTime) {
            setTimeout(() => {
                const firstTimeElement = document.getElementById(firstTime);

                if (!firstTimeElement) {
                    return;
                }

                firstTimeElement.scrollIntoView({
                    block: "nearest", inline: "center", behavior: "auto"
                });

                const table = document.getElementById(Schedule.TABLE_ID);
                const {scrollOffset} = this.state;
                let scrollLeft = table.scrollLeft;

                if (scrollLeft < scrollOffset) {
                    scrollLeft = 0;
                } else {
                    const remainder = (scrollLeft - scrollOffset) % Schedule.SCROLL_DIFF;
                    scrollLeft -= remainder;
                }

                table.scrollLeft = scrollLeft;
            }, timeout);
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.setMobile);

        // this.scrollToFirstTime();

        const day0 = this.getDay0();

        const day1 = new Date(day0);
        day1.setDate(day1.getDate() + 1);

        const day2 = new Date(day0);
        day2.setDate(day2.getDate() + 2);

        const day3 = new Date(day0);
        day3.setDate(day3.getDate() + 3);

        const day4 = new Date(day0);
        day4.setDate(day4.getDate() + 4);

        const day5 = new Date(day0);
        day5.setDate(day5.getDate() + 5);

        const day6 = new Date(day0);
        day6.setDate(day6.getDate() + 6);

        const days = [day0, day1, day2, day3, day4, day5, day6];
        this.setState({days}, () => {
            this.getCheckBoxes(day0, false, true);
        });
    }

    setMobile() {
        console.log("Screen resize detected");

        this.setState({
            onMobile: isMobile(),
        }, () => {
            // this.scrollToFirstTime(1);
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setMobile);
    }

    getDayofWeekString = (date) => {
        const day = date.getDay();

        switch (day) {
        case 0:
            return "Sunday";
        case 1:
            return "Monday";
        case 2:
            return "Tuesday";
        case 3:
            return "Wednesday";
        case 4:
            return "Thursday";
        case 5:
            return "Friday";
        case 6:
            return "Saturday";
        default:
            return;
        }
    };

    getTimeStrings = () => {
        const strings = ["12:00am", "12:30am"];
        for (let trial = 0; trial < 2; trial++) {
            const end = trial === 0 ? "am" : "pm";
            if (trial === 1) {
                strings.push(`12:00${end}`);
                strings.push(`12:30${end}`);
            }
            for (let t1 = 1; t1 < 12; t1++) {
                strings.push(`${t1}:00${end}`);
                strings.push(`${t1}:30${end}`);
            }
        }
        return strings;
    }

    getSelectableTime = (label, timeString, offsetVal) => {
        const chosen = this.props.isTimeChecked(timeString);
        let btnBg = chosen ? "#1E2738" : "#9FC6CC";
        return (
            <div
                className="time-box"
                key={timeString}
                id={timeString + offsetVal}
                style={{backgroundColor: btnBg}}
                onClick={() => {
                    this.props.toggleTime(timeString);
                    btnBg = btnBg === "#1E2738"
                        ? "#9FC6CC" : "#1E2738";
                    document
                        .getElementById(timeString + offsetVal)
                        .style
                        .backgroundColor = btnBg;
                }}>
                {label}
            </div>
        );
    };

    getCheckBoxes = (date, before, after) => {
        if (date === null) {
            return <div/>;
        }
        const tmp = [];
        const timeStrings = this.getTimeStrings();

        for (let i = 0; i < 24 ; i++) {
            const newDate = new Date(date);
            newDate.setHours(i);

            for (const minutes of [0, 30]) {
                newDate.setMinutes(minutes);
                const checkBoxId = newDate.getTime().toString();

                if (this.props.shouldInclude(date, newDate, before, after)) {
                    const offset = minutes === 0 ? 0 : 1;
                    const offsetVal = i * 2 + offset;
                    tmp.push(this.getSelectableTime(timeStrings[offsetVal],
                        checkBoxId,
                        offsetVal));
                }
            }
        }
        this.setState({timeOptions: tmp});
        return tmp;
    };

    getDayDropDown = () => {
        const tmp = [];
        const {days} = this.state;

        for (let i = 0; i < days.length; i++) {
            tmp.push(<option key={i}>
                {this.getDayofWeekString(days[i])}
            </option>);
        }

        return tmp;
    };

    selectOnChange = (e) => {
        const options = e.target.options;

        let val = "";
        let optionIndx = null;

        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                optionIndx = i;
                val = options[i].value;
                break;
            }
        }

        this.getCheckBoxes(this.state.days[optionIndx], false || optionIndx, true);
    };


    // Get next 20 hours + the next six full days after.
    render() {
        const content = (
            <div className="schedule-body">
                <p>
                    Choose the days and times that you want and then click "Send Request."
                    Times are adjusted for your timezone.
                </p>
                <Select
                    className="schedule-body-dropdown"
                    name="dayDropdown"
                    onChange={this.selectOnChange}
                    style={{
                        height: "40px",
                        margin: "12px 0",
                        border: "1px solid black",
                    }}
                >
                    {this.getDayDropDown()}
                </Select>
                <div className="schedule-body-options">
                    {this.state.timeOptions}
                </div>
            </div>
        );

        return (
            content
        );
    }
}

Schedule.defaultProps = {
    startingTime: new Date(),
    getFirstTime: () => (null),
    shouldInclude: () => (true),

    toggleTime: () => {},
    isTimeChecked: () => (false),
};

export default Schedule;
