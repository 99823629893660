import React, { Component } from "react";
import {get} from "lodash";
import axios from "axios";

import FriendQuestionsPageOne from "./FriendQuestionsPageOne";
import FriendQuestionsPageTwo from "./FriendQuestionsPageTwo";

import {isMobile} from "../../utils/utils";
import {CatPriority} from "../../utils/profileConstants";

import "../../styles/FriendQuestions.css";

import { THEME } from "../NewSettingsComponents";

class FriendQuestions extends Component {
    static CACHE_FIELDS = ["collabMusic", "userType", "aboutMeFriend",
        "questionResponses", "friendLowRange", "friendHighRange"];
    static CACHE_KEY = "friendQuestions";

    constructor(props) {
        super(props);

        const cachedState = this.loadCache();

        let questionResponses = get(props, ["currentUser", "questionResponses"], []);
        questionResponses = this.formatResponses(questionResponses);

        if (get(cachedState, "questionResponses")) {
            questionResponses = {
                ...questionResponses,
                ...cachedState.questionResponses
            };

            delete cachedState.questionResponses;
        }

        // We won't be able to update our friend range correctly
        // if we aren't actually filling out the friend-specifics.
        const defaultAge = this.props.genOnly ? "18" : "--";

        let friendLowRange = get(props, ["currentUser", "friendLowRange"], defaultAge);
        friendLowRange = friendLowRange === -2 ? defaultAge : friendLowRange;

        let friendHighRange = get(props, ["currentUser", "friendHighRange"], defaultAge);
        friendHighRange = friendHighRange === -1 ? defaultAge : friendHighRange;

        this.state = {
            friendAccount: true,
            collabMusic: get(props, ["currentUser", "collabMusic"], false),

            gotAllQuestions: false,
            friendQuestionsLoaded: false,
            categoryQuestionsLoaded: false,

            friendLowRange,
            friendHighRange,

            friendQuestions: {},
            categoryQuestions: {},

            questionResponses,

            userType: get(props, ["currentUser", "userType"], ""),
            aboutMeFriend: get(props, ["currentUser", "aboutMeFriend"], ""),

            onMobile: isMobile(),

            msg: props.msg,
            inProgress: false,
            infoColor: props.infoColor,

            updatePage: this.props.updatePage,
            page: this.props.paged ? 1 : null,

            ...cachedState
        };

        this.setMobile = this.setMobile.bind(this);
    }

    setMobile() {
        console.log("Screen resize detected");

        this.setState({
            onMobile: isMobile(),
        });
    }

    formatResponses = (responses) => {
        const formattedResponses = {};

        responses.forEach((response) => {
            const formattedResponse = {
                question: response.question
            };

            let question;

            if (response.radioQuestionResponse) {
                question = response.radioQuestionResponse;

                formattedResponse["selected"] = question.selected;
                formattedResponse["type"] = "radio";
            } else if (response.textQuestionResponse) {
                question = response.textQuestionResponse;

                formattedResponse["response"] = question.text;
                formattedResponse["type"] = "text";
            } else if (response.multipleChoiceQuestionResponse) {
                question = response.multipleChoiceQuestionResponse;

                formattedResponse["selected"] = question.answers;
                formattedResponse["type"] = "multipleChoice";
            }

            if (question) {
                formattedResponse["id"] = question.questionId;
                formattedResponses[question.questionId] = formattedResponse;
            }
        });

        return formattedResponses;
    };

    componentDidMount() {
        window.addEventListener("resize", this.setMobile);
        axios.get("/api/questions?type=friend")
            .then((result) => {
                this.setState({
                    friendQuestions: result.data.questions,
                    friendQuestionsLoaded: true
                });
            })

            .then(() => axios.get("/api/questions?subcat=true"))
            .then((result) => {
                this.setState({
                    categoryQuestions: result.data.questions,
                    categoryQuestionsLoaded: true
                });
            })

            .catch(() => {
                this.setState({
                    infoColor: false,
                    inProgress: false,
                    msg: "An error occurred loading the questions."
                });
            })
            .then(() => this.initializeQuestionResponses());
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevState.friendQuestions[0] !== this.state.friendQuestions[0]
            || prevState.categoryQuestions[0] !== this.state.categoryQuestions[0])
            && (this.state.friendQuestionsLoaded
                && this.state.categoryQuestionsLoaded))
        {
            this.initializeQuestionResponses();
        }

        if (prevProps.msg !== this.props.msg || prevProps.infoColor !== this.props.infoColor) {
            this.setState({
                msg: this.props.msg,
                infoColor: this.props.infoColor,
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setMobile);
        // When we're creating the profile (i.e. not updating),
        // we already cache after all API calls have succeeded.
        if (this.props.update) {
            this.cacheState();
        }
    }

    updateMultipleChoiceQuestion = (id, selections) => {
        const newQuestions = Object.assign({}, this.state.questionResponses);
        newQuestions[id]["selected"] = selections;
        this.setState({questionResponses: newQuestions}, this.cacheState);
    };

    updateTextQuestion = (id, answer) => {
        const newQuestions = Object.assign({}, this.state.questionResponses);
        newQuestions[id]["response"] = answer;
        this.setState({questionResponses: newQuestions}, this.cacheState);
    };

    updateRadioQuestion = (id, selection) => {
        const newQuestions = Object.assign({}, this.state.questionResponses);
        newQuestions[id]["selected"] = selection;

        this.setState({questionResponses: newQuestions}, this.cacheState);
    };

    initializeQuestionResponses = () => {
        const questionResponses = this.state.questionResponses;
        const questions = this.state.friendQuestions;

        if (questions) {
            (questions.multipleChoice || [])
                .forEach(questionObject => {
                    const {_id: id, question: questionText} = questionObject;
                    questionResponses[id] = {
                        id,
                        selected: get(questionResponses, [id, "selected"], []),
                        friend: true,
                        type: "multipleChoice",
                        question: questionText,
                    };
                });

            (questions.text || [])
                .forEach(questionObject => {
                    const {_id: id, question: questionText, minimumChars} = questionObject;
                    questionResponses[id] = {
                        id,
                        response: get(questionResponses, [id, "response"], ""),
                        minChars: minimumChars,
                        friend: true,
                        type: "text",
                        question: questionText
                    };
                });

            (questions.radio || [])
                .forEach(questionObject => {
                    const {_id: id, question: questionText} = questionObject;
                    questionResponses[id] = {
                        id,
                        selected: get(questionResponses, [id, "selected"], ""),
                        friend: true,
                        type: "radio",
                        question: questionText
                    };
                });
        }

        const categoryQuestionTypes = this.state.categoryQuestions.questionTypes;

        if (categoryQuestionTypes) {
            categoryQuestionTypes.sort((a, b) => (
                CatPriority[a] - CatPriority[b]
            ));

            categoryQuestionTypes
                .forEach(questionType => {
                    const questionList = this.state.categoryQuestions[questionType];

                    questionList.multipleChoice
                        .forEach(questionObject => {
                            const {_id: id, question: questionText} = questionObject;

                            questionResponses[id] = {
                                id,
                                selected: get(questionResponses,[id, "selected"], []),
                                type: "multipleChoice",
                                question: questionText
                            };
                        });

                    questionList.text
                        .forEach(questionObject => {
                            const {_id: id, question: questionText, minimumChars} = questionObject;

                            questionResponses[id] = {
                                id,
                                response: get(questionResponses,[id, "response"], ""),
                                type: "text",
                                minChars: minimumChars,
                                question: questionText,
                            };
                        });

                    questionList.radio
                        .forEach(questionObject => {
                            const {_id: id, question: questionText} = questionObject;

                            questionResponses[id] = {
                                id,
                                selected: get(questionResponses,[id, "selected"], ""),
                                type: "radio",
                                question: questionText
                            };
                        });
                });
        }

        this.setState({
            questionResponses,
            gotAllQuestions: true
        });
    };

    selectOnChange = (e) => {
        const name = e.target.name;
        const selectedOption = get(Array.from(e.target.options).filter(opt => opt.selected), [0, "value"]);

        this.setState({[name]: parseInt(selectedOption)}, this.cacheState);
    };

    updateMusic = (val) => {
        this.setState({collabMusic: val}, this.cacheState);
    };

    updateAccountType = () => {
        let name;

        if (this.state.userType === "student") {
            name = "";
        } else {
            name = "student";
        }

        this.setState({userType: name}, this.cacheState);
    };

    onChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value}, this.cacheState);
    };

    inputsInvalid = () => {
        if (this.state.page === 1 || this.props.update || this.props.preVerify) {
            const qRes = this.state.questionResponses;

            for (const id in qRes) {
                const question = qRes[id];

                if (question.friend) {
                    if (question.type === "text" && question.response.length < question.minChars) {
                        const questionStart = question.question.split("?")[0] + "?";
                        const diff = question.minChars - question.response.length;

                        return `Make sure your response for "${questionStart}" meets the minimum character ` +
                            `requirement. Add ${diff} more character(s).`;
                    }

                    if (question.type === "radio" && question.response === "") {
                        return "All general friend radio button responses must be have a selection!";
                    }
                } else {
                    if (question.type === "text" && (question.response.length < question.minChars &&
                        question.response.length !== 0)) {
                        const questionStart = question.question.split("?")[0] + "?";
                        const diff = question.minChars - question.response.length;

                        return `Make sure your response for "${questionStart}" meets the minimum character ` +
                            `requirement. Add ${diff} more character(s).`;
                    }
                }
            }

            const aboutMinChars = Number.parseInt(process.env.REACT_APP_ABOUT_ME_CHAR_COUNT);
            const aboutTotalChars = this.state.aboutMeFriend.length;

            if (aboutTotalChars !== 0 && aboutTotalChars < aboutMinChars) {
                const diff = aboutMinChars - aboutTotalChars;

                return `Make sure your response for "A few things I'd like" meets the ${aboutMinChars} minimum ` +
                        `character requirement. Add ${diff} more character(s).`;
            }
        }

        if (this.state.page === 2 || this.props.update || this.props.preVerify) {
            // If parseInt returns undefined for one or both of these, check will still fail as expected.
            if (!(parseInt(this.state.friendHighRange) >= parseInt(this.state.friendLowRange))) {
                return "Must select a valid age range";
            }
        }

        return null;
    };

    displayedInvalid = (msg) => {
        if (!msg) {
            return false;
        }

        this.setState({
            msg,
            infoColor: false,
            inProgress: false,
        });

        return true;
    };

    flipPageTo = (pageNumber) => () => {
        // Validate only if we're moving forward.
        if (pageNumber > this.state.page && this.displayedInvalid(this.inputsInvalid())) {
            return;
        }

        this.cacheState();
        this.setState({
            msg: "",
            infoColor: false,
            inProgress: false,
            page: pageNumber,
        });
    };

    goBack = () => {
        const cb = () => {
            this.cacheState();
            this.props.prevState();
        };

        if (this.state.page === 1) {
            this.props.updateState({
                friendQuestions: this.state
            }, cb);
        } else {
            cb();
        }
    };

    loadCache = () => {
        let cachedState = JSON.parse(window.localStorage.getItem(this.props.currentUser.email)) || {};
        cachedState = cachedState[FriendQuestions.CACHE_KEY];

        if (cachedState) {
            this.props.updateState({
                friendQuestions: cachedState
            });
        }

        return cachedState;
    };

    cacheState = () => {
        const cache = {};

        for (const key of FriendQuestions.CACHE_FIELDS) {
            cache[key] = this.state[key];
        }

        const cachedState = JSON.parse(window.localStorage.getItem(this.props.currentUser.email)) || {};

        cachedState[FriendQuestions.CACHE_KEY] = cache;
        window.localStorage.setItem(this.props.currentUser.email, JSON.stringify(cachedState));
    };

    onSubmit = () => {
        const msg = this.inputsInvalid();

        if (!this.props.preVerify && this.displayedInvalid(msg)) {
            return;
        }

        if (!this.props.update) {
            this.setState({
                inProgress: true
            }, () => {
                this.props.updateState({
                    incomplete: !!msg,
                    friendQuestions: this.state
                }, () => {
                    this.cacheState();
                    this.props.nextState();
                });
            });
        } else {
            const formData = new FormData();

            const onGeneral = this.state.updatePage === 1;
            const data = {...this.state};

            // Not creating a friend account here.
            if (onGeneral) {
                delete data.friendAccount;
            }

            const profileName = onGeneral ? "Morphkey" : "Conversation";
            formData.append("data", JSON.stringify(data));

            this.setState({
                infoColor: true, inProgress: true,
                msg: `Updating ${profileName} profile...`,
            }, () => {
                axios.post("/api/users/update-answers", formData).then(() => {
                    this.cacheState();

                    if (this.props.reloadPage) {
                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                        window.location.reload();
                    } else {
                        this.setState({
                            infoColor: true, inProgress: false,
                            msg: `${profileName} profile successfully updated!`,
                        });
                    }
                }).catch(() => {
                    this.setState({
                        infoColor: false, inProgress: false,
                        msg: "Something went wrong updating. Please try again.",
                    });
                });
            });
        }
    };

    onDismiss = () => {
        this.setState({msg: "", infoColor: false, inProgress: false});
    };

    displayAlert() {
        const { msg, infoColor } = this.state;

        if (msg !== "") {
            return (
                <div id="friend-content-alert-wrapper" onClick={this.onDismiss}
                    className={`dismiss-alert ${infoColor ? "info" : ""}`} style={{marginTop: THEME.marginSmall}}
                >
                    {/* {inProgress ?
                        <img src={rotating} className="rotating-icon" alt="in-progress-icon" /> :
                        <div id="friend-content-alert-icon">!</div>
                    } */}
                    <div id="friend-content-alert-message">{msg}</div>
                </div>
            );
        }
    }

    render() {
        const { page, updatePage, inProgress, onMobile } = this.state;

        // let className = "primary-wrapper mt-40 extended col center-self primary mb-20 questions";
        // className = `${className} ${this.props.settings ? "settings" : ""}`;

        const content = (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: onMobile ? THEME.marginXSmall : THEME.marginSmall}}>
                {this.state.gotAllQuestions && (
                    <React.Fragment>
                        {(!page || page === 2) && (!updatePage || updatePage === 2) && (
                            <FriendQuestionsPageTwo
                                selectOnChange={this.selectOnChange}
                                friendLowRange={this.state.friendLowRange}
                                friendHighRange={this.state.friendHighRange}
                                updateMusic={this.updateMusic}
                                collabMusic={this.state.collabMusic}
                                settings={this.props.settings}
                                update={this.props.update}
                                preVerify={this.props.preVerify}
                            />
                        )}

                        {(!page || page === 1) && (!updatePage || updatePage === 1) && (
                            <FriendQuestionsPageOne
                                friendQuestions={this.state.friendQuestions}
                                updateMultipleChoiceQuestion={this.updateMultipleChoiceQuestion}
                                updateRadioQuestion={this.updateRadioQuestion}
                                updateTextQuestion={this.updateTextQuestion}
                                aboutMeFriend={this.state.aboutMeFriend}
                                onChange={this.onChange}
                                userType={this.state.userType}
                                updateAccountType={this.updateAccountType}
                                categoryQuestions={this.state.categoryQuestions}
                                questionResponses={this.state.questionResponses}
                            />
                        )}

                        {this.displayAlert()}

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: THEME.marginSmall}}>
                            {this.props.update ?
                                <div /> :
                                <div className="btn" id="friend-action-go-back"
                                    onClick={inProgress ? null :
                                        page === 2 && !this.props.preVerify ?
                                            this.flipPageTo(1) : this.goBack}
                                >
                                    {this.props.preVerify || page === 2 ? "GO BACK" : "SAVE"}
                                </div>
                            }

                            <div className={`btn ${this.props.settings ? "settings" : ""}`}
                                id="friend-action-continue"
                                style={{margin: 0}}
                                onClick={inProgress ? null :
                                    page === 1 && !this.props.genOnly ? this.flipPageTo(2) :
                                        this.onSubmit}>{this.props.update ? "SAVE CHANGES" :
                                    this.props.preVerify ? "SUBMIT" : "CONTINUE"}
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );

        return (
            this.props.settings ? (
                <div>
                    {content}
                </div>
            ) : (
                <div className="frame wrap w-fill primary tile-background">
                    <div id="friend-content" className="col">
                        {content}
                    </div>
                </div>
            )
        );
    }
}

FriendQuestions.defaultProps = {
    updateState: () => {},
    reloadPage: false,
    updatePage: null,
    preVerify: false,
    settings: false,
    genOnly: false,
    paged: false,

    infoColor: false,
    msg: "",
};

export default FriendQuestions;
