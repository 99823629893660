import React, {Component} from "react";

import Select from "../elements/Select";
import {AgeExtrema} from "../../utils/profileConstants";

import checked from "../../images/check/checked.png";
import unchecked from "../../images/check/unchecked.png";

import "../../styles/FriendQuestionsPageTwo.css";

class FriendQuestionsPageTwo extends Component {

    getAgeDropDown = () => {
        const tmp = [<option key="-1">--</option>];

        for (let i = AgeExtrema.YOUNGEST_AGE; i <= AgeExtrema.OLDEST_AGE; i++) {
            tmp.push(<option key={i}>{i}</option>);
        }

        return tmp;
    };

    render() {
        const getMusicOpt = (isChecked, val, title) => (
            <div className="filter-check music-option"
                onClick={() => {this.props.updateMusic(val);}}
            >
                <img src={isChecked ? checked : unchecked} alt="music-option-checkbox" />
                <div className="no-select">
                    {title}
                </div>
            </div>
        );

        return (
            <React.Fragment>
                <div
                    id="friend-page-age-range-wrapper"
                    className={this.props.settings ? "settings" : ""}>
                    <p className="friend-page-age-selector-text">
                        I want to meet friends between
                    </p>
                    <div id="friend-page-age-selector-wrapper">
                        <Select
                            style={{marginLeft: "0"}}
                            name="friendLowRange"
                            className="friend-dropdown age-dropdown inline friend-age-lower"
                            onChange={this.props.selectOnChange}
                            defaultValue={this.props.friendLowRange}
                        >
                            {this.getAgeDropDown()}
                        </Select>
                        <p style={{margin: 0}}>and</p>
                        <Select
                            name="friendHighRange"
                            className="friend-dropdown age-dropdown inline"
                            onChange={this.props.selectOnChange}
                            defaultValue={this.props.friendHighRange}
                        >
                            {this.getAgeDropDown()}
                        </Select>
                    </div>
                    <p className="friend-page-age-selector-text">
                        years old.
                    </p>
                </div>
                {!this.props.update && !this.props.preVerify &&
                    <div className="friend-page-music-interest-wrapper">
                        <p>Would you be interested in making music with other users?</p>

                        {getMusicOpt(this.props.collabMusic, true, "Yes")}
                        {getMusicOpt(!this.props.collabMusic, false, "No")}
                    </div>}
            </React.Fragment>
        );
    }
}

export default FriendQuestionsPageTwo;
