import React, {Component} from "react";
import {isMobile} from "../utils/utils";
import { THEME } from "./NewSettingsComponents";

import ovalImage from "../images/figures/Oval.png";
import swooshImage from "../images/background-tile/swoosh.png";
import circleImage from "../images/figures/Circle2.png";

class SwooshArea extends Component {
    constructor(props) {
        super(props);

        this.state = {
            onMobile: isMobile(),
        };
        this.setMobile = this.setMobile.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.setMobile);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setMobile);
    }

    setMobile() {
        this.setState({onMobile: isMobile()});
    }

    render() {
        const { onMobile } = this.state;

        const mainGridStyle = onMobile
            ? {
                gridAutoFlow: "row",
                gridTemplateRows: "min-content min-content min-content 1fr min-content",
                height: "100%"}  : {gridTemplateColumns: "40fr 60fr",
                gridTemplateRows: "min-content 1fr",
                height: "100%"};
        const titleFontSize = this.props.titleFontSize || "50px";
        const titleStyle = onMobile
            ? {
                color: THEME.colorAccent,
                fontSize: `calc(0.71 * ${titleFontSize}`,
                textAlign: "center"} : {color: "white",
                fontSize: titleFontSize,
                textAlign: "left"};
        const mainMargin = onMobile
            ? THEME.marginXSmall : THEME.marginSmall;
        const titleMargin = onMobile
            ? {
                margin: mainMargin,
                marginTop: `calc(${mainMargin} * 2)`} : {margin: mainMargin};

        const oval = <>
        <img src={ovalImage}
            style={{
                position: "absolute",
                top: "calc(80% - 125px)",
                left: "-160px",
                height: "200px",
                width: "450px",
                zIndex: 2}}/>
    </>;
        const circle = <>
        <img src={circleImage}
            style={{
                position: "absolute",
                bottom: "-100px",
                left: "-160px",
                height: "300px",
                zIndex: 2}}/>
    </>;
        const decorativeShape = this.props.shape ===  "oval" ? oval : circle;



        const html = <>
      <div style={{position: "relative", overflow: "hidden", width: "100%", height: "100%", flexGrow: 1}}>
          <img src={swooshImage}
              style={{position: "absolute", top: 0, left: 0, width: "90%", height: "80%", zIndex: 1}}/>
          {decorativeShape}
          {this.props.modal}
          <div style={{display: "grid", ...mainGridStyle, position: "relative", zIndex: 3}}>
              <div style={{justifySelf: "center", ...titleMargin}}>
                  <div
                      style={{
                          fontSize: "60px",
                          fontWeight: "700",
                          marginTop: onMobile ? "unset" : "1vw",
                          ...titleStyle,
                          textTransform: "uppercase"}}>
                      {this.props.title}
                  </div>
              </div>
              <div />
              <div />
              <div
                  style={{
                      justifySelf: "center",
                      padding: mainMargin,
                      /*maxWidth: "450px",*/ rowGap: THEME.marginXSmall,
                      display: "grid",
                      width: onMobile ? "100%" : "80%",
                      alignContent: "start"}}>
                  {this.props.children}
              </div>
          </div>
      </div>
    </>;

        return html;
    }
}

export default SwooshArea;
