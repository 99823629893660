/* Shared state between Header and Chat components
    Signals are cleared as soon as they are handled
*/
import React from "react";

export const callSignals = {
    signallingClient: null, // Twilio sync client
    userCalling: null,      // Signal: call detected, val: rtcId
    callRoomId: null,       // Signal: call accepted, val: callerId
    callDeclined: null,     // Signal: call declined
    onCall: false,
    callTransferred: false, // Call accepted outside of Chat component?
};
export const CallContext = React.createContext(callSignals);